import React, { useEffect, useState } from "react";
import { UserContext } from "../../contexts/UserContext";
import { useContext } from "react";

const MyPopup = (params) => {
  const { windowSize } = useContext(UserContext);
  const isNarrow = windowSize.innerWidth < 850;
  const [clickNum, setClickNum] = useState(0);
  const isOpen = params.open;

  useEffect(() => {
    if (clickNum === 2) {
      setClickNum(0);
      if (!isOpen.disableClickOutsideToClose) {
        params.close();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickNum, params]);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "unset");
  }, []);

  const handleMouseUp = (e) => {
    if (e.target === e.currentTarget && clickNum === 1) {
      setClickNum((c) => c + 1);
    } else {
      setClickNum(0);
    }
  };

  const handleMouseDown = (e) => {
    if (e.target === e.currentTarget && clickNum === 0) {
      setClickNum((c) => c + 1);
    } else {
      setClickNum(0);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        position: "fixed",
        height: "100vh",
        width: "100vw",
        left: 0,
        top: 0,
        backgroundColor: "rgba(0,0,0,0.5)",
        pointerEvents: isOpen ? "auto" : "none",
        zIndex: 10,
        alignItems: "center",
        justifyContent:
          !params.open.noKeyboard && isNarrow ? "start" : "center",
        paddingTop: !params.open.noKeyboard && isNarrow && "10vh",
      }}
      onMouseUp={handleMouseUp}
      onMouseDown={handleMouseDown}
    >
      <div className="popup-content">{params.content}</div>
    </div>
  );
};

export default MyPopup;
