const Footnote = (params) => {
  return (
    <div style={layerOne}>
      {params.index ? (
        <div style={{ minWidth: "15px" }}>{params.index}</div>
      ) : null}
      <div style={{}}>{params.text}</div>
    </div>
  );
};

export default Footnote;

const layerOne = {
  display: "flex",
  width: "100%",
  // height: "100%",
  flexDirection: "row",
  marginTop: "5px",
  color: "rgba(255,255,255,.9)",
  fontFamily: "Lora",
  fontSize: 13,
  fontWeight: "500",
  textAlign: "left",
};
