import { UserContext } from "../contexts/UserContext";
import { useContext, useRef, useState, useEffect } from "react";
import * as mainStyle from "../styles/mainStyle";
import { Analytics, API } from "aws-amplify";
import { queryCarbonCalculator } from "../graphql/queries";
// import downloadjs from "downloadjs";
// import html2canvas from "html2canvas";

const CalcInput = (params) => {
  const lastVisited = localStorage.getItem("lastVisited");
  const now = new Date();
  const touchLambda = async () => {
    await API.graphql({
      query: queryCarbonCalculator,
      variables: {
        zipcode: "10025",
        income: 100000,
        numhh: "3",
      },
      authMode: "API_KEY",
    });
  };
  if (!lastVisited || now.getTime() - lastVisited > 1000 * 60 * 15) {
    localStorage.setItem("lastVisited", now.getTime());
    console.log("TOUCH");
    touchLambda();
  }

  const smaller = params.smaller;
  const defaultIncome = "0";
  const defaultNumHh = "2";
  const hhOptions = { 1: "1", 2: "2", 3: "3", 4: "4", 5: "5", 6: "6+" };
  const incomeOptions = {
    0: ["N/A", 0],
    1: ["20k", 20000],
    2: ["40k", 40000],
    3: ["60k", 60000],
    4: ["80k", 80000],
    5: ["100k", 100000],
    6: ["120k", 120000],
    7: ["140k", 140000],
    8: ["160k+", 160000],
  };

  Analytics.record({ name: "visit-calculate" });

  const inputRef = useRef(null);
  const { windowSize } = useContext(UserContext);
  const isNarrow = params.myWidth
    ? params.myWidth < 580
    : windowSize.innerWidth < 650;
  const [isLoading, setIsLoading] = useState(false);
  const [incomeSubmitDown, setIncomeSubmitDown] = useState(false);
  const [hhSubmitDown, setHhSubmitDown] = useState(false);
  const [submitDown, setSubmitDown] = useState(false);
  const [zipcode, setZipcode] = useState("");
  const [numHh, setNumHh] = useState(defaultNumHh);
  const [income, setIncome] = useState(defaultIncome);

  const [err, setErr] = useState(null);
  const [errState, setErrState] = useState(false);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (err) {
      setErrState(true);
      setCounter((c) => c + 1);
    } else {
      setErrState(null);
      setCounter(0);
    }
    const timer = setTimeout(() => {
      setErrState(false);
    }, 2500);
    return () => clearTimeout(timer);
  }, [err]);

  const submitCalc = async () => {
    setErr(null);
    if (!isLoading) {
      try {
        setIsLoading(true);
        if (zipcode.length !== 5) {
          setErr("Please enter a valid ZIP code\\" + counter);
        } else {
          try {
            const res = await API.graphql({
              query: queryCarbonCalculator,
              variables: {
                zipcode: zipcode,
                income: incomeOptions[income][1],
                numhh: numHh,
              },
              authMode: "API_KEY",
            });
            const response = JSON.parse(
              JSON.parse(JSON.stringify(res)).data.queryCarbonCalculator
            ).body;
            // console.log(response);
            Analytics.record({ name: "submit-calculate" });
            params.setResult(response);
          } catch (e) {
            setErr("Please try again\\" + counter);
            // setErr(e.errors[0].message);
            console.log(e.errors[0].message);
          }
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    }
  };

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      // const swiperChild = document.getElementById("sample");
      // const canvas = await html2canvas(swiperChild, {
      //   scale: 5,
      //   backgroundColor: null,
      //   allowTaint: true,
      // });
      // const dataURL = canvas.toDataURL("image/png");
      // downloadjs(dataURL, "GreenReportCard", "image/png");
    }
  };

  const handleZipChange = (e) => {
    const result = e.target.value.replace(/[^0-9]/, "");
    inputRef.current.value = result;
    if (result.length > 5) {
      inputRef.current.value = result.substring(0, 5);
    } else {
      setZipcode(result);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        boxSizing: "border-box",
        maxWidth: "600px",
        minWidth: mainStyle.minWidth,
        width: "100%",
        height: "100%",
        flex: "1",
        flexDirection: "column",
        // border: "2px solid purple",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "600",
          color: mainStyle.colorGreenBackground,
          fontSize: isNarrow || smaller ? "20px" : "25px",
          paddingBottom: isNarrow || smaller ? "15px" : "20px",
          boxSizing: "border-box",
        }}
      >
        <div>
          <b>
            {" "}
            <u>Offset</u>{" "}
          </b>
          your entire carbon footprint.
          <br />{" "}
          <b>
            {" "}
            <u>Earn</u>{" "}
          </b>
          7% annually.*
        </div>
      </div> */}
      <div
        id="sample"
        style={{
          display: "flex",
          flexDirection: "column",
          boxSizing: "border-box",
          justifyContent: "center",
          // border: (isNarrow ? "5px" : "8px") + " solid " + mainStyle.colorGreen,
          borderRadius: mainStyle.mediumRadius,
          width: "100%",
          backgroundColor: mainStyle.colorGreenBackground,
          boxShadow: "0px 0px 15px 10px rgba(0,0,0,0.15)",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "600",
            color: mainStyle.colorGreenBackground,
            fontSize: isNarrow || smaller ? "15px" : "20px",
            boxSizing: "border-box",
            flexDirection: "column",
            borderRadius: "8px 8px 0px 0px",
            padding: smaller || isNarrow ? "15px" : "20px",
            // border: "1px solid black",
            backgroundColor: mainStyle.colorGreen,
            border: "1px solid " + mainStyle.colorGreen,
          }}
        >
          <div style={{ fontSize: smaller || isNarrow ? "19px" : "23px" }}>
            Calculate your carbon footprint
          </div>
          <div style={{ fontSize: smaller || isNarrow ? "12px" : "13px" }}>
            Get started on your path to net-zero.
          </div>
        </div>
        <div
          style={{
            fontWeight: "600",
            color: mainStyle.colorGreenBackground,
            fontSize: "20px",
            marginTop: smaller || isNarrow ? "20px" : "30px",
            marginLeft: smaller || isNarrow ? "20px" : "30px",
            marginRight: smaller || isNarrow ? "20px" : "30px",
            marginBottom: smaller || isNarrow ? "20px" : "30px",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            minHeight: "70%",
            // border: "1px solid red",
          }}
        >
          <div style={headerStyle}>ZIP Code</div>
          <input
            inputMode="numeric"
            ref={inputRef}
            id={"greenWaitlistInput"}
            disabled={isLoading}
            style={boxStyle(isNarrow, smaller)}
            name="Zipcode"
            type="text"
            placeholder=""
            onChange={(e) => handleZipChange(e)}
            onKeyDown={handleKeyDown}
          />
          <div style={{ height: smaller || isNarrow ? "10px" : "17px" }} />
          <div style={headerStyle}>Number of people in household</div>
          <div style={{ height: smaller || isNarrow ? "5px" : "7px" }} />
          <div style={optionContainer(false, isNarrow, smaller)}>
            {Object.keys(hhOptions).map((i, item) => (
              <div
                key={"numhh-selector-" + i}
                style={selectBox(numHh === i, hhSubmitDown === i)}
                onMouseDown={() => (!isLoading ? setHhSubmitDown(i) : null)}
                onMouseUp={() => (!isLoading ? setHhSubmitDown(null) : null)}
                onMouseLeave={() => (!isLoading ? setHhSubmitDown(null) : null)}
                onClick={() => (!isLoading ? setNumHh(i) : null)}
              >
                {hhOptions[i]}
              </div>
            ))}
          </div>
          <div style={{ height: smaller || isNarrow ? "10px" : "17px" }} />
          <div style={headerStyle}>Household income</div>
          <div style={{ height: smaller || isNarrow ? "5px" : "7px" }} />
          <div
            style={{
              gap: isNarrow || smaller ? "2.5px" : "4px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {[
              [0, 5],
              [5, 9],
            ].map((rowIndex, item) => (
              <div
                key={"income-box-" + rowIndex[1]}
                style={optionContainer(rowIndex[1] === 9, isNarrow, smaller)}
              >
                {Object.keys(incomeOptions)
                  .slice(rowIndex[0], rowIndex[1])
                  .map((i, item) => (
                    <div
                      key={"income-selector-" + i}
                      style={selectBox(income === i, incomeSubmitDown === i)}
                      onMouseDown={() =>
                        !isLoading ? setIncomeSubmitDown(i) : null
                      }
                      onMouseUp={() =>
                        !isLoading ? setIncomeSubmitDown(null) : null
                      }
                      onMouseLeave={() =>
                        !isLoading ? setIncomeSubmitDown(null) : null
                      }
                      onClick={() => (!isLoading ? setIncome(i) : null)}
                    >
                      {incomeOptions[i][0]}
                    </div>
                  ))}
              </div>
            ))}
          </div>
          <div style={{ height: smaller || isNarrow ? "15px" : "20px" }} />
          <div
            style={ActionButtonStyleDark(submitDown, smaller, isNarrow)}
            onClick={submitCalc}
            onMouseDown={() => setSubmitDown(true)}
            onMouseUp={() => setSubmitDown(false)}
            onMouseLeave={() => setSubmitDown(false)}
          >
            {!isLoading ? (
              <div>Calculate!</div>
            ) : (
              <img
                alt="loading"
                src="/loading2.gif"
                style={{
                  width: "50%",
                  height: "50%",
                  objectFit: "contain",
                }}
              />
            )}
            <div style={errorDiv(errState)}>
              {err && err.includes("\\")
                ? err.substr(0, err.lastIndexOf("\\"))
                : err}
            </div>
          </div>
        </div>
      </div>
      {smaller ? (
        <div style={disclaimerStyle(isNarrow, smaller)}>
          Your information is <b>not stored or distributed</b>.
        </div>
      ) : (
        <div style={disclaimerStyle(isNarrow, smaller)}>
          Your information is <b>not stored or distributed</b>. It is used only
          for the purpose of calculating your carbon footprint.
        </div>
      )}
    </div>
  );
};

export default CalcInput;

const headerStyle = {
  color: mainStyle.colorGreen,
  display: "flex",
  fontSize: "17px",
};

const ActionButtonStyleDark = (submitDown, smaller, isNarrow) => ({
  userSelect: "none",
  display: "flex",
  height: smaller || isNarrow ? "55px" : "60px",
  width: "100%",
  fontSize: 18,
  textAlign: "center",
  borderRadius: mainStyle.smallRadius,
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  boxSizing: "border-box",
  fontWeight: "700",
  textDecoration: "none",
  color: "white",
  backgroundColor: mainStyle.colorGreen,
  boxShadow: submitDown ? "inset 0px 0px 10px 0px rgba(0,0,0,0.25)" : null,
  position: "relative",
});

const boxStyle = (isNarrow, smaller) => ({
  display: "flex",
  height: smaller || isNarrow ? "45px" : "50px",
  width: "100%",
  fontSize: smaller || isNarrow ? "35px" : "40px",
  borderTop: "0px",
  borderLeft: "0px",
  borderRight: "0px",
  borderBottom: "2px solid " + mainStyle.colorGreen,
  color: mainStyle.colorGreen,
  fontWeight: "600",
  backgroundColor: "transparent",
  outlineWidth: 0,
  fontFamily: "Lora",
  userSelect: "none",
  boxSizing: "border-box",
  outline: "none",
  padding: "0px",
  justifyContent: "center",
  alignItems: "center",
});

const selectBox = (selected, pressed) => ({
  userSelect: "none",
  display: "flex",
  height: "100%",
  flex: "1",
  textAlign: "center",
  borderRadius: mainStyle.smallRadius,
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  boxSizing: "border-box",
  border: "2px solid " + mainStyle.colorGreen,
  color: selected ? "white" : mainStyle.colorGreen,
  backgroundColor: selected
    ? mainStyle.colorGreen
    : mainStyle.colorGreenBackground,
  boxShadow: pressed ? "inset 0px 0px 10px 0px rgba(0,0,0,0.25)" : null, //"inset 0px 0px 2px 1px" + mainStyle.colorGreen,
});

const optionContainer = (fewerOptions, isNarrow, smaller) => ({
  display: "flex",
  height: smaller || isNarrow ? "45px" : "50px",
  width: fewerOptions ? "80%" : "100%",
  fontSize: smaller || isNarrow ? "15px" : "17px",
  fontWeight: "500",
  borderRadius: mainStyle.smallRadius,
  fontFamily: "Lora",
  userSelect: "none",
  boxSizing: "border-box",
  gap: smaller || isNarrow ? "2.5px" : "4px",
});

const disclaimerStyle = (isNarrow, smaller) => ({
  color: "white",
  fontSize: isNarrow || smaller ? "12px" : "13px",
  fontWeight: "500",
  marginTop: smaller ? "7px" : "10px",
  // marginBottom: "10px",
  width: "100%",
  textAlign: smaller ? "center" : "left",
  alignItems: "center",
  justifyContent: "center",
  boxSizing: "border-box",
});

const errorDiv = (errState) => ({
  height: "100%",
  fontFamily: "Lora",
  fontSize: 13,
  fontWeight: "600",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  textAlign: "center",
  backgroundColor: mainStyle.colorError,
  opacity: errState ? 1 : 0,
  transition: errState || errState === null ? null : "opacity 1.5s",
  padding: "5px",
  borderRadius: mainStyle.smallRadius,
  position: "absolute",
  bottom: "0",
  boxSizing: "border-box",
  color: "#ffffff",
  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
  pointerEvents: "none",
});
