import { spacer } from "../styles/common";
import { homePage, simpleTerms } from "../assets/Content";
import { UserContext } from "../contexts/UserContext";
import { useContext, useEffect, useState } from "react";
import { Analytics } from "aws-amplify";
import Together from "../components/home/Together";
import ContentPiece from "../components/home/ContentPiece";
import Popup from "reactjs-popup";
import Disclosure from "../components/Disclosure";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import * as mainStyle from "../styles/mainStyle";
import { Outlet, useSearchParams, Link } from "react-router-dom";

const Home = (params) => {
  const { windowSize, setThanksPopupOpen, thanksPopupOpen } =
    useContext(UserContext);
  const isNarrow = windowSize.innerWidth < 850;
  const isShort = windowSize.innerHeight < 550;
  const [submitDown, setSubmitDown] = useState(false);
  const searchParams = useSearchParams()[0];
  if (searchParams.get("ref")) {
    localStorage.setItem("referrer", searchParams.get("ref"));
    window.history.replaceState(null, "Green", "/");
  }
  const [disclose, setDisclose] = useState(
    params.disclose ? params.disclose : false
  );

  const CenterCard = (props) => {
    return (
      <div
        style={{
          width: "100%",
          paddingTop: props.newPadding ? props.newPadding : "90px",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          paddingLeft: !props.edges ? mainStyle.margin.sides(isNarrow) : null,
          paddingRight: !props.edges ? mainStyle.margin.sides(isNarrow) : null,
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            width: "100%",
            maxWidth: !props.edges ? "900px" : null,
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            padding: mainStyle.margin.sides(isNarrow),
            backgroundColor: props.edges ? mainStyle.colorGreen : null,
            borderRadius: !props.edges ? "30px" : null,
            color: !props.edges
              ? mainStyle.colorGreen
              : mainStyle.colorGreenBackground,
            fontSize: isNarrow ? "20px" : "30px",
            fontWeight: "700",
            border: props.border ? "5px solid " + mainStyle.colorGreen : null,
            boxSizing: "border-box",
            boxShadow: props.shadow
              ? "0px 0px 30px 10px rgba(0,0,0,0.07)"
              : null,
          }}
        >
          {props.text}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (params.scoreId && !thanksPopupOpen) {
      setThanksPopupOpen({ scoreId: params.scoreId });
      window.history.replaceState(null, "Green", "/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (disclose) window.history.replaceState(null, "Green", "/");

  const closeModal = () => setDisclose(false);
  Analytics.record({ name: "visit-home" });

  return (
    <div id="scrollableHead" style={MainDivStyle}>
      <Outlet />
      <Popup open={disclose} closeOnDocumentClick onClose={closeModal}>
        <Disclosure title="Disclaimer" body={simpleTerms} />
      </Popup>
      <NavBar />
      <div style={BodyDivStyle}>
        <div style={HeaderDivStyle(isShort, isNarrow)}>
          <div style={HeaderSubDivStyle(isShort, isNarrow)}>
            {!isNarrow ? (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  boxSizing: "border-box",
                  backgroundImage: 'url("/phone2.png")',
                  backgroundPosition: "top right",
                  backgroundSize: "auto 100%",
                  backgroundRepeat: "no-repeat",
                  borderRadius: isShort
                    ? "0px 0px 0px 80px"
                    : "0px 0px 0px 160px",
                }}
              />
            ) : null}
            {!isNarrow ? spacer(isNarrow) : null}
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                marginBottom: isNarrow && "50px",
                marginTop: isNarrow && "50px",
                // border: "4px solid red",
              }}
            >
              <div style={ActionContainterDivStyle(isNarrow)}>
                <div style={TitleTextStyle(isNarrow)}>{homePage.A1}</div>
                <div style={{ height: "20px" }} />
                <Link
                  onMouseDown={() => setSubmitDown(true)}
                  onMouseUp={() => setSubmitDown(false)}
                  onMouseLeave={() => setSubmitDown(false)}
                  to="/calculate"
                  style={{
                    ...mainStyle.ActionButtonStyle(true),
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: submitDown
                      ? "inset 0px 0px 10px 0px rgba(0,0,0,0.25)"
                      : null,
                  }}
                >
                  <div>Calculate your impact</div>
                  <img
                    style={{ height: "22px", marginLeft: "7px" }}
                    alt=""
                    src="calculator2.png"
                  />
                </Link>
              </div>
            </div>
            {isNarrow ? (
              <div
                style={{
                  display: "flex",
                  boxSizing: "border-box",
                  borderRadius: "0px 0px 0px 80px",
                  justifyContent: "center",
                  overflow: "hidden",
                  // border: "4px solid red",
                }}
              >
                <img
                  src="phone.png"
                  alt="Mock up of Green app on iPhone"
                  style={{ maxWidth: "390px" }}
                />
              </div>
            ) : null}
          </div>
        </div>
        <div
          onClick={() =>
            document
              .getElementById("scrollable")
              .scrollIntoView({ behavior: "smooth" })
          }
          style={ArrowImgDivStyle(isShort, isNarrow)}
        >
          <img
            src="/arrow-down.png"
            alt=""
            style={ArrowStyle(windowSize.innerHeight < 800)}
          />
        </div>
        <div id="scrollable" style={{ height: 0 }} />

        <CenterCard
          border
          text={homePage.Card1}
          newPadding={isNarrow ? "40px" : "90px"}
        />

        <ContentPiece
          isLeft={isNarrow}
          leader={homePage.B1}
          header={homePage.B2}
          text={homePage.B3}
          pic="graph2-dark.png"
          contentType="graph"
          button={
            <div
              style={mainStyle.ActionButtonStyle(true)}
              onClick={() => setThanksPopupOpen({ type: "together" })}
            >
              Join the waitlist
            </div>
          }
          addedStyle={{ paddingTop: isNarrow ? "90px" : "120px" }}
        />

        <ContentPiece
          leader={homePage.C1}
          header={homePage.C2}
          text={[homePage.C3]}
          bullets={homePage.C4}
          pic="desktop.png"
          contentType="header"
          addedStyle={{ paddingTop: isNarrow ? "90px" : "120px" }}
        />

        <CenterCard edges text={homePage.Card2} />

        <ContentPiece
          isLeft={isNarrow}
          isCentered={true}
          leader={homePage.E1}
          header={homePage.E2}
          text={homePage.E3}
          pic="thumbs-dark.png"
          contentType="strong"
          addedStyle={{ paddingTop: isNarrow ? "90px" : "120px" }}
        />

        <ContentPiece
          isCentered={true}
          leader={homePage.F1}
          header={homePage.F2}
          text={homePage.F3}
          pic="strong-dark.png"
          contentType="ESG"
          addedStyle={{
            paddingTop: isNarrow ? "90px" : "120px",
            paddingBottom: isNarrow ? "90px" : "120px",
          }}
        />

        <Together noTopMargin={true} />
        <Footer footnotes={homePage.Footnotes} />
      </div>
    </div>
  );
};

export default Home;

const MainDivStyle = {
  display: "flex",
  flexDirection: "column",
  cursor: "default",
  backgroundColor: mainStyle.colorGreenBackground,
  // userSelect: "none",
  fontFamily: "Lora",
  boxSizing: "border-box",
};

const BodyDivStyle = {
  display: "flex",
  flexDirection: "column",
  width: "100vw",
  boxSizing: "border-box",
  alignItems: "center",
};

const HeaderDivStyle = (isShort, isNarrow) => ({
  display: "flex",
  paddingTop: mainStyle.navBarHeight,
  width: "100vw",
  minHeight: "650px",
  height: !isNarrow ? "95vh" : null,
  maxHeight: "1050px",
  justifyContent: "center",
  boxSizing: "border-box",
});

const HeaderSubDivStyle = (isShort, isNarrow) => ({
  display: "flex",
  width: "100%",
  minHeight: "100%",
  flexDirection: isNarrow ? "column" : "row",
  backgroundColor: mainStyle.colorGreen,
  justifyContent: "center",
  borderRadius: isShort || isNarrow ? "0px 0px 0px 80px" : "0px 0px 0px 160px",
  boxSizing: "border-box",
});

const ArrowImgDivStyle = (isShort, isNarrow) => ({
  display: "flex",
  width: "100vw",
  minHeight: "30px",
  height: isNarrow ? "50px" : "5vh",
  maxHeight: "50px",
  objectFit: "contain",
  padding: "3px",
  boxSizing: "border-box",
  cursor: "pointer",
  alignItems: "center",
  justifyContent: "center",
});

const ArrowStyle = (isShort) => ({
  height: "75%",
});

const ActionContainterDivStyle = (isNarrow) => ({
  ...{
    display: "flex",
    // height: "100%",
    padding: "25px",
    maxWidth: "500px",
    minWidth: "330px",
    // height: "50%",
    color: "white",
    backgroundColor: mainStyle.colorGreenBackground,
    boxSizing: "border-box",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: mainStyle.mediumRadius,
    // border: "1px solid purple",
    marginRight: isNarrow
      ? mainStyle.margin.wideSides(isNarrow)
      : mainStyle.margin.sides(isNarrow),
    marginLeft: isNarrow ? mainStyle.margin.wideSides(isNarrow) : 0,
    boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)",
  },
});

const TitleTextStyle = (isNarrow) => ({
  display: "flex",
  fontSize: isNarrow ? 31 : 33,
  fontWeight: "600",
  justifyContent: "left",
  textAlign: "left",
  color: mainStyle.colorGreen,
  // backgroundColor: "red",
});
