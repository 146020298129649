export const title = "Privacy Policy";
export const body = (
  <div>
    This Privacy Policy describes how Jeremy Ben-Meir (
    <b>“Green,” “them,” “we,” “us,”</b> and
    <b>“our”</b>) collects, uses, shares, and safeguards personal information.
     This Privacy Policy also tells you about your rights and choices with
    respect to your personal information, and how you can reach us to get
    answers to your questions.
    <br /> <br /> <b>Types Of Information We Collect.</b> <br />
    <br />
    The following provides examples of the type of information that we collect
    from you and how we use that information. <br />
    <br />
    <div style={{ padding: "0px 30px 0px 30px" }}>
      <u>Context</u> <br />
      Primary Purpose for Collection and Use of Data Types of Data <br />
      <br />
      <u>Account Registration</u> <br />
      We may collect your name and contact information when you create an
      account. We may also collect information relating to the actions that you
      perform while logged into your account. We use this information to provide
      account related functionalities to our users. Accounts can be used for
      easy checkout and to save your preferences and transaction history.
      <br />
      <br /> <u>Client</u> <br /> Information We may collect include the name,
      and contact information, of our clients and their employees with whom we
      may interact. We have a legitimate interest in contacting our clients and
      communicating with them concerning normal business administration such as
      projects, services, and billing. <br />
      <br />
      <u>Cookies and first party tracking</u> <br />
      We may use cookies and clear GIFs. “Cookies” are small pieces of
      information that a website sends to a computer’s hard drive while a web
      site is viewed. We use this information to ensure our website operates
      efficiently.
      <br />
      <br /> <u>Cookies and Third Party Tracking </u>
      <br />
      We may participate in behavior-based advertising, this means that a third
      party uses technology (e.g., a cookie) to collect information about your
      use of our website so that they can provide advertising about products and
      services tailored to your interests on our website, or on other websites.
        We use this information to engage in behavior-based advertising and
      capture website analytics.
      <br />
      <br />
      <u>Demographic Information</u>
      <br />
      We may collect personal information, such as your age and gender.   We use
      this information to comply with applicable laws. <br />
      <br />
      <u>Email Interconnectivity</u>
      <br />
      If you receive email from us, we may use certain tools to capture data
      related to when you open our message, click on any links or banners it
      contains and make purchases. We use this information to understand how you
      interact with our communications to you. <br />
      <br />
      <u>Employment</u>
      <br />
      If you apply for a job posting, or become an employee, we may collect
      information necessary to process your application or to retain you as an
      employee.  This may include, among other things, your Social Security
      Number.  Providing this information is required for employment. We use
      information about current employees to carry out our payment and benefits
      obligations.  In some contexts, we are also required by law to collect
      information about our employees.  We also use this information to have
      efficient staffing and work force operations. <br />
      <br />
      <u>Feedback/Support</u>
      <br />
      If you provide us feedback or contact us for support, we may collect your
      name and e-mail address, as well as any other content that you send to us,
      in order to reply. We use this information in order to receive, and act
      upon, your feedback or issues. <br />
      <br />
      <u>Mailing List</u>
      <br />
      When you sign up for one of our mailing lists, we may collect your email
      address or postal address.     We share information about our products and
      services with individuals that consent to receive such information.  We
      also use this information to share information about our products or
      services.
      <br />
      <br />
      <u>Mobile Devices</u>
      <br />
      We may collect information from your mobile device such as unique
      identifying information broadcast from your device when visiting our
      website or when visiting one of our stores. We use this information to
      identify unique visitors and understand how users interact with us on
      their mobile devices.
      <br />
      <br />
      <u>Order Placement</u>
      <br />
      We may collect your name, billing address, shipping address, e-mail
      address, phone number, and credit card number when you place an order. We
      use your information to perform our contract to provide you with products
      or services.
      <br />
      <br />
      <u>Partner Promotion</u>
      <br />
      We may collect information that you provide as part of a co-branded
      promotion with another company. We use this information to fulfill our
      promotions. <br />
      <br />
      <u>Surveys</u>
      <br />
      When you participate in a survey, we may collect information that you
      provide through the survey.  If the survey is provided by a third party
      service provider, the third party’s privacy policy applies to the
      collection, use, and disclosure of your information. We use this
      information to understand your opinions and collect information relevant
      to our organization. <br />
      <br />
      <u>Sweepstakes or contests</u>
      <br />
      When you participate in a sweepstakes or contest, we may collect
      information about you which includes contact information to notify you if
      you are selected. We use this information to operate the sweepstakes.  In
      some contexts we are also required by law to collect information about
      those that enter into our sweepstakes. <br />
      <br />
      <u>Website interactions</u>
      <br />
      We use technology to monitor how you interact with our website. This may
      include which links you click on, or information that you type into our
      online forms.  This may also include information about your device or
      browser. We use this information to understand how you interact with our
      website to better improve it, and to understand your preferences and
      interests in order to select offerings that you might find most useful.
       We also have a legitimate interest in detecting and preventing fraud.
      <br />
      <br />
      <u>Web logs</u>
      <br />
      We may collect information, including your browser type, operating system,
      Internet Protocol (IP) address (a number that is automatically assigned to
      a computer when the Internet is used), domain name, click-activity,
      referring website, and/or a date/time stamp for visitors. We use this
      information to monitor our networks and the visitors to our websites.
       Among other things, it helps us understand which of our products or
      services is the most popular. <br />
      <br />
    </div>
    In addition to the information that we collect from you directly, we may
    also receive information about you from other sources, including third
    parties, business partners, our affiliates, or publicly available sources.
     For example, if you submit a job application, or become an employee, we may
    conduct a background check. ‍ <br />
    <br />
    <b>Use And Processing Of Information.</b>
    <br />
    <br /> In addition to the purposes and uses described above, we may use
    information in the following ways: <br />
    <div style={{ padding: "0px 30px 0px 30px" }}>
      <br />• To identify you when you visit our websites. <br />• To provide
      products and services or to process returns. <br />• To improve our
      services and product offerings. <br />• To streamline the checkout
      process. <br />• To conduct analytics. <br />• To respond to inquiries
      related to support, employment opportunities, or other requests.   <br />•
      To send marketing and promotional materials, including information
      relating to our products, services, sales, or promotions. <br />• For
      internal administrative purposes, as well as to manage our relationships.
    </div>
    <br />
    Although the sections above describe our primary purpose in collecting your
    information, in many situations we have more than one purpose.  For example,
    if you complete an online purchase or transaction on our app, we may collect
    your information to perform our contract with you, but we also collect your
    information so that we can quickly and easily respond to any questions about
    your order or transaction.  As a result, our collection and processing of
    your information is based in different contexts upon your consent, our need
    to perform a contract, our obligations under law, and/or our general
    interest in conducting our business. <br />
    <br />
    <b>Sharing Of Information.</b> <br />
    <br />
    In addition to the specific situations discussed elsewhere in this policy,
    we disclose information in the following situations: <br /> <br />
    <div style={{ padding: "0px 30px 0px 30px" }}>
      <b>1. Affiliates and Acquisitions.</b> We may share information with our
      corporate affiliates (e.g., parent company, sister companies,
      subsidiaries, joint ventures, or other companies under common control).
       If another company acquires, or plans to acquire, our company, business,
      or our assets, we will also share information with that company, including
      at the negotiation stage. <br />
      <b>2. Other Disclosures with Your Consent. </b> We may ask if you would
      like us to share your information with other unaffiliated third parties
      who are not described elsewhere in this policy. <br />
      <b>3. Other Disclosures without Your Consent.</b> We may disclose
      information in response to subpoenas, warrants, or court orders, or in
      connection with any legal process, or to comply with relevant laws.  We
      may also share your information in order to establish or exercise our
      rights, to defend against a legal claim, to investigate, prevent, or take
      action regarding possible illegal activities, suspected fraud, safety of
      person or property, or a violation of our policies, or to comply with your
      request for the shipment of products to or the provision of services by a
      third party intermediary. <br />
      <b>4. Public.</b>  Some of our websites may provide the opportunity to
      post comments, or reviews, in a public forum.  If you decide to submit
      information on these pages, that information may be publicly available.
      <br />
      <b>5. Partner Promotion.</b> We may offer contests, sweepstakes, or other
      promotions with third party partners.  If you decide to enter a contest,
      sweepstakes, or promotion that is sponsored by a third party partner the
      information that you provide will be shared with us and with them.  Their
      use of your information is not governed by this privacy policy. <br />
      <b>6. Service Providers.</b> We may share your information with service
      providers.  Among other things service providers may help us to administer
      our website, conduct surveys, provide technical support, process payments,
      and assist in the fulfillment of orders. <br />
      <b>7. Sharing with Affiliates.</b> We may share certain information with
      affiliates. For financial products or services, please refer to our US
      Consumer Privacy Notice. In addition, California residents may opt-out of
      the sharing of certain information with affiliates by using one of the
      methods listed below under “Contact Information.”
    </div>
    <br />
    <br />
    <b> Your Choices.</b> <br />
    <br />
    You can make the following choices regarding your personal information:
    <br />
    <br />
    <div style={{ padding: "0px 30px 0px 30px" }}>
      <b>1. Access To Your Personal Information.</b>
      You may request access to your personal information by contacting us at
      the address described below. If required by law, upon request, we will
      grant you reasonable access to the personal information that we have about
      you. Note that California residents may be entitled to ask us for a notice
      describing what categories of personal information (if any) we share with
      third parties or affiliates for direct marketing. <br />
      <b>2. Deletion Of Your Personal Information. </b> You may request that we
      delete your personal information by contacting us at the address described
      below. If required by law we will grant a request to delete information,
      but you should note that in many situations we must keep your personal
      information to comply with our legal obligations, resolve disputes,
      enforce our agreements, or for another one of our business purposes.{" "}
      <br />
      <b>3. Online Tracking. </b>
      We do not currently recognize automated browser signals regarding tracking
      mechanisms, which may include "Do Not Track" instructions. <br />
      <b> 4. Promotional Emails.</b> You may choose to provide us with your
      email address for the purpose of allowing us to send free newsletters,
      surveys, offers, and other promotional materials to you, as well as
      targeted offers from third parties. You can stop receiving promotional
      emails by following the unsubscribe instructions in e-mails that you
      receive. If you decide not to receive promotional emails, we may still
      send you service related communications. <br />{" "}
      <b>5. Promotional Mailings. </b>
      If at any time you do not want to receive offers and/or circulars from us
      you can remove yourself from our mailing lists by emailing us (our contact
      information is below) with "NO SNAIL MAIL" in the subject line along with
      your name, address and zip code. Please note that our mailings are
      prepared in advance of their being sent. Although we will remove your name
      from our mailing list after receiving your request, you may still receive
      mailings from us that had been initiated prior to your name being removed.{" "}
      <br />
      <b>6. Promotional Text Messages. </b>
      If you receive a text message from us that contains promotional
      information you can opt-out of receiving future text messages by replying
      “STOP.”
    </div>
    <br />
    Please address written requests and questions about your rights
    investwithgreen@gmail.com.
    <br />
    <br /> Note that, as required by law, we will require you to prove your
    identity.  We may conduct an identity verification by phone call or email.
    Depending on your request, we will ask for information such as your name,
    the last item you purchased from us, or the date of your last purchase from
    or transaction with us. We may also ask you to provide a signed declaration
    confirming your identity. Following a request, we will use reasonable
    efforts to supply, correct or delete personal information about you in our
    files.
    <br />
    <br />
    <b>Authorized Agent</b>
    <br />
    <br />
    You may use an authorized agent to submit a request to access your
    information, delete your information, or opt out of the sale of your
    information. To designate an agent for these purposes: <br />
    <br />
    1. You must provide us with a copy of a written and signed Authorized Agent
    Designation Form given to your authorized agent; and <br />
    <br />
    2. If you are a business, proof that you are registered with the Secretary
    of State to conduct business in California.
    <br />
    <br /> <b>How We Protect Personal Information </b>
    <br />
    <br />
    No method of transmission over the Internet, or method of electronic
    storage, is fully secure. While we use reasonable efforts to protect your
    personal information from unauthorized access, use, or disclosure, we cannot
    guarantee the security of your personal information. In the event that we
    are required by law to inform you of a breach to your personal information
    we may notify you electronically, in writing, or by telephone, if permitted
    to do so by law. <br />
    <br />
    Our website and app permits you to create an account.  When you do, you will
    be prompted to create a password.  You are responsible for maintaining the
    confidentiality of your password, and you are responsible for any access to
    or use of your account by someone else that has obtained your password,
    whether or not such access or use has been authorized by you.  You should
    notify us of any unauthorized use of your password or account. <br />
    <br />
    <b>Miscellaneous</b>
    <br />
    <br />
    The following additional information relates to our privacy practices:{" "}
    <br />
    <br />
    <div style={{ padding: "0px 30px 0px 30px" }}>
      <br />
      <b>• Third Party Applications/Websites. </b>We have no control over the
      privacy practices of websites or applications that we do not own. <br />
      <b>• Changes To This Privacy Policy. </b>We may change our privacy policy
      and practices over time.  To the extent that our policy changes in a
      material way, the policy that was in place at the time that you submitted
      personal information to us will generally govern that information unless
      we receive your consent to the new privacy policy.  Our privacy policy
      includes an “effective” and “last updated” date. The effective date refers
      to the date that the current version took effect. The last updated date
      refers to the date that the current version was last substantively
      modified. <br />
      <b>• Accessibility. </b>This Privacy Policy may be accessed through audio
      readers. <br />
      <b>• Children. </b>We do not sell the personal information of minors under
      16 years of age without affirmative authorization. <br />
      <b>• Information for California Residents. </b> California Civil Code
      Sections 1798.115(c), 1798.130(a)(5)(c), 1798.130(c), and 1798.140
      indicate that organizations should disclose whether certain categories of
      information are collected, “sold” or transferred for an organization’s
      “business purpose”(as those terms are defined under California law).  You
      can find a list of the categories of information that we collect and
      share here. Please note that because this list is comprehensive it may
      refer to types of information that we share about people other than
      yourself. If you would like more information concerning the categories of
      personal information (if any) we share with third parties or affiliates
      for those parties to use for direct marketing please submit a written
      request to us using the information in the "Contact Information" section
      below.  We do not discriminate against California residents who exercise
      any of their rights described in this Privacy Policy.
    </div>
    <br />
    <b>Contact Information </b>If you have any questions, comments, or
    complaints concerning our privacy practices please contact us at the
    appropriate address below.  We will attempt to respond to your requests and
    to provide you with additional privacy-related information.
    <br />
    <br />
    investwithgreen@gmail.com <br />
    <br />
    Effective Date: October 10, 2022
    <br />
    Last Updated Date: December 4, 2022
  </div>
);
