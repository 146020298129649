const DisclaimerButton = (params) => {
  const size = params.size ? params.size : "25px";
  return (
    <div
      onClick={() =>
        params.clickable
          ? document
              .getElementById("FooterID")
              .scrollIntoView({ behavior: "smooth" })
          : null
      }
      style={buttonStyle(size)}
    >
      <img
        alt="disclaimer"
        src={params.light ? "/disclaimer-light.png" : "/disclaimer.png"}
        style={{ height: "100%", width: "100%" }}
      />
    </div>
  );
};

export default DisclaimerButton;

const buttonStyle = (size) => ({
  display: "flex",
  borderRadius: "20px",
  height: size,
  minHeight: size,
  width: size,
  minWidth: size,
  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.3)",
  cursor: "pointer",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  marginRight: "8px",
});
