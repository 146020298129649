/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const queryCarbonCalculator = /* GraphQL */ `
  query QueryCarbonCalculator(
    $zipcode: String
    $income: String
    $numhh: String
  ) {
    queryCarbonCalculator(zipcode: $zipcode, income: $income, numhh: $numhh)
  }
`;
export const getWaitlistMember = /* GraphQL */ `
  query GetWaitlistMember($email: AWSEmail!) {
    getWaitlistMember(email: $email) {
      id
      email
      referralId
      scoreId
      score
      referrer
      givenName
      age
      refer
      invest
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listWaitlistMembers = /* GraphQL */ `
  query ListWaitlistMembers(
    $email: AWSEmail
    $filter: ModelWaitlistMemberFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWaitlistMembers(
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        email
        referralId
        scoreId
        score
        referrer
        givenName
        age
        refer
        invest
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContactMember = /* GraphQL */ `
  query GetContactMember($id: ID!) {
    getContactMember(id: $id) {
      entity
      companyName
      fullName
      email
      inquiryTopic
      inquiryDetail
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listContactMembers = /* GraphQL */ `
  query ListContactMembers(
    $filter: ModelContactMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        entity
        companyName
        fullName
        email
        inquiryTopic
        inquiryDetail
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getReferMember = /* GraphQL */ `
  query GetReferMember($id: ID!) {
    getReferMember(id: $id) {
      referName
      referEmail
      email0
      email1
      email2
      email3
      email4
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listReferMembers = /* GraphQL */ `
  query ListReferMembers(
    $filter: ModelReferMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReferMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        referName
        referEmail
        email0
        email1
        email2
        email3
        email4
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const memberByReferralId = /* GraphQL */ `
  query MemberByReferralId(
    $referralId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelWaitlistMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    memberByReferralId(
      referralId: $referralId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        referralId
        scoreId
        score
        referrer
        givenName
        age
        refer
        invest
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const memberByScoreId = /* GraphQL */ `
  query MemberByScoreId(
    $scoreId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelWaitlistMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    memberByScoreId(
      scoreId: $scoreId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        referralId
        scoreId
        score
        referrer
        givenName
        age
        refer
        invest
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
