import NavBar from "../components/NavBar";
import * as mainStyle from "../styles/mainStyle";
import { UserContext } from "../contexts/UserContext";
import { useContext } from "react";
import {
  steadyWidth,
  translate,
  translateAdv,
  reverse,
} from "../styles/common";
import * as Content from "../assets/Content";
import Footer from "../components/Footer";
import TimelineComponent from "../components/Timeline";
import { Analytics } from "aws-amplify";
import Together from "../components/home/Together";

const Learn = () => {
  const { windowSize, setThanksPopupOpen } = useContext(UserContext);
  const isNarrow = windowSize.innerWidth < 850;
  const isShort = windowSize.innerHeight < 550;
  Analytics.record({ name: "visit-learn" });

  const imageAdd = (pic, isLeft, isHeader = false) => (
    <div
      style={{
        ...{
          backgroundSize: "auto 100%",
          width: "100%",
          maxWidth: "100%",
          minHeight: isHeader ? "200px" : isNarrow ? "300px" : "350px",
          height: isHeader ? "80%" : isNarrow ? "200px" : "100%",
          backgroundPosition: "center " + isNarrow ? "center" : reverse(isLeft),
          backgroundImage: 'url("/' + pic + '")',
          backgroundRepeat: "no-repeat",
          marginTop: isHeader ? "20px" : "0px",
          marginBottom: isHeader ? "20px" : "0px",
          boxSizing: "border-box",
          borderRadius: "20px",
          border: "5px solid " + mainStyle.colorGreen,
          // border: "1px solid blue",
        },
        ...(!isHeader ? { maxWidth: "400px" } : {}),
      }}
    />
  );

  const spacer = (
    <div
      style={{
        minWidth: mainStyle.margin.sides(isNarrow),
        minHeight: mainStyle.margin.sides(isNarrow),
      }}
    />
  );
  const spacerWide = (
    <div
      style={{
        minWidth: mainStyle.margin.sides(isNarrow),
      }}
    />
  );

  const getStartedVal = (
    <div
      style={mainStyle.ActionButtonStyle(true)}
      onClick={() => setThanksPopupOpen({ type: "together" })}
    >
      Join the waitlist!
    </div>
  );

  const contentPiece = (isLeft, trueContent, imgContent, height) => {
    const spacerLoc = !isLeft && !isNarrow;
    const addHeight = height
      ? { ...height, paddingTop: mainStyle.navBarHeight }
      : {};
    const imageArea = reverse(isLeft);
    return (
      <div style={{ ...SectionDiv(isNarrow), ...addHeight }}>
        {!spacerLoc && (height ? !(isShort && isNarrow) : true) ? (
          <div style={basicContentDivider(isNarrow, imageArea)}>
            {imgContent}
          </div>
        ) : null}
        {!spacerLoc && !(height && isNarrow) ? spacer : null}
        <div
          style={
            height
              ? specialContentDivider(isNarrow)
              : basicContentDivider(isNarrow, isLeft)
          }
        >
          {trueContent}
        </div>
        {spacerLoc ? spacer : null}
        {spacerLoc ? (
          <div style={basicContentDivider(isNarrow, imageArea)}>
            {imgContent}
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div style={MainDivStyle}>
      <NavBar />
      <div style={BodyDivStyle}>
        {contentPiece(
          true,
          <div style={textSectionDiv}>
            <div style={contentHeaderDiv(true)}>{Content.learnPage.A1}</div>
            <div style={contentTextDiv}>
              <div style={contentTextSubDiv(true)}>{Content.learnPage.A2}</div>
            </div>
            <div style={{ height: "15px" }} />
            {getStartedVal}
            {!isShort && isNarrow ? <div style={{ height: "55px" }} /> : null}
          </div>,
          imageAdd("cartoon0-dark.png", true, true),
          {
            height: isNarrow || isShort ? "100vh" : "75vh",
            maxHeight: "750px",
            minHeight: "400px",
          }
        )}
        <div
          style={{
            width: "100%",
            minHeight: "125px",
            height: "25vh",
            maxHeight: "250px",
            backgroundImage: 'url("/patternflat-dark.png")',
            backgroundSize: "contain",
            backgroundPosition: "center",
          }}
        />
        {spacer}
        {spacer}
        {contentPiece(
          isNarrow,
          <div style={textSectionDiv}>
            <div style={contentLeaderDiv(true)}>{Content.learnPage.B1}</div>
            <div style={contentHeaderDiv(true, 30)}>{Content.learnPage.B2}</div>
            <div style={contentTextDiv}>
              <div style={contentTextSubDiv(true)}>
                {Content.learnPage.B3[0]}
              </div>
              <div style={contentTextSubDiv(true)}>
                {Content.learnPage.B3[1]}
              </div>
            </div>
          </div>,
          imageAdd("scenery-dark.png", isNarrow)
        )}
        {spacer}
        {spacer}
        <div style={SectionDiv(isNarrow)}>
          <div style={basicContentDivider(isNarrow, isNarrow)}>
            <div style={textSectionDiv}>
              <div style={contentLeaderDiv(true)}>{Content.learnPage.C1}</div>
              <div style={contentHeaderDiv(true, 30)}>
                {Content.learnPage.C2}
              </div>
              <div style={contentTextDiv}>
                <div style={contentTextSubDiv(true)}>
                  {Content.learnPage.C3}
                </div>
              </div>
            </div>
          </div>
          {spacerWide}
          <div style={basicContentDivider(isNarrow, true)}>
            <div style={timelineContainer}>
              <TimelineComponent />
            </div>
          </div>
        </div>
        {spacer}
        {spacer}
        <Together />
        <Footer />
      </div>
    </div>
  );
};

export default Learn;

const MainDivStyle = {
  display: "flex",
  flexDirection: "column",
  cursor: "default",
  backgroundColor: mainStyle.colorGreenBackground,
  // userSelect: "none",
  fontFamily: "Lora",
  boxSizing: "border-box",
};

const BodyDivStyle = {
  display: "flex",
  flexDirection: "column",
  width: "100vw",
  boxSizing: "border-box",
};

const SectionDiv = (isNarrow) => ({
  display: "flex",
  width: "100%",
  boxSizing: "border-box",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: isNarrow ? "column" : "row",
  paddingLeft: mainStyle.margin.sides(isNarrow),
  paddingRight: mainStyle.margin.sides(isNarrow),
  // border: "1px solid blue",
});

const basicContentDivider = (isNarrow, isLeft) => ({
  ...{
    display: "flex",
    flexDirection: "row",
    boxSizing: "border-box",
    justifyContent: isNarrow ? "center" : translateAdv(isLeft),
    alignItems: "center",
    flex: 1,
    height: "100%",
    // border: "1px solid red",
  },
  ...(isNarrow ? { maxWidth: steadyWidth, width: "100%" } : {}),
});

const specialContentDivider = (isNarrow) => ({
  display: "flex",
  flexDirection: "row",
  boxSizing: "border-box",
  justifyContent: "center",
  alignItems: isNarrow ? "flex-end" : "center",
  flex: isNarrow ? 0 : 1,
  height: "100%",
});

const contentLeaderDiv = (isLeft) => ({
  display: "flex",
  fontSize: 15,
  fontWeight: "700",
  fontFamily: "Lora",
  color: mainStyle.colorGreenAccent,
  width: "100%",
  justifyContent: translateAdv(isLeft),
  textAlign: translate(isLeft),
});

const contentHeaderDiv = (isLeft, size = 40) => ({
  display: "flex",
  fontSize: size,
  fontWeight: "700",
  fontFamily: "Lora",
  color: mainStyle.colorGreenAccent,
  width: "100%",
  justifyContent: translateAdv(isLeft),
  textAlign: translate(isLeft),
});

const contentTextDiv = {
  display: "flex",
  flexDirection: "column",
  minWidth: "100%",
};

const contentTextSubDiv = (isLeft) => ({
  display: "flex",
  fontSize: 15,
  fontWeight: "light",
  fontFamily: "Lora",
  color: mainStyle.colorGreenAccent,
  marginTop: "10px",
  width: "100%",
  justifyContent: translateAdv(isLeft),
  textAlign: translate(isLeft),
});

const textSectionDiv = {
  display: "flex",
  maxWidth: steadyWidth,
  height: "100%",
  flexDirection: "column",
  justifyContent: "center",
  // border: "1px solid red",
};

const timelineContainer = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  // alignItems: "flex-start",
  textAlign: "left",
  width: "100%",
  maxWidth: steadyWidth,
};
