export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

// function pickHex(score) {
//   const weight = score / 100.0;
//   var greenColor = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec("#00B988");
//   var redColor = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec("#ff6348");
//   var yellowColor = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec("#abab00");
//   const redWeight = Math.max(1 - weight * 2, 0);
//   const yellowWeight = 1 - 2 * Math.abs(weight - 0.5);
//   const greenWeight = Math.max(2 * (weight - 0.5), 0);

//   var rgb = [
//     Math.round(
//       parseInt(redColor[1], 16) * redWeight +
//         parseInt(greenColor[1], 16) * greenWeight +
//         parseInt(yellowColor[1], 16) * yellowWeight
//     ),
//     Math.round(
//       parseInt(redColor[2], 16) * redWeight +
//         parseInt(greenColor[2], 16) * greenWeight +
//         parseInt(yellowColor[2], 16) * yellowWeight
//     ),
//     Math.round(
//       parseInt(redColor[3], 16) * redWeight +
//         parseInt(greenColor[3], 16) * greenWeight +
//         parseInt(yellowColor[3], 16) * yellowWeight
//     ),
//   ];
//   return "rgb(" + rgb.join(",") + ")";
// }
