import React from "react";
import * as mainStyle from "../../styles/mainStyle";
import { UserContext } from "../../contexts/UserContext";
import { useContext } from "react";
import TextArea from "./TextArea";
import * as Content from "../../assets/Content";
import WaitlistEntry from "./waitlistEntry";

const Together = (params) => {
  const { windowSize, setThanksPopupOpen } = useContext(UserContext);
  const isNarrow = windowSize.innerWidth < 850;
  const noTopMargin = params.noTopMargin ? params.noTopMargin : true;
  const greenStyle = params.greenStyle ? params.greenStyle : false;
  const callToAction = params.callToAction;

  const myText = (
    <TextArea
      leader={callToAction ? Content.homePage.G1 : Content.homePage.D1}
      header={callToAction ? Content.homePage.G2 : Content.homePage.D2}
      text={[
        callToAction
          ? Content.homePage.G3(greenStyle ? "white" : mainStyle.colorGreen)
          : Content.homePage.D3(greenStyle ? "white" : mainStyle.colorGreen),
      ]}
      isLeft={true}
      noMargins={true}
      button={
        <div style={containerStyle}>
          <WaitlistEntry whiteStyle={greenStyle} />
        </div>
      }
      color={greenStyle ? mainStyle.colorGreenBackground : mainStyle.colorGreen}
    />
  );

  return (
    <div
      style={wholeDiv(
        isNarrow,
        noTopMargin,
        windowSize.innerWidth < 540,
        params.popup,
        greenStyle
      )}
    >
      {params.popup && (
        <div
          onClick={() => setThanksPopupOpen(null)}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            width: "15px",
            height: "15px",
            cursor: "pointer",
          }}
        >
          <img
            alt="close signup"
            src={isNarrow ? "close-dark.png" : "close-dark.png"}
            style={{ height: "100%", width: "100%" }}
          />
        </div>
      )}
      <div
        style={mainDiv(
          isNarrow,
          noTopMargin,
          windowSize.innerWidth < 540,
          params.popup,
          greenStyle,
          params.whiteBorder
        )}
      >
        {!(params.popup && isNarrow) && (
          <div
            style={{
              display: "flex",
              boxSizing: "border-box",
              height: !isNarrow && "100%",
              flex: !(greenStyle && !isNarrow) && 1,
              maxWidth: ((greenStyle && !isNarrow) || params.popup) && "325px",
              backgroundColor: mainStyle.colorGreen,
              minWidth: isNarrow ? "100%" : "325px",
              justifyContent: "right",
              paddingTop: mainStyle.margin.sides(isNarrow),
              paddingBottom: !(isNarrow && greenStyle)
                ? mainStyle.margin.sides(isNarrow)
                : 0,
              paddingLeft: mainStyle.margin.sides(isNarrow),
              paddingRight: mainStyle.margin.sides(isNarrow),
              flexDirection: "column",
              // height: "300px",
            }}
          >
            <img
              src="tree-white.png"
              alt="A tree growing Green's logo"
              style={imageStyle(
                isNarrow,
                "tree-white.png",
                greenStyle,
                params.popup
              )}
            />
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            boxSizing: "border-box",
            height: !isNarrow && "100%",
            flex: !(greenStyle && !isNarrow) && (params.popup ? 3 : 1),
            justifyContent: greenStyle && !isNarrow ? "left" : "center",
            paddingTop: mainStyle.margin.sides(isNarrow),
            paddingBottom: mainStyle.margin.sides(isNarrow),
            paddingLeft: mainStyle.margin.sides(isNarrow),
            paddingRight: mainStyle.margin.sides(isNarrow),
            maxWidth: params.popup && "625px",
          }}
        >
          {myText}
        </div>
      </div>
    </div>
  );
};

export default Together;

const wholeDiv = (isNarrow, noTopMargin, isVeryNarrow, popup, greenStyle) => ({
  display: "flex",
  width: "100%",
  flexDirection: isNarrow ? "column" : "row",
  paddingTop:
    greenStyle || noTopMargin || popup ? 0 : mainStyle.margin.sides(isNarrow),
  paddingBottom: popup || greenStyle ? 0 : mainStyle.margin.sides(isNarrow),
  paddingLeft: popup || greenStyle ? 0 : mainStyle.margin.sides(isNarrow),
  paddingRight: popup || greenStyle ? 0 : mainStyle.margin.sides(isNarrow),
  alignItems: "center",
  justifyContent: "center",
  boxSizing: "border-box",
  maxWidth: popup ? "90vw" : null,
  maxHeight: popup ? "90vh" : null,
  borderTop: greenStyle && "2px solid " + mainStyle.colorGreenBackground,
  minWidth: mainStyle.minWidth,
});

const mainDiv = (
  isNarrow,
  noTopMargin,
  isVeryNarrow,
  popup,
  greenStyle,
  whiteBorder
) => ({
  display: "flex",
  width: "100%",
  flexDirection: isNarrow ? "column" : "row",
  // paddingTop: mainStyle.margin.sides(popup ? isNarrow : isVeryNarrow),
  // paddingBottom: mainStyle.margin.sides(popup ? isNarrow : isVeryNarrow),
  // paddingLeft: mainStyle.margin.sides(isNarrow),
  // paddingRight: mainStyle.margin.sides(isNarrow),
  borderRadius: "10px",
  alignItems: "center",
  boxShadow: popup || greenStyle ? null : "0px 0px 30px 10px rgba(0,0,0,.09)",
  backgroundColor: !greenStyle ? mainStyle.colorGreenBackground : null,
  justifyContent: "center",
  boxSizing: "border-box",
  fontFamily: "Lora",
  overflow: "hidden",
  border: whiteBorder && "2px solid " + mainStyle.colorGreenBackground,
  // border: "3px solid blue",
  maxWidth: isNarrow ? "1600px" : "1600px",
});

const imageStyle = (isNarrow, pic, greenStyle, popup) => ({
  display: "flex",
  boxSizing: "border-box",

  width: "100%",
  height: isNarrow ? "200px" : "250px",
  objectFit: "contain",
  objectPosition: greenStyle && !isNarrow && "right",

  // backgroundPosition: "center right",
  //   "2px solid " +
  //   (greenStyle ? mainStyle.colorGreenBackground : mainStyle.colorGreen),
  // borderRadius: "20px",
});

// const ActionButtonStyleDark = {
//   display: "flex",
//   height: "60px",
//   width: "100%",
//   fontSize: 18,
//   textAlign: "center",
//   borderRadius: "20px",
//   alignItems: "center",
//   justifyContent: "center",
//   cursor: "pointer",
//   boxSizing: "border-box",
//   fontWeight: "700",
//   textDecoration: "none",
//   color: "white",
//   backgroundColor: mainStyle.colorGreen,
//   userSelect: "none",
//   // color: "white",
//   // border: "5px solid "+mainStyle.colorGreen,
//   // boxShadow: "0px 0px 30px 10px rgba(0,0,0,.09)",
// };

const containerStyle = {
  display: "flex",
  height: "70px",
  width: "100%",
};
