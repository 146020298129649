import React from "react";
import * as mainStyle from "../../styles/mainStyle";
import { UserContext } from "../../contexts/UserContext";
import { useContext } from "react";

const ExtraInfo = (params) => {
  const { windowSize, setThanksPopupOpen } = useContext(UserContext);
  const isNarrow = windowSize.innerWidth < 850;
  const extraInformation = [
    {
      name: "Food",
      img: "food.png",
      val: params.thanksPopupOpen.emissionsInfo["food"],
    },
    {
      name: "Natural Gas",
      img: "natural_gas.png",
      val: params.thanksPopupOpen.emissionsInfo["natural_gas"],
    },
    {
      name: "Electricity",
      img: "electricity.png",
      val: params.thanksPopupOpen.emissionsInfo["electricity"],
    },
    {
      name: "Apparel",
      img: "apparel.png",
      val: params.thanksPopupOpen.emissionsInfo["apparel"],
    },
    {
      name: "Motor Gas",
      img: "motor_gas.png",
      val: params.thanksPopupOpen.emissionsInfo["motor_gas"],
    },
    {
      name: "Air Fare",
      img: "air_fare.png",
      val: params.thanksPopupOpen.emissionsInfo["air_fare"],
    },
    {
      name: "Water",
      img: "water.png",
      val: params.thanksPopupOpen.emissionsInfo["water"],
    },
  ];
  const total = extraInformation
    .map((item) => item.val)
    .reduce((partialSum, a) => partialSum + a, 0);
  const totalAmt = params.thanksPopupOpen.emissionsInfo["total"];
  const otherAmt = totalAmt - total;
  const sortedInfo = extraInformation
    .sort((a, b) => b.val - a.val)
    .concat([
      {
        name: "Other",
        img: "other.png",
        val: otherAmt,
      },
    ]);
  // console.log(extraInformation);
  // console.log(params.thanksPopupOpen.emissionsInfo);

  const valuePanel = (item) => (
    <div
      style={{
        display: "flex",
        minHeight: "150px",
        flex: "1",
        border: "3px solid " + mainStyle.colorGreenBackground,
        borderRadius: "10px",
        flexDirection: "column",
        color: mainStyle.colorGreen,
        position: "relative",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "2px",
          right: "6px",
          fontSize: "20px",
          color: mainStyle.colorGreenBackground,
        }}
      >
        {/* {item + 1} */}
      </div>
      <div
        style={{
          flex: "5",
          width: "100%",
          color: mainStyle.colorGreenBackground,
          display: "flex",
          fontWeight: "600",
          fontSize: "20px",
          backgroundImage: `url(${sortedInfo[item].img})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#00000099",
            height: "100%",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              fontSize: "40px",
              lineHeight: "36px",
              // border: "2px solid " + mainStyle.colorGreenBackground,
            }}
          >
            {((sortedInfo[item].val * 100) / totalAmt).toFixed(0)}%
          </div>
          <div
            style={{
              fontSize: "20px",
              lineHeight: "18px",
              // border: "2px solid " + mainStyle.colorGreenBackground,
            }}
          >
            {sortedInfo[item].name}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flex: "3",
          width: "100%",
          backgroundColor: mainStyle.colorGreenBackground,
          flexDirection: "column",
        }}
      >
        <div
          style={{
            flex: "1",
            width: "100%",
            fontWeight: "600",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "29px",
          }}
        >
          {sortedInfo[item].val.toFixed(2)}
          <div style={{ width: "5px" }} />
          <div
            style={{
              fontWeight: "400",
              fontSize: "12px",
              textAlign: "left",
              lineHeight: "13px",
            }}
          >
            Metric
            <br />
            Tons CO2e
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: isNarrow ? "column" : "row",
        padding: mainStyle.margin.sides(isNarrow),
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        maxWidth: isNarrow ? "500px" : "800px",
        width: "100vw",
        maxHeight: "625px",
        height: "100vh",
        borderTop: false && "2px solid " + mainStyle.colorGreenBackground,
        minWidth: mainStyle.minWidth,
        // border: "3px solid red",
      }}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          width: "100%",
          flexDirection: "column",
          borderRadius: "10px",
          backgroundColor: mainStyle.colorGreen,
          boxSizing: "border-box",
          fontFamily: "Lora",
          position: "relative",
          maxHeight: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <div
          onClick={() => setThanksPopupOpen(null)}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            width: "15px",
            height: "15px",
            cursor: "pointer",
          }}
        >
          <img
            alt="close signup"
            src={isNarrow ? "close-dark.png" : "close-dark.png"}
            style={{ height: "100%", width: "100%" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            color: mainStyle.colorGreen,
            backgroundColor: mainStyle.colorGreenBackground,
            padding: mainStyle.margin.gaps(isNarrow),
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              fontSize: "22px",
              fontWeight: "600",
            }}
          >
            Thanks for sharing!
          </div>
          <div
            style={{
              display: "flex",
              fontSize: "15px",
              fontWeight: "400",
              width: "100%",
              textAlign: "left",
            }}
          >
            Explore your emissions breakdown
            {/* based on the number of people in your household, and your income and ZIP Code! */}
          </div>
        </div>
        <div
          id="top-info-id"
          style={{
            display: "flex",
            flex: 1,
            boxSizing: "border-box",
            maxWidth: "100%",
            minWidth: "100%",
            padding: mainStyle.margin.gaps(isNarrow),
            flexDirection: "column",
            overflow: "scroll",
            // border: "3px solid red",
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              gap: "10px",
              flexDirection: "column",
            }}
          >
            <div style={rowStyle(isNarrow)}>
              <div style={columnStyle}>
                {valuePanel(0)}
                {valuePanel(1)}
              </div>
              <div style={columnStyle}>
                {valuePanel(2)}
                {valuePanel(3)}
              </div>
            </div>
            <div style={rowStyle(isNarrow)}>
              <div style={columnStyle}>
                {valuePanel(4)}
                {valuePanel(5)}
              </div>
              <div style={columnStyle}>
                {valuePanel(6)}
                {valuePanel(7)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtraInfo;

const rowStyle = (isNarrow) => ({
  display: "flex",
  gap: "10px",
  flexDirection: isNarrow ? "column" : "row",
  flex: 1,
});
const columnStyle = {
  display: "flex",
  gap: "10px",
  flexDirection: "row",
  flex: 1,
};
