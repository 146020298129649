//old home
// B1: "Why Green?",
// B2: "When you win, the planet wins.",
// B3: [
//   "Let's face it: investing often feels like a choice between returns and a positive impact. But now, with Green, you can build wealth while putting money where it matters.",
//   "Green is the first platform that lets you invest in alternative assets with competitive returns and a verifiable impact on the environment. With a quick setup, intuitive dashboard, and streamlined investing process, adding wind and solar to your portfolio has never been easier or more attractive.",
// ],

import FootnoteString from "../components/home/FootnoteItem";
import * as mainStyle from "../styles/mainStyle";

// With no investing minimums, low fees, and frequent withdrawal windows, Green makes it easy to diversify into the carbon-free future. Just create a free account, fund an investment, and watch your wealth and impact grow.
// Green makes it easy to diversify into the carbon-free future.

export const infoPanel = {
  A1: "How do we measure this?",
  A2: "Learn more",
  A3: (readMore) => (
    <div>
      The Green Emissions Report Card uses national survey data to estimate
      personal consumption in various product segments (e.g. gasoline, food).
      Specifically, we use household income, number of individuals in the
      household, and ZIP Code-level averages of other variables (e.g. age,
      commuting time) to produce a best-guess of your annual consumption based
      on the national data. We then combine these consumption estimates with
      prior research on their carbon-intensity to produce an estimate of your
      total personal carbon emissions.
      <br />
      <br />
      Consider a high-income, suburban household with one member. Based on
      Americans' consumption patterns, this household will likely consume more
      gas per person, for example. Members of these households likely drive
      greater distances than their urban counterparts or take flights more
      frequently than their low-income counterparts.
      <br />
      <br />
      Moreover, we are able to quantify how many gallons of gas they will
      consume in a year, putting a number on how much more than average we
      expect their spend at the pump to be. Using this estimate, we are able to
      multiply their gas consumption by how much each gallon emits, giving us an
      estimate of their emissions in that segment. We repeat this process for
      nine other product segments to come up with an estimate of their total
      emissions.
      <br />
      <br />
      <div
        onClick={readMore}
        style={{ textDecoration: "underline", cursor: "pointer" }}
      >
        Read more...
      </div>
      <br />
      <div
        style={{
          borderTop: "1px solid " + mainStyle.colorGreen,
          paddingTop: "10px",
        }}
        onClick={() => window.open("mailto:investwithgreen@gmail.com")}
      >
        Feel free to reach out with any comments or questions at{" "}
        <u style={{ cursor: "pointer" }}>investwithgreen@gmail.com</u>!
      </div>
    </div>
  ),
  A4: (readLess) => (
    <div>
      The Green Emissions Report Card uses national survey data to estimate
      personal consumption in various product segments (e.g. gasoline, food).
      Specifically, we use household income, number of individuals in the
      household, and ZIP Code-level averages of other variables (e.g. age,
      commuting time) to produce a best-guess of your annual consumption based
      on the national data. We then combine these consumption estimates with
      prior research on their carbon-intensity to produce an estimate of your
      total personal carbon emissions.
      <br />
      <br />
      Consider a high-income, suburban household with one member. Based on
      Americans' consumption patterns, this household will likely consume more
      gas per person, for example. Members of these households likely drive
      greater distances than their urban counterparts or take flights more
      frequently than their low-income counterparts.
      <br />
      <br />
      Moreover, we are able to quantify how many gallons of gas they will
      consume in a year, putting a number on how much more than average we
      expect their spend at the pump to be. Using this estimate, we are able to
      multiply their gas consumption by how much each gallon emits, giving us an
      estimate of their emissions in that segment. We repeat this process for
      nine other product segments to come up with an estimate of their total
      emissions.
      <br />
      <br />
      Inspired by the methodology of Jones and Kammen (2014) and Jones and
      Kammen (2011), we use the Residential Energy Consumption Survey (RECS) to
      estimate electricity, natural gas, fuel oil, and propane consumption using
      a Ridge regression fit on a second-order polynomial of demographic and
      geographic covariates available for each response. This estimator retains
      the interpretational simplicity of a linear model, while remaining robust
      to spurious relations in the data. Therefore, we can think of it as the
      best estimate of consumption using publicly available data.
      <br />
      <br />
      In the same way, we use the Consumption Expenditure Survey (CEX) to
      estimate motor vehicle gas, airfare, food, apparel, and water/sewage
      service consumption.
      <br />
      <br />
      Next, we use Census data to produce zip code-level averages of the
      demographic covariates considered in the fitted models. We then impute
      estimated consumption for the nine product segments we model for all
      combinations of household size, income, and zip code.
      <br />
      <br />
      Lastly, we multiply these consumption estimates with emissions factors –
      the metric tons of CO2-emissions equivalents associated with a unit of
      consumption – from Jones and Kammen (2011). We then sum these estimated
      emissions across segments (adjusting for estimated emissions in all other
      segments not explicitly modeled) to produce a final estimate of
      personalized emissions. This estimate is reported as the number of
      CO2-equivalent metric tons emitted in a year and as a normalized score.
      <br />
      <br />
      <div
        onClick={readLess}
        style={{ textDecoration: "underline", cursor: "pointer" }}
      >
        Read less...
      </div>
      <br />
      <div
        style={{
          borderTop: "1px solid " + mainStyle.colorGreen,
          paddingTop: "10px",
        }}
        onClick={() => window.open("mailto:investwithgreen@gmail.com")}
      >
        Feel free to reach out with any comments or questions at{" "}
        <u style={{ cursor: "pointer" }}>investwithgreen@gmail.com</u>!
      </div>
    </div>
  ),
};

export const homePage = {
  A1: (
    <div>
      <b>
        {" "}
        <u>Invest</u>{" "}
      </b>
      in green
      <br /> tech innovation.
      <br />{" "}
      <b>
        {" "}
        <u>See</u>{" "}
      </b>
      the impact.
    </div>
  ),
  Card1: (
    <div>
      Exclusive investments in <u>private green companies</u> with{" "}
      <u>predictable returns</u>, on a simple platform that{" "}
      <u>tracks progress toward your climate goals.</u>
    </div>
  ),
  Card2: (
    <div>
      The top eight most widely-used ESG (environmental, social, governance)
      investment ratings disagree 80% of the time.
      <sup style={{ fontSize: "17px" }}>2</sup> Huh?
    </div>
  ),
  A2: "Track your environmental impact while investing in exclusive alternative assets with predictable, competitive, carbon-free returns.",
  A3: "Power your portfolio and the planet with curated investments that build a brighter future for us all.",
  B1: "Clean and competitive",
  B2: "When you win, the planet wins.",
  B3: [
    "Let's face it: investing often feels like a choice between returns and a positive impact. But now, with Green, you can build wealth while supporting the transition to a more sustainable future.",
    "Just create a free account, fund an investment, and watch your wealth and impact grow.*",
  ],
  C1: "Why Green?",
  C2: "A platform for everybody.",
  C3: "Whether you have $10 or $10 million, Green allows you to easily invest in green energy projects and track the impact of your investments.* Our user-friendly interface and straightforward sign-up process make it easy to join us in decarbonizing the future.",
  C5: "Green is the first platform that lets you invest in alternative assets with competitive returns and a verifiable impact on the environment.",
  C4: [
    "See your real impact",
    "Access competitive returns",
    "Use our intuitive dashboard",
  ],
  E1: 'Ditch "ESG"',
  E2: "Greener, for real.",
  E3: [
    <div>
      Traditional ESG ratings supposedly measure a company’s impact on the
      environment. However, the top eight most widely-used ESG ratings disagree
      80% of the time.<sup>2</sup> So, it is nearly impossible to understand the
      impact of investment strategies based on these scores.
    </div>,
    <div>
      At Green, we don’t need them. Our portfolio is comprised entirely of
      companies that take decarbonization seriously, whose impact on the
      environment can be measured in averted emissions.
    </div>,
  ],
  F1: "Invest with confidence",
  F2: "Harness the power of private markets.",
  F3: [
    <div>
      Savings accounts and government bonds offer low returns that fail to beat
      inflation.<sup>3</sup> Stocks and mutual funds suffer from large swings in
      prices, which makes it hard to plan for the future. While these publicly
      available options may not work for you, private market investments deliver
      consistent real returns.<sup>4</sup>
    </div>,
    <div>
      With Green, you can build a modern portfolio with exposure to assets
      issued by private, sustainable companies. Generally uncorrelated with
      stocks and bonds, private market investing is a no-brainer. Protect your
      wealth from inflation and bear markets today.
    </div>,
  ],

  D1: "Join Us",
  D2: "Plant a tree!",
  // Access exclusive investments and maximize risk-adjusted returns while promoting sustainable technologies and projects.Join our waitlist today, and see what Green can do for your portfolio and the planet.
  D3: (color) => (
    <div>
      To thank you for joining the Green Community, we have partnered with{" "}
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      <a
        style={{
          color: color,
          textDecoration: "underline",
          cursor: "pointer",
        }}
        onClick={() => window.open("https://highatlasfoundation.org/")}
      >
        High Atlas Foundation
      </a>{" "}
      to plant a tree when you join our waitlist.**
    </div>
  ),
  G1: "Join Us",
  G2: "Make a change",
  G3: (color) => (
    <div>
      Green enables anybody to reduce their emissions by investing in private
      green companies with predictable, competitive returns.* Join the waitlist
      today!
    </div>
  ),
  Footnotes: [
    <div>
      <div style={{ paddingBottom: "5px" }}>
        Performance values stated in nominal terms, net of fees and expenses,
        based on a 5-year investment horizon with annual compounding.
      </div>
      <FootnoteString
        t1="Environmental impact of savings accounts taken from a "
        t2="report issued by the Center for American Progress and the Sierra Club"
        t3="."
        link="https://www.carbonbubble.net/"
      />
      <FootnoteString
        t1="Environmental impact of Green taken from indicative "
        t2="impact metrics issued by the New York Green Bank"
        t3="."
        link="https://greenbank.ny.gov/Resources/Impact-Report"
      />
      <FootnoteString
        t1="Performance values for savings accounts taken from the "
        t2="FDIC"
        t3="."
        link="https://www.fdic.gov/resources/bankers/national-rates/previous-rates.html"
      />
      <FootnoteString
        t1="Performance values for Green taken from indicative "
        t2="rates issued by NYCEEC"
        t3="."
        link="https://nyceec.com/rates/"
      />
    </div>,
    <FootnoteString
      t1="Source: Berg, Florian, et al. "
      t2="ESG confusion and stock returns: Tackling the problem of noise"
      t3=". No. w30562. National Bureau of Economic Research, 2022."
      link="https://www.nber.org/system/files/working_papers/w30562/w30562.pdf"
    />,
    <FootnoteString
      t1="Source: "
      t2="Blackrock's report on negative real rates"
      t3="."
      link="https://www.blackrock.com/us/individual/insights/negative-real-rates"
    />,
    <FootnoteString
      t1="Source: "
      t2="McKinsey's Private Markets Annual Review"
      t3="."
      link="https://www.mckinsey.com/industries/private-equity-and-principal-investors/our-insights/mckinseys-private-markets-annual-review"
    />,
  ],
};

export const learnPage = {
  A1: "Learn about Green.",
  A2: "Our platform works to maximize the potential returns and impact of your money. Discover how Green can work for you.",
  B1: "Our approach",
  B2: "Green unlocks access to private sustainable instruments.",
  B3: [
    "Green is for the investor that measures returns not only in dollars, but also in metric tons of carbon dioxide removed from the atmosphere. Our mission is to make sustainable investing accessible, attractive, and transparent.",
    "We do this by analyzing countless transactions on the private markets, choosing the most promising from these to put in our low-cost portfolios. After funding an account, you can track your progress to financial and environmental goals through our easy-to-use dashboard.",
  ],
  C1: "Our process",
  C2: "See how Green makes it happen.",
  C3: "From the private markets to your portfolio, take a tour through the Green investing experience.",
  D1: {
    Origination:
      "Green works with established partners in the private markets to acquire positions in fixed-income products, emitted by companies working across the green economy. We analyze countless transactions, subjecting them to a rigorous screening process that selects on quality, returns, and environmental impact.",
    "Due Diligence":
      "Green performs due diligence to make sure the proposed investments fit our investment criteria and are fit for inclusion in the General Fund.",
    Investment:
      "Seamlessly transfer money from your bank account to your Green account and invest in our General Fund, designed to maximize risk-adjusted returns and environmental impact.",
    Performance:
      "Monitor your progress towards financial and climate-related goals. Automate monthly investments to effortlessly expand your earnings. Submit an order to cash out during our next redemption window.",
  },
};

export const investPage = {
  A1: "A new way to invest",
  A2: "The Green General Fund.",
  A3: "Our flagship investment product is designed to provide long-term capital appreciation through an actively managed portfolio investing in private debt instruments originated by companies with verified carbon-negative business models.",

  B1: "Investing in good.",
  B2: "We make sure every penny held in green is helping develop a more sustainable future.",
  B3: [
    "Community Solar Projects",
    "Heat Pump Leasing",
    "Energy Efficiency Retrofits",
    "EV Charging Stations",
  ],

  C1: "How does it work?",
  C2: "Take control over your finances",
  A4: {
    "Minimum Initial Investment": "$5",
    "Redemption Window": "Semi-Annual",
    "Automated Recurring Investments": true,
    "Fully Managed": true,
  },
  D1: "Sustainability comes first",
  D2: "Diversify into the carbon-free future.",
  D3: [
    <div>
      It’s impossible to build a principled investing strategy using ESG scores.
      Rating agencies use convoluted and counterintuitive methods to measure a
      company's impact, which means you may end up with Exxon Mobil in the top
      10 of the S&P 500 ESG Index while Tesla gets dropped entirely, as happened
      early in 2022.<sup>1</sup>
    </div>,
    "At Green, we do things differently. This starts by investing in companies working to build state-of-the-art green infrastructure solutions.",
  ],
  Footnotes: [
    <FootnoteString
      t1="Source: Forbes. "
      t2="Musk claims S&P ‘lost their integrity’ after Tesla gets booted from sustainability index while Exxon is included"
      t3="."
      link="https://fortune.com/2022/05/18/tesla-sp-esg-index-exxon-elon-musk/"
    />,
  ],
};

export const aboutPage = {
  A1: "About us",
  A2: "Green is on a mission to make sustainable investing easy, attractive, and transparent.",
  B0: "Let's move",
  B1: "We're ready to get started! Are you?",
  B2: "We are reimagining what a sustainable investing strategy looks like. Whether you're an individual investor trying to build your wealth, an institution looking to diversify your sustainable holdings, or a mission-aligned company looking to raise capital, Green has something for you.",
};

export const raiseCapital = {
  A1: "Looking to raise capital for your business?",
  A2: "Green provides our investment partners with a one-stop financing solution, which emphasizes speed of execution and true flexibility.",
  A3: "Reach out! We're happy to hear from prospective partners.",
};

export const raiseCapital2 = {
  A1: "Turn green into green",
  A2: "As a first-of-its-kind platform, Green is committed to being a best-in-class partner by providing affordable capital to mission-aligned companies.",
  B0: "Looking to raise?",
  B1: "Our partnership process.",
  B2: "With expertise in the capital requirements of sustainable projects, Green connects investment partners with flexible and fast financing to help them scale their businesses.",
  B3: "Interested in learning more about how Green can help you meet your financing goals? We’re always open to speaking with new partners.",
};

export const HAFTerms = (
  <div>
    Green is partnering with {/* eslint-disable jsx-a11y/anchor-is-valid */}
    <a
      style={{ color: "white", textDecoration: "underline", cursor: "pointer" }}
      onClick={() => window.open("https://highatlasfoundation.org/")}
    >
      High Atlas Foundation
    </a>{" "}
    to plant one tree for every person that joins the waitlist for the first
    50,000 individuals that sign up after December 5, 2022. Learn more at{" "}
    {/* eslint-disable jsx-a11y/anchor-is-valid */}
    <a
      style={{ color: "white", textDecoration: "underline", cursor: "pointer" }}
      onClick={() => window.open("https://highatlasfoundation.org/")}
    >
      highatlasfoundation.org
    </a>
    .
  </div>
);

export const simpleTerms = (
  <div>
    <b>DISCLAIMER:</b> The Website – as well as all content authored by Green on
    third-party platforms – and the information contained therein should not be
    construed as offering the sale of securities or as a solicitation for offers
    to buy securities of any kind. This information is not final and subject to
    revision at any moment. Green is not presently pursuing the sale of any
    security, and will not do so until a registration statement filed with the
    Securities and Exchange Commission is effective and all applicable laws in
    the state in which the proposed securities are sold are complied with. All
    quoted performance values and descriptions of the workings of the investment
    product are hypothetical, and should not be taken as establishing a contract
    between the owners of the website and any counterparty. We expressly
    disclaim any damages or liabilities that might arise from the use of this
    website. For further guidance please refer to the “Terms of Use.”
  </div>
);

export const graphTerms = (
  <div>
    All performance values quoted on the website are hypothetical and subject to
    change. Read more below*
  </div>
);
