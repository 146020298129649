const FootnoteString = (params) => {
  return (
    <div>
      {params.t1}
      <a style={linkStyle} href={params.link} rel="noreferrer" target="_blank">
        {params.t2}
      </a>
      {params.t3}
    </div>
  );
};

export default FootnoteString;
const linkStyle = { color: "rgba(255,255,255,1)" };
