/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_mobile_analytics_app_id": "c224e02cb50d4925b37fb4938f338490",
    "aws_mobile_analytics_app_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://hrrlceko2naezmkebqq22oheuq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-fk42dkyjwveafpxv3bi2j6ze3q",
    "aws_cognito_identity_pool_id": "us-east-1:c6689a7a-3c76-4d67-8503-37504e6f738a",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_hJtlcEsr9",
    "aws_user_pools_web_client_id": "50ihh48ddh1g446hi1bpjcg223",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_user_files_s3_bucket": "green-bucket135915-master",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "referHistoryDynamoDB-master",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
