import { UserContext } from "../../contexts/UserContext";
import { useContext, useEffect, useState, useRef } from "react";
import * as mainStyle from "../../styles/mainStyle";
import * as Content from "../../assets/Content";
import TextArea from "../../components/home/TextArea";
import { isMobile } from "react-device-detect";
import { translateAdv, reverse, spacer } from "../../styles/common";
import DisclaimerButton from "../DislaimerButton";

const ContentPiece = (params) => {
  const { windowSize } = useContext(UserContext);
  const isNarrow = windowSize.innerWidth < 850;

  const isLeft = params.isLeft == null ? true : params.isLeft;
  const isCentered = params.isCentered == null ? true : params.isCentered;
  const topBottom = params.topBottom || "top";
  const pic = params.pic || "graph.png";
  const contentType = params.contentType || "graph";
  const leader = params.leader || Content.homePage.B1;
  const header = params.header || Content.homePage.B2;
  const text = params.text || Content.homePage.B3;
  const button = params.button || null;
  const bullets = params.bullets || null;
  const noAnimation = params.noAnimation || isMobile;
  const textCentered = params.textCentered || false;
  const addedStyle = params.addedStyle || {};

  const domRef = useRef();
  const [isVisible, setVisible] = useState(false || noAnimation);
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setVisible(true);
        observer.unobserve(domRef.current);
      }
    });
    observer.observe(domRef.current);

    return () => observer.disconnect();
  }, []);

  const disclaimerTextBox = (
    <div
      style={{
        color: "grey",
        fontSize: "10.5px",
        fontWeight: "500",
        textAlign: "left",
      }}
    >
      {Content.graphTerms}
    </div>
  );

  const imgContent = (
    <div
      style={basicContentDivider(
        !isLeft,
        isNarrow,
        windowSize,
        isCentered,
        textCentered
      )}
    >
      <div
        style={{
          ...{
            display: "flex",
            flexDirection: "column",
            height: isNarrow ? "250px" : "100%",
            minHeight: isNarrow ? "300px" : "350px",
            maxWidth: "400px",
            width: "100%",
            boxSizing: "border-box",
            position: "relative",
            justifyContent: "center",
          },
          ...(contentType === "header" ? { minHeight: "300px" } : {}),
          ...(contentType === "strong" || contentType === "ESG"
            ? {
                marginLeft:
                  isLeft || isNarrow ? mainStyle.margin.sides(isNarrow) : 0,
                marginRight:
                  !isLeft || isNarrow ? mainStyle.margin.sides(isNarrow) : 0,
              }
            : {}),
          ...(contentType === "graph"
            ? {
                backgroundSize: "auto 100%",
                backgroundPosition: "center center",
                marginLeft:
                  isLeft || isNarrow ? mainStyle.margin.sides(isNarrow) : 0,
                marginRight:
                  !isLeft || isNarrow ? mainStyle.margin.sides(isNarrow) : 0,
              }
            : {}),
          ...(contentType === "ESG"
            ? {
                backgroundSize: "100% auto",
                borderRadius: "15px",
                backgroundColor: mainStyle.colorGreen,
              }
            : {}),
          ...(contentType === "about"
            ? {
                marginLeft:
                  isLeft || isNarrow ? mainStyle.margin.sides(isNarrow) : 0,
                marginRight:
                  !isLeft || isNarrow ? mainStyle.margin.sides(isNarrow) : 0,
              }
            : {}),
        }}
      >
        <div
          style={{
            ...{
              backgroundSize: "cover",
              height: "100%",
              width: "100%",
              backgroundPosition:
                (isNarrow ? "center" : reverse(isLeft)) + " " + topBottom,
              backgroundRepeat: "no-repeat",
              borderRadius: isLeft ? "0px 20px 20px 0px" : "20px 0px 0px 20px",
              boxSizing: "border-box",
              position: "relative",
              backgroundImage: 'url("/' + pic + '")',
            },
            ...(contentType === "header"
              ? {
                  backgroundSize: "auto 100%",
                }
              : {}),
            ...(contentType === "strong"
              ? {
                  border: "5px solid " + mainStyle.colorGreen,
                  borderRadius: "15px",
                  backgroundPosition: "center center",
                  backgroundSize: "auto 90%",
                }
              : {}),
            ...(contentType === "ESG"
              ? {
                  borderRadius: "15px",
                  backgroundPosition: "center center",
                  backgroundSize: "100% auto",
                }
              : {}),
            ...(contentType === "graph"
              ? {
                  borderRadius: "15px",
                  backgroundSize: "auto 100%",
                  boxShadow: "0px 0px 20px 10px rgba(0,0,0,.1)",
                  backgroundPosition: "center center",
                }
              : {}),
            ...(contentType === "ESG"
              ? {
                  border: null,
                }
              : {}),
            ...(contentType === "about"
              ? {
                  maxHeight: "350px",
                  backgroundSize: "contain",
                  borderRadius: "15px",
                  border: "5px solid " + mainStyle.colorGreen,
                  backgroundPosition: "center center",
                }
              : {}),
          }}
        >
          {contentType === "graph" ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "15px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  bottom: "10px",
                  right: "10px",
                  position: "absolute",
                  color: mainStyle.colorGreenAccent,
                  fontSize: "11px",
                  fontWeight: "500",
                  cursor: "pointer",
                  borderRadius: "5px",
                  padding: "5px",
                  border: "1px solid " + mainStyle.colorGreenAccent,
                  // textDecoration: "underline",
                  // backgroundColor: mainStyle.colorGreenBackground,
                  // boxShadow: "0px 0px 20px 7px rgba(0,0,0,.05)",
                }}
                onClick={() =>
                  document
                    .getElementById("FooterID")
                    .scrollIntoView({ behavior: "smooth" })
                }
              >
                How do we measure this?<sup style={{ fontSize: "8px" }}>1</sup>
              </div>
            </div>
          ) : null}
        </div>
        {contentType === "graph" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <DisclaimerButton size="20px" clickable />
            {disclaimerTextBox}
          </div>
        ) : null}
      </div>
    </div>
  );

  return (
    <div
      ref={domRef}
      style={{ ...contentDiv(isNarrow, isVisible, noAnimation), ...addedStyle }}
    >
      {isLeft || isNarrow ? imgContent : null}
      {!(textCentered && !isNarrow) && (isLeft || isNarrow)
        ? spacer(isNarrow)
        : null}
      <div
        style={basicContentDivider(isLeft, isNarrow, null, null, textCentered)}
      >
        <TextArea
          leader={leader}
          header={header}
          text={text}
          button={button}
          isLeft={isLeft}
          bullets={bullets}
          addMargin={textCentered}
        />
      </div>
      {!(textCentered && !isNarrow) && !isLeft && !isNarrow
        ? spacer(isNarrow)
        : null}
      {!isLeft && !isNarrow ? imgContent : null}
    </div>
  );
};

export default ContentPiece;

const contentDiv = (isNarrow, isVisible, noAnimation) => ({
  display: "flex",
  width: "100%",
  alignItems: "stretch",
  flexDirection: isNarrow ? "column" : "row",
  boxSizing: "border-box",
  justifyContent: "center",

  opacity: isVisible ? 1 : 0,
  transform: isVisible ? "none" : "translate(0, 50%)",
  visibility: isVisible ? "visible" : "hidden",
  willChange: "opacity, visibility",
  transition: !noAnimation
    ? "opacity 200ms ease-out, transform 200ms ease-out"
    : "opacity 0ms ease-out, transform 0ms ease-out",
  // border: "1px solid blue",
  // height: "100px",
});

const basicContentDivider = (
  isLeft,
  isNarrow,
  windowSize = null,
  isCentered = true,
  textCentered
) => ({
  ...{
    display: "flex",
    flexDirection: "row",
    boxSizing: "border-box",
    justifyContent: isNarrow || textCentered ? "center" : translateAdv(isLeft),
    flex: 1,
    // border: "1px solid green",
    width: "100%",
  },
  ...(windowSize && !isCentered && isNarrow
    ? {
        paddingRight:
          windowSize.innerWidth - 440 > 0
            ? (windowSize.innerWidth - 450) / 2
            : mainStyle.margin.sides(isNarrow),
      }
    : {}),
});
