import NavBar from "../components/NavBar";
import * as Content from "../assets/Content";
import * as mainStyle from "../styles/mainStyle";
import { UserContext } from "../contexts/UserContext";
import { useContext } from "react";
import Footer from "../components/Footer";
import { translate, translateAdv } from "../styles/common";
import { Analytics } from "aws-amplify";
import Together from "../components/home/Together";
import DisclaimerButton from "../components/DislaimerButton";

const Invest = () => {
  const { windowSize, setThanksPopupOpen } = useContext(UserContext);
  const isNarrow = windowSize.innerWidth < 850;
  Analytics.record({ name: "visit-invest" });

  const targetVal = (img, text) => (
    <div style={TargetDiv}>
      <div
        style={{
          borderRadius: "25px",
          minWidth: "50px",
          width: "50px",
          minHeight: "50px",
          height: "50px",
          backgroundColor: mainStyle.colorGreen,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          style={{
            width: "50%",
          }}
          src={img}
          alt={"icon_" + img}
        />
      </div>
      <div
        style={{
          color: mainStyle.colorGreen,
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          // border: "1px solid red",
        }}
      >
        {text}
      </div>
    </div>
  );

  const imageAdd = (pic, isHeader = false) => (
    <div
      style={{
        ...{
          backgroundSize: "cover",
          height: "100%",
          maxWidth: isNarrow ? "100%" : "400px",
          width: isNarrow ? "400px" : "100%",
          minHeight: isNarrow ? "200px" : "375px",
          backgroundPosition: "center center",
          backgroundImage: 'url("/' + pic + '")',
          backgroundRepeat: "no-repeat",
          borderRadius: "20px",
        },
        ...(isHeader
          ? { border: "5px solid " + mainStyle.colorGreenBackground }
          : {}),
      }}
    />
  );

  const spacer = (
    <div
      style={{
        minWidth: mainStyle.margin.sides(isNarrow),
        minHeight: mainStyle.margin.sides(isNarrow),
      }}
    />
  );

  const getStartedVal = (
    <div
      style={mainStyle.ActionButtonStyle(false)}
      onClick={() => setThanksPopupOpen({ type: "together" })}
    >
      Join the waitlist!
    </div>
  );

  return (
    <div style={MainDivStyle}>
      <NavBar />
      <div style={BodyDivStyle}>
        <div
          style={{
            ...SectionDiv(isNarrow, true, "row"),
            marginTop: mainStyle.navBarHeight,
          }}
        >
          {isNarrow ? (
            <div style={basicContentDivider(isNarrow, isNarrow, true)}>
              {imageAdd("cartoon1.png", true)}
            </div>
          ) : null}
          {isNarrow ? spacer : null}
          <div style={basicContentDivider(isNarrow, isNarrow, true)}>
            <div style={textSectionDiv}>
              <div style={contentLeaderDiv(true)}>{Content.investPage.A1}</div>
              <div style={contentHeaderDiv(true, isNarrow ? 25 : 30)}>
                {Content.investPage.A2}
              </div>
              <div style={contentTextDiv}>
                <div style={contentTextSubDiv(true)}>
                  {Content.investPage.A3}
                </div>
              </div>
            </div>
            <div style={{ height: "20px" }} />
            <div style={TableDiv(isNarrow)}>
              <div style={rowDiv(true)}>
                {Object.keys(Content.investPage.A4).map((keyName, keyIndex) => {
                  return (
                    <div style={col0Div(true)} key={"row1" + keyName}>
                      {keyName}
                    </div>
                  );
                })}
              </div>
              <div style={{ height: "10px" }} />
              <div style={rowDiv()}>
                {Object.keys(Content.investPage.A4).map((keyName, keyIndex) => {
                  return (
                    <div style={col1Div(true)} key={"row1" + keyName}>
                      {Content.investPage.A4[keyName] === true ? (
                        <img
                          src="/check-mark.png"
                          style={{ width: "30%" }}
                          alt="check-mark"
                        />
                      ) : (
                        Content.investPage.A4[keyName]
                      )}
                    </div>
                  );
                })}
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "10px",
                  borderBottom: "1px solid " + mainStyle.colorGreenBackground,
                  boxSizing: "border-box",
                }}
              />
              <div style={{ height: "10px" }} />

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <DisclaimerButton light size="20px" clickable={true} />
                <div
                  style={{
                    textAlign: "left",
                    fontWeight: "600",
                    fontSize: 13,
                  }}
                >
                  Details not final and subject to change. Read more below*
                </div>
              </div>

              <div style={{ height: "20px" }} />
              {getStartedVal}
            </div>
          </div>
          {!isNarrow ? spacer : null}
          {!isNarrow ? (
            <div style={basicContentDivider("flex-start", isNarrow, true)}>
              {imageAdd("cartoon1.png", true)}
            </div>
          ) : null}
        </div>
        {spacer}

        <div style={SectionDiv(isNarrow, false)}>
          <div style={basicContentDivider(true, isNarrow)}>
            <div style={textSectionDiv}>
              <div style={contentLeaderDiv()}>{Content.investPage.D1}</div>
              <div style={contentHeaderDiv(true, isNarrow ? 25 : 30)}>
                {Content.investPage.D2}
              </div>
              <div style={contentTextDiv}>
                <div style={contentTextSubDiv()}>
                  {Content.investPage.D3[0]}
                </div>
                <div style={contentTextSubDiv()}>
                  {Content.investPage.D3[1]}
                </div>
              </div>
            </div>
          </div>
          {spacer}
          {isNarrow ? spacer : null}
          <div style={basicContentDivider(true, isNarrow)}>
            <div style={subContentDivider(true)}>
              {targetVal("solar.png", Content.investPage.B3[0])}
              {spacer}
              {targetVal("thermometer.png", Content.investPage.B3[1])}
            </div>
            {spacer}
            <div style={subContentDivider(true)}>
              {targetVal("green-house.png", Content.investPage.B3[2])}
              {spacer}
              {targetVal("thunder.png", Content.investPage.B3[3])}
            </div>
          </div>
        </div>
        {spacer}
        <Together noTopMargin={true} />
        <Footer footnotes={Content.investPage.Footnotes} />
      </div>
    </div>
  );
};

export default Invest;

const MainDivStyle = {
  display: "flex",
  flexDirection: "column",
  cursor: "default",
  backgroundColor: mainStyle.colorGreenBackground,
  // userSelect: "none",
  fontFamily: "Lora",
  boxSizing: "border-box",
};

const BodyDivStyle = {
  display: "flex",
  flexDirection: "column",
  width: "100vw",
  boxSizing: "border-box",
};

const SectionDiv = (isNarrow, dark = true, flexDirection = "column") => ({
  display: "flex",
  width: "100%",
  boxSizing: "border-box",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: isNarrow ? "column" : flexDirection,
  paddingTop: mainStyle.margin.sides(false),
  paddingBottom: mainStyle.margin.sides(false),
  paddingLeft: mainStyle.margin.sides(isNarrow),
  paddingRight: mainStyle.margin.sides(isNarrow),
  backgroundColor: dark ? mainStyle.colorGreen : "transparent",
  color: dark ? "white" : mainStyle.colorGreenAccent,
  // border: "1px solid red",
});

const TableDiv = (isNarrow) => ({
  display: "flex",
  width: !isNarrow ? "500px" : "100%",
  maxWidth: !isNarrow ? "400px" : "400px",
  fontSize: !isNarrow ? 15 : 13,
  boxSizing: "border-box",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  // border: "1px solid blue",
});

const rowDiv = (underline) => ({
  ...{
    display: "flex",
    flex: 1,
    width: "100%",
    boxSizing: "border-box",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  ...(underline
    ? {
        borderBottom: "1px solid " + mainStyle.colorGreenBackground,
        borderTop: "1px solid " + mainStyle.colorGreenBackground,
        flex: 0,
      }
    : {}),
});

const col0Div = () => ({
  ...{
    display: "flex",
    boxSizing: "border-box",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: "100%",
    flex: 1,
    color: mainStyle.colorGreenBackground,
    fontSize: 12,
    padding: "5px",
    textAlign: "center",
  },
});

const col1Div = () => ({
  ...{
    display: "flex",
    boxSizing: "border-box",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: "100%",
    flex: 1,
    color: mainStyle.colorGreenBackground,
    fontSize: 20,
    fontWeight: "700",
    textAlign: "center",
    // border: "1px solid red",
  },
});

const basicContentDivider = (
  isLeft = true,
  isNarrow = false,
  flexDir = false
) => ({
  display: "flex",
  flexDirection: isNarrow || flexDir ? "column" : "row",
  boxSizing: "border-box",
  justifyContent: translateAdv(isLeft),
  textAlign: translate(isLeft),
  alignItems: translateAdv(isLeft),
  flex: 1,
  height: "100%",
  // border: "1px solid blue",
  maxWidth: "100%",
});

const subContentDivider = (isLeft = true) => ({
  display: "flex",
  flexDirection: "row",
  boxSizing: "border-box",
  justifyContent: translateAdv(isLeft),
  textAlign: translate(isLeft),
  alignItems: "center",
  height: "100%",
  // border: "1px solid red",
});

const contentLeaderDiv = (isLeft = true) => ({
  display: "flex",
  fontSize: 15,
  fontWeight: "700",
  fontFamily: "Lora",
  width: "100%",
  justifyContent: translateAdv(isLeft),
  textAlign: translate(isLeft),
});

const contentHeaderDiv = (isLeft = true, size = 40) => ({
  display: "flex",
  fontSize: size,
  fontWeight: "700",
  fontFamily: "Lora",
  width: "100%",
  justifyContent: translateAdv(isLeft),
  textAlign: translate(isLeft),
});

const contentTextDiv = {
  display: "flex",
  flexDirection: "column",
  maxWidth: "500px",
  minWidth: "100%",
};

const contentTextSubDiv = (isLeft = true, marginSize = "10px") => ({
  display: "flex",
  fontSize: 15,
  fontWeight: "light",
  fontFamily: "Lora",
  marginTop: marginSize,
  width: "100%",
  justifyContent: translateAdv(isLeft),
  textAlign: translate(isLeft),
});

const textSectionDiv = {
  display: "flex",
  maxWidth: "400px",
  flexDirection: "column",
  justifyContent: "center",
  // border: "1px solid red",
};

const TargetDiv = {
  display: "flex",
  minWidth: "150px",
  maxWidth: "150px",
  minHeight: "120px",
  maxHeight: "120px",
  // borderRadius: "150px",
  borderBottom: "1px solid " + mainStyle.colorGreen,
  // backgroundColor: mainStyle.colorGreen,
  height: "100%",
  flexDirection: "column",
  color: mainStyle.colorGreenBackground,
  paddingLeft: "20px",
  paddingRight: "20px",
  boxSizing: "border-box",
  fontWeight: "700",
  fontSize: 14,
  // justifyContent: "center",
  textAlign: "center",
  alignItems: "center",
};
