export const colorGreen = "#2A836C";
export const colorGreenBackground = "#F0FFF5";
export const colorGreenAccent = "#2A836C";
export const colorError = "#ff6348";
export const navBarHeight = "80px";
export const margin = {
  gaps: (isNarrow) => (isNarrow ? "10px" : "15px"),
  sidesTiny: (isNarrow) => (isNarrow ? "20px" : "30px"),
  sidesNarrow: (isNarrow) => (isNarrow ? "25px" : "40px"),
  sides: (isNarrow) => (isNarrow ? "25px" : "50px"),
  wideSides: (isNarrow) => (isNarrow ? "30px" : "70px"),
  twoSides: (isNarrow) => (isNarrow ? "50px" : "100px"),
  sideVal: (isNarrow) => (isNarrow ? 50 : 100),
  investSides: (isNarrow) => (isNarrow ? "35px" : "50px"),
};
export const fontFamily = "Lora";
export const smallRadius = "7px";
export const mediumRadius = "10px";
export const largeRadius = "20px";
export const minWidth = "300px";

export const ActionButtonStyle = (dark) => ({
  display: "flex",
  height: "60px",
  width: "100%",
  fontSize: 18,
  textAlign: "center",
  borderRadius: mediumRadius,
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  boxSizing: "border-box",
  fontWeight: "700",
  textDecoration: "none",
  color: dark ? colorGreenBackground : colorGreen,
  backgroundColor: dark ? colorGreen : colorGreenBackground,
  boxShadow: "0px 0px 30px 10px rgba(0,0,0,.09)",
  userSelect: "none",
});
