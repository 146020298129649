/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createNewWaitlistMember = /* GraphQL */ `
  mutation CreateNewWaitlistMember($email: AWSEmail!, $referrer: String) {
    createNewWaitlistMember(email: $email, referrer: $referrer)
  }
`;
export const createWaitlistMember = /* GraphQL */ `
  mutation CreateWaitlistMember(
    $input: CreateWaitlistMemberInput!
    $condition: ModelWaitlistMemberConditionInput
  ) {
    createWaitlistMember(input: $input, condition: $condition) {
      id
      email
      referralId
      scoreId
      score
      referrer
      givenName
      age
      refer
      invest
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateWaitlistMember = /* GraphQL */ `
  mutation UpdateWaitlistMember(
    $input: UpdateWaitlistMemberInput!
    $condition: ModelWaitlistMemberConditionInput
  ) {
    updateWaitlistMember(input: $input, condition: $condition) {
      id
      email
      referralId
      scoreId
      score
      referrer
      givenName
      age
      refer
      invest
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteWaitlistMember = /* GraphQL */ `
  mutation DeleteWaitlistMember(
    $input: DeleteWaitlistMemberInput!
    $condition: ModelWaitlistMemberConditionInput
  ) {
    deleteWaitlistMember(input: $input, condition: $condition) {
      id
      email
      referralId
      scoreId
      score
      referrer
      givenName
      age
      refer
      invest
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createContactMember = /* GraphQL */ `
  mutation CreateContactMember(
    $input: CreateContactMemberInput!
    $condition: ModelContactMemberConditionInput
  ) {
    createContactMember(input: $input, condition: $condition) {
      entity
      companyName
      fullName
      email
      inquiryTopic
      inquiryDetail
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateContactMember = /* GraphQL */ `
  mutation UpdateContactMember(
    $input: UpdateContactMemberInput!
    $condition: ModelContactMemberConditionInput
  ) {
    updateContactMember(input: $input, condition: $condition) {
      entity
      companyName
      fullName
      email
      inquiryTopic
      inquiryDetail
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteContactMember = /* GraphQL */ `
  mutation DeleteContactMember(
    $input: DeleteContactMemberInput!
    $condition: ModelContactMemberConditionInput
  ) {
    deleteContactMember(input: $input, condition: $condition) {
      entity
      companyName
      fullName
      email
      inquiryTopic
      inquiryDetail
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createReferMember = /* GraphQL */ `
  mutation CreateReferMember(
    $input: CreateReferMemberInput!
    $condition: ModelReferMemberConditionInput
  ) {
    createReferMember(input: $input, condition: $condition) {
      referName
      referEmail
      email0
      email1
      email2
      email3
      email4
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateReferMember = /* GraphQL */ `
  mutation UpdateReferMember(
    $input: UpdateReferMemberInput!
    $condition: ModelReferMemberConditionInput
  ) {
    updateReferMember(input: $input, condition: $condition) {
      referName
      referEmail
      email0
      email1
      email2
      email3
      email4
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteReferMember = /* GraphQL */ `
  mutation DeleteReferMember(
    $input: DeleteReferMemberInput!
    $condition: ModelReferMemberConditionInput
  ) {
    deleteReferMember(input: $input, condition: $condition) {
      referName
      referEmail
      email0
      email1
      email2
      email3
      email4
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
