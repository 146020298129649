export const title = "Terms of Use";
export const body = (
  <div>
    THESE JEREMY BEN-MEIR WEBSITE TERMS OF USE (these <b>“Terms”</b>) are agreed
    to between Jeremy Ben-Meir (<b>“Green,” “them,” “we,” “us,”</b> and
    <b>“our”</b>) and you (<b>“You”</b> or <b>“Your”</b>), or the entity on
    whose behalf you are agreeing to these terms. <br />
    <br />
    These Terms apply to the website located at https://www.withgreen.co and all
    other sites, mobile sites, Websites, applications, platforms and tools where
    these Terms appear or are linked (collectively, the "Website"). These Terms
    also apply to the online portal accessible through the Website (
    <b>“My Account”</b>). You and other individuals or entities using the
    Website are collectively referred to as <b>“Users.”</b> <br />
    <br />
    PLEASE READ THESE TERMS CAREFULLY. BY ACCESSING OR OTHERWISE USING THE
    WEBSITE, YOU AGREE THAT YOU HAVE READ AND AGREE TO BE BOUND BY THESE TERMS.
    Any person or entity who interacts with the Website, whether through
    automated means, third-party means or otherwise, is considered a User.
    Unless you have entered into a separate written agreement with Jeremy
    Ben-Meir regarding the Website, these Terms are the complete and exclusive
    agreement between you and Jeremy Ben-Meir regarding your access to and use
    of the Website and supersede any oral or written proposal, quote, or other
    communication between you and Jeremy Ben-Meir regarding your access to and
    use of the Website.
    <br />
    <br />
    IF YOU DO NOT AGREE TO THESE TERMS, or do not meet the qualifications
    included in THESE TERMS YOU MUST NOT ACCESS OR USE THE WEBSITE. <br />
    <br />
    <b>1. Definitions.</b> Words and phrases used in these Terms have the
    definitions given in these Terms or, if not defined herein, have their plain
    English meaning as commonly interpreted in the United States. <br />
    <br /> <b>2. Term.</b> These Terms are entered into as of the date you first
    access or use the Website (the “Effective Date”) and will continue until
    terminated as set forth herein. <br />
    <br /> <b>3. Modifications.</b> We reserve the right, at any time, to modify
    the Website, as well as these Terms, whether by making those modifications
    available through the Website or by providing notice to you as specified in
    these Terms. Any modifications will be effective 24 hours following posting
    through the Website or delivery of such other notice. You must cease using
    the Website or terminate these Terms at any time if you do not agree to any
    modification. However, you will be deemed to have agreed to any and all
    modifications through your continued use of the Website following such
    notice. Any modifications to the Website, including all updates, upgrades,
    new versions, and new releases, will be treated as part of the “Website” for
    purposes of these Terms. <br />
    <br />
    <b>4. AGE Eligibility.</b> The Website is intended for use by Users of 18
    years of age and older. <br />
    <br />
    <b>5. Access.</b> Subject to your compliance with these Terms, we will
    permit you to access and use the Website solely for lawful purposes and only
    in accordance with these Terms. <br /> <br />
    <div style={{ padding: "0px 30px 0px 30px" }}>
      1. Unlawful or Prohibited Uses of the Website. The Website may only be
      used for lawful purposes in accordance with these Terms. As a condition of
      your use of the Website, you represent and warrant to us that you will not
      use the Website for any purpose that is unlawful or prohibited by these
      Terms. Whether on behalf of yourself or on behalf of any third party, you
      will not: <br /> <br />
      2. Make any commercial use of the Website other than in furtherance of any
      Transactions that you make through the Website; <br /> <br />
      3. Download, copy or transmit any content for the benefit of any third
      party; <br /> <br />
      4. Misrepresent your identity, impersonate any person or entity, falsely
      state or otherwise misrepresent your affiliation with any person or entity
      in connection with the Website, or express or imply that we endorse any
      statement that you make; <br /> <br />
      5. Conduct fraudulent activities through the Website; and <br /> <br />
      6. Use the Website to defame, abuse, harass, stalk, threaten or otherwise
      violate the legal rights of others, including others’ privacy rights or
      rights of publicity.
    </div>
    <br />
    <b>6. Accounts.</b> Before using the Website and in particular My Account,
    you may be required to establish an account (an “Account”). Approval of your
    request to establish an Account will be at our sole discretion. Each Account
    and the User identification and password for each Account (the “Account ID”)
    is personal in nature. You will not distribute or transfer your Account or
    Account ID or provide a third party with the right to access your Account or
    Account ID. You are solely responsible for all use of the Website through
    your Account. You will ensure the security and confidentiality of your
    Account ID and will notify us immediately if any Account ID is lost, stolen
    or otherwise compromised. Any activities completed through your Account or
    under your Account ID will be deemed to have been done by you. You may not:
    (1) select or use an Account ID of another User with the intent to
    impersonate that User; and (2) use an Account ID that we, in our sole
    discretion, deem offensive. <br />
    <br />
    <b>7. Our Content.</b>
    <br />
    <br />
    <div style={{ padding: "0px 30px 0px 30px" }}>
      <b>1. Ownership and Responsibility.</b> All content included with the
      Website that we provide such as text, graphics, logos, images, software,
      application updates, and other materials (collectively, “Our Content”) is
      the owned or licensed property of Jeremy Ben-Meir and their suppliers or
      licensors and is protected by United States and international copyright,
      trademark, patent, or other proprietary rights (collectively, “IPR”).
      Jeremy Ben-Meir and its suppliers and licensors expressly reserve all IPR
      in all Our Content. You are solely responsible for verifying the accuracy,
      completeness, and applicability of all Our Content and for your use of any
      of Our Content. Except as set forth in these Terms, you are granted no
      licenses or rights in or to any of Our Content, or any IPR therein or
      related thereto. <br />
      <b>2. Viewing Our Content.</b> Subject to your compliance with these
      Terms, you may view Our Content, solely as presented on the Website, in
      furtherance of any Transactions that you make through the Website and any
      other permitted uses of the Website. You will not directly or indirectly
      use any of Our Content for any other purpose. You will not, and will not
      permit any third party to: (a) alter, modify, copy, reproduce, publish, or
      create derivative works of any of Our Content; (b) distribute, sell,
      resell, lend, loan, lease, license, sublicense or transfer any of Our
      Content; or (c) alter, obscure or remove any copyright, trademark or any
      other notices that are provided on or in connection with any of Our
      Content.
    </div>
    <br />
    <b>8. Transactions.</b>
    <br />
    <br />
    <div style={{ padding: "0px 30px 0px 30px" }}>
      <b>1 With Us.</b> The Website provides you with the opportunity to buy
      products sold by us (<b>“Products”</b>). You will pay us any fees
      applicable to Products that you purchase.
      <br />
      <b> 2 Transaction Information.</b>If you wish to purchase any Products
      available through the Website (each such purchase, a <b>“Transaction”</b>
      ), you may be asked to supply certain information relevant to your
      Transaction including, without limitation, information about your method
      of payment (such as your payment card number and expiration date), your
      billing address, and your shipping information (collectively,
      <b>“Transaction Information”</b>). You represent and warrant that you have
      the legal right to use any Transaction Information utilized in connection
      with any Transaction. By submitting Transaction information to us, you
      grant to us the right to provide such information to third parties for the
      purposes of facilitating the completion of Transactions initiated by you
      or on your behalf. Verification of Transaction Information may be required
      prior to the acknowledgment or completion of any Transaction. <br />
      <b>3 Products.</b> All descriptions, images, references, features,
      content, specifications, Products and prices of Products are subject to
      change at any time without notice. The inclusion of any Products on the
      Website does not imply or warrant that these Products will be available.
      It is your responsibility to ascertain and obey all applicable local,
      state, federal, and international laws in regard to the receipt,
      possession, use, and sale of any Item. By entering into a Transaction, you
      represent and warrant that the Item that you buy will be used only in a
      lawful manner. We reserve the right, with or without prior notice, to do
      any one or more of the following: (a) limit the available quantity of or
      discontinue any Product; (b) impose conditions on the honoring of any
      coupon, coupon code, promotional code, or other similar promotion; (c) bar
      you from making or completing any or all Transaction(s); and (d) refuse to
      provide you with any Product.
      <br />
      <b> 4 Payment.</b> You agree to pay us all Transaction charges that may be
      incurred by you or on your behalf through the Website, at the price(s)
      then in effect for the Products ordered. In addition, you will remain
      responsible for any taxes that may be applicable to your Transactions. You
      will pay us all such Transaction charges by credit card upon the
      finalization of the applicable Transaction. <br />
      <b>5 Availability, Errors and Inaccuracies.</b> We make a conscientious
      effort to describe and display Products accurately on the Website. Despite
      these efforts, a small number of Products may be described inaccurately,
      or unavailable, and we may experience delays in updating Product
      information. As a result, we cannot and do not guarantee the accuracy or
      completeness of any such information, including prices, Product images,
      specifications, and availability. We reserve the right to change or update
      Product information and to correct errors, inaccuracies, or omissions at
      any time without prior notice. If we determine that there were
      inaccuracies in Product information, we will cancel your Transaction and
      notify you of such cancellation via email. <br />
      <b>6 Rules for Promotions.</b> These Terms govern any sweepstakes,
      contests, raffles, discounts, or other promotions (collectively,
      “Promotions”) made available through the Website. Promotions also may be
      governed by rules that are separate from these Terms. If you participate
      in any Promotions, please review the applicable rules as well as the
      Privacy Policy (linked below). If the rules for a Promotion conflict with
      these Terms, the Promotion rules will apply.{" "}
    </div>
    <br />
    <br />
    <b>9. Third-Party Content and Website.</b> <br />
    <div style={{ padding: "0px 30px 0px 30px" }}>
      <br />
      <b>1. Third-Party Content.</b>Content is also provided through the Website
      by other Users. Other Users might post content that is inaccurate,
      misleading, or deceptive. We neither endorse nor are responsible for any
      opinion, advice, information, or statements made by third parties. If you
      rely on third-party content, you assume the risk that it might be
      inaccurate, misleading, or deceptive. The opinions expressed by third
      parties reflect solely the opinions of the individuals who submitted such
      opinions and may not reflect our opinions. <br />
      <b>2. Third-Party Website.</b>We may provide you with the ability to
      access websites developed, provided, or maintained by third-party service
      providers through the Website (<b>“Third-Party Website”</b>). Third-Party
      Websites may integrate with, pull content from, or add content to the
      Website. In addition to these Terms, your access to and use of any
      Third-Party Website is also subject to any other agreement you may agree
      to before being given access to the Third-Party Website (each, a
      <b>“Third-Party Service Agreement”</b>). The terms of any Third-Party
      Service Agreement will apply to the applicable Third-Party Website
      provided under that Third-Party Service Agreement in addition to these
      Terms but will not apply to any other Website you may access.
      <br />
      <b>3. Links to Third-Party Websites.</b>The Website may contain links and
      interactive functionality interacting with the websites of third parties.
      We are not responsible for, and have no liability for, the functionality,
      actions, inactions, settings, privacy policies, terms, or content of any
      such third-party website. Before enabling any sharing functions of the
      Website to communicate with any such third-party website or otherwise
      visiting any such third-party website, we strongly recommend that you
      review and understand the terms and conditions, privacy policies, and
      settings of each such third-party website. The links and interactive
      functionality for Third-Party Websites through the Website do not
      constitute an endorsement by us of such Third-Party Websites. Other
      websites may link to the Website with or without our authorization, and we
      may block any links to or from the Website in our sole discretion. YOUR
      USE OF THIRD-PARTY WEBSITES IS AT YOUR OWN RISK.
    </div>
    <br />
    <b> 10. Customer Service.</b> We or our agents may call, text, or email you
    at the telephone number and/or email address that you provide us, regarding
    customer service issues. Our use of your contact information will be handled
    in accordance with our Privacy Policy (linked below). <br /> <br />
    <b>11. Termination.</b>To the extent permitted and in accordance with
    applicable law, we may terminate your access to the Website or Your Account
    at any time if you violate these Terms or for any reason, at our sole
    discretion. You may terminate these Terms at any time by ceasing to access
    the Website and by destroying any Materials that you have been permitted to
    download from the Website. <br /> <br />
    <b>12. Suspension.</b> Without limiting our right to terminate these Terms,
    we may also suspend your access to your Account and the Website, with or
    without notice to you, upon any actual, threatened, or suspected breach of
    these Terms or applicable law or upon any other conduct deemed by us to be
    inappropriate or detrimental to Jeremy Ben-Meir, the Website, or any other
    User or third party. <br /> <br />
    <b>13. Technology and Marks.</b> The Website, and the databases, software,
    hardware and other technology used by or on our behalf to operate the
    Website, and the structure, organization, and underlying data, information
    and software code thereof (collectively, the “Technology”), may constitute
    valuable trade secrets of Jeremy Ben-Meir. You will not, and will not permit
    any third party to: (1) access or attempt to access the Technology except as
    expressly provided in these Terms; (2) use the Technology in any unlawful
    manner or in any other manner that could damage, disable, overburden or
    impair the Technology; (3) use automated scripts to collect information from
    or otherwise interact with the Technology; (4) alter, modify, reproduce, or
    create derivative works of the Technology; (5) distribute, sell, resell,
    lend, loan, lease, license, sublicense, or transfer any of your rights to
    access or use the Technology or otherwise make the Technology available to
    any third party; (6) reverse engineer, disassemble, decompile, or otherwise
    attempt to derive the method of operation of the Technology; (7) attempt to
    circumvent or overcome any technological protection measures intended to
    restrict access to any portion of the Technology; (8) monitor the
    availability, performance, or functionality of the Technology; or (9)
    interfere with the operation or hosting of the Technology. We use reasonable
    means to protect the security of the Website, but you acknowledge that
    perfect security on the internet is impossible and that, as a result, Your
    Content may be exposed in the event of a breach. We retain all rights,
    title, and interest, including, without limitation, all IPR in and to the
    Technology and any additions, improvements, updates, and modifications
    thereto. You receive no ownership interest in or to the Technology, and you
    are not granted any right or license to use the Technology itself, apart
    from your ability to access and use the Website under these Terms. The name,
    logo and all product and service names associated with the Website or Items
    are trademarks of Jeremy Ben-Meir and their licensors and providers, and you
    are granted no right or license to use them.
    <br /> <br />
    <b>14. Representations and Warranties.</b> You hereby represent and warrant
    to us that: (a) you have the legal right and authority to enter into these
    Terms; (b) these Terms form a binding legal obligation on your behalf; (c)
    you have the legal right and authority to perform your obligations under
    these Terms and to grant the rights and licenses described in these Terms;
    and (d) your access to, and use of, the Website and Your Content, will
    comply with all applicable laws, rules, regulations, and third-party rights
    and will not cause us to violate any applicable laws, rules, regulations, or
    third-party rights.
    <br /> <br />
    <b> 15. Disclaimers. </b>WE CANNOT AND DO NOT REPRESENT OR WARRANT THAT THE
    WEBSITE WILL BE ERROR-FREE, UNINTERRUPTED, FREE FROM UNAUTHORIZED ACCESS, OR
    OTHERWISE MEET YOUR REQUIREMENTS. THE WEBSITE AND ALL CONTENT, ITEMS,
    TECHNOLOGY, AND OTHER INFORMATION INCLUDED ON OR OTHERWISE MADE AVAILABLE TO
    YOU THROUGH THE WEBSITE (COLLECTIVELY, THE “MATERIALS”) ARE PROVIDED BY US
    ON AN “AS IS,” “AS AVAILABLE” BASIS, WITHOUT REPRESENTATIONS OR WARRANTIES
    OF ANY KIND. WE MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS
    OR IMPLIED, AS TO THE OPERATION OF THE MATERIALS, THE ACCURACY OR
    COMPLETENESS OF THE MATERIALS, OR THAT COMMUNICATIONS SENT FROM US ARE FREE
    OF MALWARE OR OTHER HARMFUL COMPONENTS. YOU EXPRESSLY AGREE THAT YOUR USE OF
    THE MATERIALS IS AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW,
    WE DISCLAIM ANY AND ALL REPRESENTATIONS AND WARRANTIES WITH RESPECT TO THE
    MATERIALS, WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION,
    WARRANTIES OF TITLE, MERCHANTABILITY, AND FITNESS FOR A PARTICULAR PURPOSE
    OR USE.
    <br /> <br /> <b>16. Indemnity. </b>You hereby agree to indemnify, defend,
    and hold harmless us affiliates, employees, agents, contractors, Users,
    customers, providers, licensees, successors-in-interest, and assigns
    (“Indemnified Parties”) from any and all claims, suits, demands, actions,
    losses, liabilities, damages, judgments, settlements, fines, penalties,
    fees, expenses and costs (including attorneys’ fees and court costs) arising
    in any manner from: (1) your access to, or use of, the Materials; (2) Your
    Content; and (3) your breach of any representation, warranty, or other
    provision of these Terms. We will provide you with notice of any such
    indemnifiable claim or allegation, and we will have the right to participate
    in the defense of any such claim at our expense.
    <br /> <br /> <b>17. Limitation on Liability.</b> WE WILL NOT BE LIABLE FOR
    ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, RELIANCE, OR
    CONSEQUENTIAL DAMAGES, HOWEVER CAUSED, UNDER ANY THEORY OF LIABILITY,
    WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
    OTHERWISE), ARISING IN CONNECTION WITH, OR OUT OF THE USE OF, THE MATERIALS,
    EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, INCLUDING,
    WITHOUT LIMITATION, ANY LOSS OF YOUR CONTENT, OPPORTUNITY, REVENUES OR
    PROFITS, BUSINESS INTERRUPTION, OR PROCUREMENT OF SUBSTITUTE GOODS OR
    WEBSITE. OUR TOTAL CUMULATIVE LIABILITY IN CONNECTION WITH THESE TERMS AND
    ALL MATERIALS, WHETHER IN CONTRACT OR TORT OR OTHERWISE, WILL NOT EXCEED
    $100. YOU AGREE THAT WE WOULD NOT ENTER INTO THESE TERMS WITHOUT THESE
    LIMITATIONS ON OUR LIABILITY. IN JURISDICTIONS WHERE LIMITATION OF LIABILITY
    FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES IS NOT PERMITTED, OUR LIABILITY IS
    LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW.
    <br /> <br /> <b>18. Data Privacy.</b> All information we collect on this
    Website is subject to our Privacy Policy. By using this Website, you consent
    to all actions taken by us with respect to your information in compliance
    with the Privacy Policy. Notwithstanding anything in the Privacy Policy, we
    will have the right to collect, extract, compile, synthesize, and analyze
    non-personally identifiable data or information resulting from your access
    to, and use of, the Services. To the extent any such non-personally
    identifiable data or information is collected or generated by us, the data
    and information will be solely owned by us and may be used by us for any
    lawful business purpose without a duty of accounting to you, provided that
    the data and information is used only in an aggregated form, without
    directly identifying you or any other entity or natural person as the source
    thereof. <br /> <br />
    <b>19. FeedBack.</b> If You provide us any feedback or suggestions regarding
    the Website or Content (“Feedback”), You hereby assign to Jeremy Ben-Meir
    all rights in the Feedback and agree that Green shall have the right to use
    such Feedback and related information in any manner it deems appropriate
    without a duty of accounting to You. Jeremy Ben-Meir will treat any Feedback
    You provide to them as non-confidential and non-proprietary.  You agree that
    You will not submit to Green any information or ideas that You consider to
    be confidential or proprietary.
    <br /> <br /> <b>20. Disputes.</b>{" "}
    <div style={{ padding: "0px 30px 0px 30px" }}>
      <br /> <b>1. Agreement to Arbitrate.</b> Except as otherwise provided in
      these Terms, the parties will attempt to resolve all disputes,
      controversies, or claims arising under, out of, or relating to these
      Terms, including (a) the formation, validity, binding effect,
      interpretation, performance, breach, or termination of these Terms; (b)
      the arbitrability of the issues submitted to arbitration hereunder; and
      (c) non-contractual claims relating to these Terms (each, a{" "}
      <b>“Dispute”</b>) through discussion between the parties. Except as
      otherwise provided in these Terms, if any Dispute cannot be resolved
      through negotiations between the parties within 15 days of notice from one
      party to the other of the Dispute, either party may submit such Dispute
      for final settlement through binding arbitration under the Code of
      Arbitration Procedure of the Financial Industry Regulatory Authority (
      <b>“FINRA”</b>). Either party may commence the arbitration by delivering a
      request for arbitration as specified in the Code of Arbitration Procedure.
      The arbitration will be conducted before a sole neutral arbitrator,
      selected as provided in the Code of Arbitration Procedure. The arbitration
      will be conducted in the English language at a site specified by us in New
      York, NY, United States. The arbitrator will apply the governing law set
      forth in these Terms to any such arbitration and shall have the power to
      award any remedy available at law or in equity; provided, however, that
      the arbitrator shall have no jurisdiction to amend these Terms or grant
      any relief not permitted herein or beyond the relief permitted herein. The
      award of the arbitrator will be the exclusive remedy of the parties for
      all claims, counterclaims, issues, or accountings presented or plead to
      the arbitrator. The award of the arbitrator will require the
      non-prevailing party to pay the substantially prevailing party’s costs,
      fees and expenses (including reasonable attorneys’ fees). Judgment upon
      the award may be entered in any court or governmental body having
      jurisdiction thereof. Any additional costs, fees, or expenses incurred in
      enforcing the award may be charged against the party that resists its
      enforcement. <br />
      <b>2. Exception to Arbitration.</b> You agree that if we reasonably
      believe that you have, in any manner, violated or threatened to infringe
      our IPR, then we may seek emergency, preliminary or other appropriate
      interim relief in the federal or state courts located in New York, United
      States.
      <br />
      <b> 3. Governing Law and Venue.</b> The interpretation of the rights and
      obligations of the parties under these Terms, including, to the extent
      applicable, any negotiations, arbitrations or other proceedings hereunder,
      will be governed in all respects exclusively by the laws of the State of
      New York, United States without regard to conflict of law principles. The
      United Nations Convention on Contracts for the International Sale of Goods
      shall not apply to these Terms, and the parties hereby disclaim the
      application thereof. Subject to the arbitration provision of these Terms,
      each party will bring any action or proceeding arising from or relating to
      these Terms exclusively in the federal or state courts located in New
      York, and you irrevocably submit to the personal jurisdiction and venue of
      any such court in any such action or proceeding.
    </div>
    <br />
    <b>21. Notices.</b> Unless otherwise specified in these Terms, any notices
    required or allowed under these Terms will be provided to us by postal mail
    to the address for Jeremy Ben-Meir listed on the Website. We may provide you
    with any notices required or allowed under these Terms by sending you an
    email to any email address that you provide to us in connection with your
    Account, provided that in the case of any notice applicable both to you and
    other Users, we may instead provide such notice by posting it on the
    Website. Notices provided to us will be deemed given when we actually
    receive them. Notice provided to you will be deemed given 24 hours after
    posting to the Website or sending via e-mail, unless (as to e-mail) we are
    notified that the e-mail address is invalid. <br /> <br />
    <b>22. Additional Terms.</b> We may amend these Terms at any time for any
    reason. Except where required by applicable law, we may make such
    modifications effective upon implementation and without advance notice to
    you.  Your continued use of the Website following any such modification
    constitutes evidence of your acceptance of it.  We will generally make any
    amended form of these Terms available to you through the Website. All
    waivers by us under these Terms must be in writing or later acknowledged by
    us in writing. Any waiver or failure by us to enforce any provision of these
    Terms on one occasion will not be deemed a waiver by us of any other
    provision or of such provision on any other occasion. If any provision of
    these Terms is held to be unenforceable, that provision will be removed to
    the extent necessary to comply with the law, replaced by a provision that
    most closely approximates the original intent and economic effect of the
    original to the extent consistent with the law, and the remaining provisions
    will remain in full force. The substantially prevailing party in any lawsuit
    or proceeding arising from or related to these Terms will be entitled to
    receive its costs, expert witness fees and reasonable attorneys’ fees,
    including costs and fees on appeal. Neither these Terms nor any of your
    rights or obligations hereunder may be assigned or transferred by you (in
    whole or in part and including by sale, merger, consolidation, or other
    operation of law) without our prior written approval. Any assignment in
    violation of the foregoing will be null and void. We may freely assign these
    Terms. The words “include,” “includes” and “including” means “include,”
    “includes” or “including,” in each case, “without limitation.” The parties
    hereto are independent parties, not agents, employees or employers of the
    other or joint ventures, and neither acquires hereunder any right or ability
    to bind or enter into any obligation on behalf of the other.
  </div>
);
