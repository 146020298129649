import React from "react";
import * as mainStyle from "../../styles/mainStyle";
import { UserContext } from "../../contexts/UserContext";
import { useContext, useState } from "react";
import TextArea from "./TextArea";
import * as Content from "../../assets/Content";

const ReportInfo = (params) => {
  const { windowSize, setThanksPopupOpen } = useContext(UserContext);
  const isNarrow = windowSize.innerWidth < 850;
  const noTopMargin = params.noTopMargin ? params.noTopMargin : true;
  const greenStyle = params.greenStyle ? params.greenStyle : false;
  const [extraText, setExtraText] = useState(false);

  // useEffect(() => {
  //   document
  //     .getElementById("top-info-id")
  //     .scrollIntoView({ behavior: "smooth" });
  // }, [extraText]);

  const myText = (
    <TextArea
      leader={Content.infoPanel.A1}
      header={Content.infoPanel.A2}
      text={[
        !extraText
          ? Content.infoPanel.A3(() => setExtraText(true))
          : Content.infoPanel.A4(() => setExtraText(false)),
      ]}
      isLeft={true}
      noMargins={true}
      color={greenStyle ? mainStyle.colorGreenBackground : mainStyle.colorGreen}
    />
  );

  return (
    <div
      style={wholeDiv(
        isNarrow,
        noTopMargin,
        windowSize.innerWidth < 540,
        params.popup,
        greenStyle
      )}
    >
      {params.popup && (
        <div
          onClick={() => setThanksPopupOpen(null)}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            width: "15px",
            height: "15px",
            cursor: "pointer",
          }}
        >
          <img
            alt="close signup"
            src={isNarrow ? "close-dark.png" : "close-dark.png"}
            style={{ height: "100%", width: "100%" }}
          />
        </div>
      )}
      <div
        style={mainDiv(
          isNarrow,
          noTopMargin,
          windowSize.innerWidth < 540,
          params.popup,
          greenStyle,
          params.whiteBorder
        )}
      >
        <div
          id="top-info-id"
          style={{
            boxSizing: "border-box",
            justifyContent: "center",
            minHeight: "100%",
            maxHeight: "100%",
            maxWidth: "100%",
            minWidth: "100%",
            overflow: "scroll",
            paddingTop: mainStyle.margin.sides(isNarrow),
            paddingBottom: mainStyle.margin.sides(isNarrow),
            paddingLeft: mainStyle.margin.sides(isNarrow),
            paddingRight: mainStyle.margin.sides(isNarrow),
          }}
        >
          {myText}
        </div>
      </div>
    </div>
  );
};

export default ReportInfo;

const wholeDiv = (isNarrow, noTopMargin, isVeryNarrow, popup, greenStyle) => ({
  display: "flex",
  width: "100%",
  flexDirection: isNarrow ? "column" : "row",
  paddingTop:
    greenStyle || noTopMargin || popup ? 0 : mainStyle.margin.sides(isNarrow),
  paddingBottom: popup || greenStyle ? 0 : mainStyle.margin.sides(isNarrow),
  paddingLeft: popup || greenStyle ? 0 : mainStyle.margin.sides(isNarrow),
  paddingRight: popup || greenStyle ? 0 : mainStyle.margin.sides(isNarrow),
  alignItems: "center",
  justifyContent: "center",
  boxSizing: "border-box",
  maxWidth: popup ? "90vw" : null,
  maxHeight: popup ? "90vh" : null,
  borderTop: greenStyle && "2px solid " + mainStyle.colorGreenBackground,
  minWidth: mainStyle.minWidth,
});

const mainDiv = (
  isNarrow,
  noTopMargin,
  isVeryNarrow,
  popup,
  greenStyle,
  whiteBorder
) => ({
  width: "100%",
  flexDirection: isNarrow ? "column" : "row",
  borderRadius: "10px",
  boxShadow: popup || greenStyle ? null : "0px 0px 30px 10px rgba(0,0,0,.09)",
  backgroundColor: !greenStyle ? mainStyle.colorGreenBackground : null,
  boxSizing: "border-box",
  fontFamily: "Lora",
  maxHeight: "300px",
  // border: "2px solid red",
  overflow: "scroll",
});
