import React, { useEffect } from "react";
import * as mainStyle from "../styles/mainStyle";
import { UserContext } from "../contexts/UserContext";
import { API, graphqlOperation } from "aws-amplify";
import { useContext, useState } from "react";
import { getWaitlistMember, memberByScoreId } from "../graphql/queries";

const ThankYou = (params) => {
  const { windowSize, setThanksPopupOpen } = useContext(UserContext);
  const isNarrow = windowSize.innerWidth < 850;
  const [myInfo, setMyInfo] = useState({
    email: params.thanksPopupOpen.email,
    referralId: params.thanksPopupOpen.referralId,
    score: params.thanksPopupOpen.score,
  });
  const [inputAccount, setInputAccount] = useState(
    params.thanksPopupOpen.acct || params.thanksPopupOpen.scoreId
  );
  const stateEnum = {
    accountCreated: myInfo.email && !inputAccount,
    accountFound: myInfo.email && inputAccount,
    accountFinding: !myInfo.email && inputAccount,
    accountNotFound: !myInfo.email && !inputAccount,
  };

  const scoreText = !myInfo.score ? 0 : myInfo.score;
  const headerText = stateEnum.accountCreated
    ? "Thank you!"
    : stateEnum.accountFound || stateEnum.accountFinding
    ? "Welcome back!"
    : "Account not found";
  const subHeaderText = stateEnum.accountNotFound
    ? "We partnered with High Atlas Foundation to plant a tree for you when you join!**"
    : "We're partnering with High Atlas Foundation to plant a tree for everybody you refer!**";
  const miniBodyText = stateEnum.accountNotFound
    ? "Sign up today!"
    : "Share the link below, move up on the waitlist to gain early access!";

  const myLink = !myInfo.referralId
    ? "withgreen.co"
    : "withgreen.co/?ref=" + myInfo.referralId;
  const myText = "Join Green's waitlist for early access!";
  const hashtags = "green,withgreen,greenfuture";
  const emailLink =
    "mailto:?subject=Check this out&body=" + myText + " " + myLink;
  const twitterLink =
    "http://twitter.com/share?text=" +
    myText +
    "&url=" +
    myLink +
    "&hashtags=" +
    hashtags;
  const linkedinLink =
    "https://www.linkedin.com/sharing/share-offsite/?url=" +
    encodeURIComponent(myLink);

  const fetchInfo = async () => {
    try {
      if (stateEnum.accountFinding) {
        const res = await API.graphql(
          params.thanksPopupOpen.scoreId
            ? graphqlOperation(memberByScoreId, {
                scoreId: params.thanksPopupOpen.scoreId,
              })
            : graphqlOperation(getWaitlistMember, {
                email: params.thanksPopupOpen.acct,
              })
        );
        params.thanksPopupOpen.scoreId
          ? setMyInfo({
              email: res.data.memberByScoreId.items[0].email,
              referralId: res.data.memberByScoreId.items[0].referralId,
              score: res.data.memberByScoreId.items[0].score,
            })
          : setMyInfo({
              email: res.data.getWaitlistMember.email,
              referralId: res.data.getWaitlistMember.referralId,
              score: res.data.getWaitlistMember.score,
            });
      }
    } catch (err) {
      console.log(err);
      setInputAccount(null);
    }
  };

  useEffect(() => {
    fetchInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // REFERRAL LINK SECTION
  const [copiedShowing, setCopiedShowing] = useState(null);
  const handleCopy = () => {
    try {
      navigator.clipboard.writeText(myLink);
      setCopiedShowing("Copied!");
    } catch (err) {
      setCopiedShowing("Could not copy!");
    }
    const timer = setTimeout(() => {
      setCopiedShowing(null);
    }, 2500);
    return () => clearTimeout(timer);
  };
  const referralSection = (
    <div onMouseDown={handleCopy} style={refLinkDiv(isNarrow)}>
      <div style={refLinkTextDiv(isNarrow)}>
        <div
          style={{
            position: "absolute",
            width: "50px",
            height: "100%",
            backgroundImage:
              "linear-gradient(to right, " +
              mainStyle.colorGreenBackground +
              "00 , " +
              mainStyle.colorGreenBackground +
              "ff )",
            right: 0,
          }}
        ></div>
        <div
          style={{
            textAlign: "left",
            overflow: "hidden",
          }}
        >
          {copiedShowing ? copiedShowing : myLink}
        </div>
      </div>
      {copiedShowing ? null : (
        <div style={copyItemDiv(isNarrow)}>
          <img
            alt="Button to copy referral link"
            src="/copying.png"
            style={{ width: "35%", objectFit: "contain" }}
          ></img>
        </div>
      )}
    </div>
  );

  const closeButton = (
    <div
      style={{
        position: "absolute",
        top: isNarrow ? "12px" : "12px",
        right: isNarrow ? "12px" : "12px",
        height: isNarrow ? "13px" : "18px",
        width: isNarrow ? "13px" : "18px",
        backgroundSize: "contain",
        backgroundImage: 'url("/close.png")',
        cursor: "pointer",
      }}
      onClick={() => setThanksPopupOpen(null)}
    />
  );

  const mediaSection = (
    <div style={mediaLinksDiv(isNarrow)}>
      <div
        style={mediaLinkDiv(isNarrow)}
        onClick={() => window.open(emailLink)}
      >
        <img
          alt="Button to share link via email"
          style={mediaLinkImg}
          src="/dark-email.png"
        />
      </div>
      <div
        style={mediaLinkDiv(isNarrow)}
        onClick={() => window.open(twitterLink)}
      >
        <img
          alt="Button to share link via Twitter"
          style={mediaLinkImg}
          src="/dark-twitter.png"
        />
      </div>
      <div
        style={mediaLinkDiv(isNarrow)}
        onClick={() => window.open(linkedinLink)}
      >
        <img
          alt="Button to share link via LinkedIn"
          style={mediaLinkImg}
          src="/dark-linkedin.png"
        />
      </div>
    </div>
  );

  const positionBox = (
    <div
      style={{
        display: "flex",
        minHeight: "100%",
        backgroundColor: mainStyle.colorGreen,
        minWidth: isNarrow ? "30%" : "23%",

        boxSizing: "border-box",
        color: mainStyle.colorGreenBackground,
        borderRadius: "10px",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        flexDirection: "column",
        position: "relative",
        border: "7px solid " + mainStyle.colorGreenBackground,
      }}
    >
      <div
        style={{
          display: "flex",
          fontSize: isNarrow ? 13 : 20,
          backgroundColor: mainStyle.colorGreenBackground,
          color: mainStyle.colorGreen,
          width: "100%",
          paddingTop: isNarrow ? "10px" : "10px",
          paddingBottom: isNarrow ? "10px" : "10px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Referrals
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          fontSize: isNarrow ? 30 : 50,
          fontWeight: "700",
          padding: isNarrow ? "15px" : "20px",
        }}
      >
        {scoreText}
      </div>
    </div>
  );

  const thankYouHeader = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        paddingLeft: "20px",
        paddingTop: "20px",
        paddingBottom: "20px",
        overflow: "hidden",
      }}
    >
      <h2
        style={{
          textAlign: "left",
          marginBottom: "10px",
          overflow: "hidden",
          // whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {headerText}
      </h2>
      <h4 style={{ textAlign: "left", fontSize: isNarrow ? "12px" : "15px" }}>
        {subHeaderText}
      </h4>
    </div>
  );

  return (
    <div style={PopupStyle(isNarrow)}>
      {closeButton}
      <div style={headerDiv(isNarrow)}>
        {positionBox}
        {thankYouHeader}
      </div>
      <div style={miniBodyDiv(isNarrow)}>{miniBodyText}</div>
      {!stateEnum.accountNotFound ? (
        <div style={bodyDiv(isNarrow)}>
          {mediaSection}
          {referralSection}
        </div>
      ) : null}
      {!stateEnum.accountNotFound ? (
        <div style={bottomBodyDiv(isNarrow)}>{myInfo.email}</div>
      ) : null}
    </div>
  );
};

export default ThankYou;

const PopupStyle = (isNarrow) => ({
  display: "flex",
  flexDirection: "column",
  borderRadius: "10px",
  // backgroundColor: mainStyle.colorGreenBackground,
  maxWidth: "90vw",
  maxHeight: "90vh",
  // height: "500px",
  width: isNarrow ? "400px" : "700px",
  fontFamily: "Lora",
  fontSize: "15px",
  fontWeight: "600",
  color: mainStyle.colorGreen,
  boxSizing: "border-box",
  userSelect: "none",
  overflow: "scroll",
  position: "relative",
});

const headerDiv = (isNarrow) => ({
  display: "flex",
  flexDirection: "row",
  // flex: "1",
  width: "100%",
  backgroundColor: mainStyle.colorGreen,
  color: mainStyle.colorGreenBackground,
  padding: isNarrow ? "15px" : "30px",
  boxSizing: "border-box",
});

const miniBodyDiv = (isNarrow) => ({
  display: "flex",
  flex: 1,
  width: "100%",
  backgroundColor: mainStyle.colorGreenBackground,
  padding: "15px",
  boxSizing: "border-box",
  flexDirection: "column",
  borderBottom: "1px solid " + mainStyle.colorGreen,
});

const bottomBodyDiv = (isNarrow) => ({
  display: "flex",
  flex: 1,
  width: "100%",
  backgroundColor: mainStyle.colorGreen,
  padding: "10px",
  boxSizing: "border-box",
  flexDirection: "column",
  fontSize: isNarrow ? "11px" : "13px",
  color: mainStyle.colorGreenBackground,
});

const bodyDiv = (isNarrow) => ({
  display: "flex",
  flex: 1,
  width: "100%",
  backgroundColor: mainStyle.colorGreenBackground,
  paddingLeft: mainStyle.margin.sidesNarrow(isNarrow),
  paddingRight: mainStyle.margin.sidesNarrow(isNarrow),
  paddingBottom: mainStyle.margin.sidesNarrow(isNarrow),
  paddingTop: mainStyle.margin.sidesNarrow(isNarrow),
  boxSizing: "border-box",
  flexDirection: "column",
  gap: "5px",
});

const refLinkDiv = (isNarrow) => ({
  display: "flex",
  border: "1px solid " + mainStyle.colorGreen,
  borderRadius: "10px",
  overflow: "hidden",
  cursor: "pointer",
});

const refLinkTextDiv = (isNarrow) => ({
  display: "flex",
  flex: 1,
  justifyContent: "center",
  alignItems: "center",
  padding: "15px 0px 15px 10px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  position: "relative",
});

const copyItemDiv = (isNarrow) => ({
  display: "flex",
  minWidth: "45px",
  minHeight: "100%",
  backgroundColor: mainStyle.colorGreen,
  justifyContent: "center",
  alignItems: "center",
});

const mediaLinksDiv = (isNarrow) => ({
  display: "flex",
  gap: "5px",
  width: "100%",
});
const mediaLinkDiv = (isNarrow) => ({
  display: "flex",
  border: "1px solid " + mainStyle.colorGreen,
  borderRadius: "10px",
  overflow: "hidden",
  cursor: "pointer",
  flex: 1,
  height: "60px",
  justifyContent: "center",
  alignItems: "center",
});

const mediaLinkImg = {
  objectFit: "contain",
  height: "50%",
};
