import React from "react";
import * as mainStyle from "../styles/mainStyle";
import { UserContext } from "../contexts/UserContext";
import { useContext } from "react";
import { Analytics } from "aws-amplify";

const Disclosure = (params) => {
  const { windowSize } = useContext(UserContext);
  const isNarrow = windowSize.innerWidth < 750;
  const isShort = windowSize.innerHeight < 500;
  Analytics.record({ name: "disclosure-".concat(params.title) });
  return (
    <div
      style={PopupStyle(
        windowSize.innerWidth,
        windowSize.innerHeight,
        isNarrow,
        isShort
      )}
    >
      <div style={HeaderDivStyle(false)}>{params.title}</div>
      <div style={{ minHeight: "20px" }} />
      <div style={ContentDivStyle}>{params.body}</div>
    </div>
  );
};

export default Disclosure;

const standardRadius = "10px";

const PopupStyle = (width, height, isNarrow, isShort) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: mainStyle.colorGreenBackground,
  borderRadius: standardRadius,
  boxSizing: "border-box",
  padding: "20px",
  alignItems: "center",
  width: width - mainStyle.margin.sideVal(isNarrow),
  maxWidth: isNarrow ? "450px" : "700px",
  maxHeight: height - mainStyle.margin.sideVal(false),
  fontFamily: "Lora",
  overflow: "scroll",
});

const HeaderDivStyle = (sub) => ({
  fontWeight: sub ? "500" : "600",
  fontSize: sub ? 15 : 20,
});

const ContentDivStyle = {
  flexDirection: "column",
  display: "flex",
  flex: 1,
  width: "100%",
  overflowX: "hidden",
};
