import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import { UserContext } from "../contexts/UserContext";
import { useContext } from "react";
import * as mainStyle from "../styles/mainStyle";
import * as Content from "../assets/Content";
import { contactButtonVal } from "../components/Contact";
import TextArea from "../components/home/TextArea";
import ContentPiece from "../components/home/ContentPiece";
import { Analytics } from "aws-amplify";

const About = () => {
  const { windowSize } = useContext(UserContext);
  const isNarrow = windowSize.innerWidth < 850;
  Analytics.record({ name: "visit-about" });
  return (
    <div style={MainDivStyle}>
      <NavBar darkTheme />
      <div style={BodyDivStyle}>
        <div style={SectionDiv(isNarrow, true)}>
          <TextArea
            header={Content.aboutPage.A1}
            text={[Content.aboutPage.A2]}
            color="white"
          />
        </div>

        <ContentPiece
          isCentered={true}
          leader={Content.aboutPage.B0}
          header={Content.aboutPage.B1}
          text={[Content.aboutPage.B2]}
          pic="about-dark.png"
          contentType="about"
          noAnimation={true}
          button={contactButtonVal(
            <div style={mainStyle.ActionButtonStyle(true)}>Contact us</div>
          )}
          addedStyle={{
            flex: 1,
            paddingTop: mainStyle.margin.wideSides(isNarrow),
            paddingBottom: mainStyle.margin.wideSides(isNarrow),
          }}
        />
        <Footer />
      </div>
    </div>
  );
};

export default About;

const MainDivStyle = {
  display: "flex",
  flexDirection: "column",
  cursor: "default",
  backgroundColor: mainStyle.colorGreenBackground,
  // userSelect: "none",
  fontFamily: "Lora",
  boxSizing: "border-box",
};

const BodyDivStyle = {
  display: "flex",
  flexDirection: "column",
  width: "100vw",
  boxSizing: "border-box",
  minHeight: "100vh",
  // border: "1px solid red",
};

const SectionDiv = (isNarrow, isHeader = false) => ({
  display: "flex",
  flexDirection: isNarrow ? "column" : "row",
  boxSizing: "border-box",
  justifyContent: "center",
  alignItems: "center",
  marginTop: isHeader ? mainStyle.navBarHeight : 0,
  paddingLeft: mainStyle.margin.sides(isNarrow),
  paddingRight: mainStyle.margin.sides(isNarrow),
  paddingTop: "50px",
  paddingBottom: "50px",
  borderRadius: isNarrow ? "0px 0px 0px 40px" : "0px 0px 0px 60px",
  backgroundColor: isHeader ? mainStyle.colorGreen : "transparent",
  // border: "1px solid red",
});
