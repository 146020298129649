import React from "react";
import * as mainStyle from "../../styles/mainStyle";
import { useContext, useEffect, useState, useRef } from "react";
import { UserContext } from "../../contexts/UserContext";
import { validateEmail } from "../../assets/Common";
import { API } from "aws-amplify";
import { createNewWaitlistMember } from "../../graphql/mutations";

const WaitlistEntry = (params) => {
  const inputRef = useRef(null);
  const { setThanksPopupOpen } = useContext(UserContext);
  const [submitDown, setSubmitDown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [err, setErr] = useState(null);
  const [errState, setErrState] = useState(false);
  const [counter, setCounter] = useState(0);
  const whiteStyle = params.whiteStyle ? params.whiteStyle : false;
  var today = Date.now();
  const numHistory = 10;

  const newWaitlistHistory = localStorage.getItem("newWaitlistHistory")
    ? JSON.parse(localStorage.getItem("newWaitlistHistory"))
    : {
        recents: [],
      };

  const isRecent = () => {
    const newRecents = newWaitlistHistory.recents;
    if (Object.keys(newRecents).length < numHistory) {
      return false;
    }
    const leastRecent = Math.min(...Object.values(newRecents));
    return today - leastRecent < 1000 * 60 * 30;
  };

  const replaceLeastRecent = () => {
    const newRecents = newWaitlistHistory.recents;
    if (Object.keys(newRecents).length < numHistory) {
      newRecents[Object.keys(newRecents).length] = today;
    } else {
      const leastRecent = Math.min(...Object.values(newRecents));
      const leastRecentIndex = Object.values(newRecents).indexOf(leastRecent);
      newRecents[leastRecentIndex] = today;
    }
    localStorage.setItem(
      "newWaitlistHistory",
      JSON.stringify({ ...newWaitlistHistory, recents: newRecents })
    );
  };

  useEffect(() => {
    if (err) {
      setErrState(true);
      setCounter((c) => c + 1);
    } else {
      setErrState(null);
      setCounter(0);
    }
    const timer = setTimeout(() => {
      setErrState(false);
    }, 2500);
    return () => clearTimeout(timer);
  }, [err]);

  const handleSubmit = async () => {
    setErr(null);
    const adjustedEmail = email.trim().toLowerCase();
    if (!isLoading) {
      try {
        setIsLoading(true);
        if (isRecent()) {
          setErr(
            "Please wait a few minutes before submitting again\\" + counter
          );
        } else if (!validateEmail(adjustedEmail) || adjustedEmail === "") {
          setErr("Enter a valid email address\\" + counter);
        } else {
          try {
            const inputVar = !!localStorage.getItem("referrer")
              ? {
                  email: adjustedEmail,
                  referrer: localStorage.getItem("referrer"),
                }
              : {
                  email: adjustedEmail,
                };
            const res = await API.graphql({
              query: createNewWaitlistMember,
              variables: {
                ...inputVar,
              },
              authMode: "API_KEY",
            });
            // console.log(res);
            setErr(null);
            const parsedRes = JSON.parse(res.data.createNewWaitlistMember);
            if (parsedRes.statusCode === 400) {
              setThanksPopupOpen({ acct: adjustedEmail });
              setEmail("");
              inputRef.current.value = "";
              inputRef.current.blur();
              // setErr("Email is already in use");
              throw new Error("Email is already in use");
            }
            setThanksPopupOpen({
              email: adjustedEmail,
              referralId: parsedRes.body.referralId,
              score: 0,
            });
            setEmail("");
            inputRef.current.value = "";
            inputRef.current.blur();
            replaceLeastRecent();
          } catch (e) {
            setErr(e.errors[0].message);
            console.log(e.errors[0].message);
          }
        }
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          flex: 10,
          gap: "5px",
        }}
      >
        <input
          ref={inputRef}
          id={whiteStyle ? "whiteWaitlistInput" : "normalWaitlistInput"}
          style={boxStyle(whiteStyle)}
          name="Email"
          type="email"
          placeholder="Email address"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          onKeyDown={handleKeyDown}
        />
        <div
          style={buttonStyle(whiteStyle, submitDown)}
          onMouseDown={() => setSubmitDown(true)}
          onMouseUp={() => setSubmitDown(false)}
          onMouseLeave={() => setSubmitDown(false)}
          onClick={() => handleSubmit()}
        >
          {!isLoading ? (
            <div>
              Get early
              <br />
              access
            </div>
          ) : (
            <img
              alt="loading"
              src={whiteStyle ? "loading3.gif" : "loading2.gif"}
              style={{ width: "50%", height: "50%", objectFit: "contain" }}
            />
          )}
          <div style={errorDiv(errState)}>
            {err && err.includes("\\")
              ? err.substr(0, err.lastIndexOf("\\"))
              : err}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaitlistEntry;

const boxStyle = (whiteStyle) => ({
  display: "flex",
  height: "100%",
  // flex: 2,/
  width: "66.6%",
  fontSize: "15px",
  border:
    "2px solid " +
    (whiteStyle ? mainStyle.colorGreenBackground : mainStyle.colorGreen),
  color: whiteStyle
    ? mainStyle.colorGreenBackground
    : mainStyle.colorGreenAccent,
  fontWeight: "600",
  borderRadius: mainStyle.smallRadius,
  paddingLeft: "20px",
  paddingRight: "20px",
  backgroundColor: "transparent",
  WebkitAppearance: "none",
  MozAppearance: "none",
  appearance: "none",
  outlineWidth: 0,
  fontFamily: "Lora",
  userSelect: "none",
  boxSizing: "border-box",
  // backgroundColor: "red",
});

const buttonStyle = (whiteStyle, submitDown) => ({
  borderRadius: mainStyle.smallRadius,
  height: "100%",
  display: "flex",
  width: "33.3%",
  // flex: 1,
  backgroundColor: whiteStyle
    ? mainStyle.colorGreenBackground
    : mainStyle.colorGreen,
  cursor: "pointer",
  boxShadow: submitDown ? "inset 0px 0px 10px 0px rgba(0,0,0,0.25)" : null,
  justifyContent: "center",
  alignItems: "center",
  color: whiteStyle ? mainStyle.colorGreen : mainStyle.colorGreenBackground,
  fontWeight: "600",
  userSelect: "none",
  boxSizing: "border-box",
  position: "relative",
});

const errorDiv = (errState) => ({
  // color: "white",
  fontFamily: "Lora",
  fontSize: 12,
  fontWeight: "500",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  textAlign: "center",
  backgroundColor: mainStyle.colorError,
  // border: "1px solid #E76161",
  opacity: errState ? 1 : 0,
  transition: errState || errState === null ? null : "opacity 1.5s",
  padding: "5px",
  borderRadius: mainStyle.smallRadius,
  position: "absolute",
  top: "0",
  height: "100%",
  boxSizing: "border-box",
  color: "white",
  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
  pointerEvents: "none",
});
