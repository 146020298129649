import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import * as mainStyle from "../styles/mainStyle";
import Typography from "@mui/material/Typography";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector, {
  timelineConnectorClasses,
} from "@mui/lab/TimelineConnector";
import { createTheme } from "@mui/material/styles";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot, { timelineDotClasses } from "@mui/lab/TimelineDot";
import * as Content from "../assets/Content";

const TimelineComponent = () => {
  return (
    <React.Fragment>
      <Timeline
        // style={{ border: "1px solid red" }}
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
          [`& .${timelineDotClasses.root}`]: {
            backgroundColor: mainStyle.colorGreen,
          },
          [`& .${timelineConnectorClasses.root}`]: {
            backgroundColor: mainStyle.colorGreen,
          },
        }}
      >
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography theme={theme} variant="h6" component="span">
              Origination
            </Typography>
            <Typography variant="body1" theme={theme}>
              {Content.learnPage.D1["Origination"]}
            </Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography theme={theme} variant="h6" component="span">
              Due Diligence
            </Typography>
            <Typography variant="body1" theme={theme}>
              {Content.learnPage.D1["Due Diligence"]}
            </Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography theme={theme} variant="h6" component="span">
              Investment
            </Typography>
            <Typography variant="body1" theme={theme}>
              {Content.learnPage.D1["Investment"]}
            </Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography theme={theme} variant="h6" component="span">
              Performance
            </Typography>
            <Typography variant="body1" theme={theme}>
              {Content.learnPage.D1["Performance"]}
            </Typography>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </React.Fragment>
  );
};

export default TimelineComponent;
const theme = createTheme({
  typography: {
    fontFamily: "Lora",
    fontSize: 12,
    color: mainStyle.colorGreenAccent,
    h6: {
      color: mainStyle.colorGreenAccent,
    },
    body1: {
      color: mainStyle.colorGreenAccent,
    },
  },
});
