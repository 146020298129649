import React, { useEffect } from "react";
import * as mainStyle from "../styles/mainStyle";
import { Analytics } from "aws-amplify";

const ComingSoon = () => {
  Analytics.record({ name: "visit-comingsoon" });
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return function cleanup() {
      document.body.style.overflow = "";
    };
  }, []);

  return <div style={PopupStyle}></div>;
};

export default ComingSoon;

const PopupStyle = {
  display: "flex",
  flexDirection: "row",
  backgroundColor: mainStyle.colorGreenBackground,
  borderRadius: "15px",
  boxSizing: "border-box",
  padding: "20px",
  alignItems: "center",
  fontFamily: "Lora",
  justifyContent: "center",
  width: "75vw",
  height: "300px",
  maxWidth: "600px",
  border: "3px solid " + mainStyle.colorGreen,
  backgroundImage: 'url("soon-dark.png")',
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
};
