import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./views/Home";
import Error from "./views/Error";
import About from "./views/About";
import Invest from "./views/Invest";
import Learn from "./views/Learn";
import Calculate from "./views/Calculate";
import awsconfig from "./aws-exports";
import { Amplify, Auth, Storage } from "aws-amplify";
import { useState, useEffect } from "react";
import { UserContext } from "./contexts/UserContext";
import Raise from "./views/Raise";
import ScrollToTop from "./components/ScrollToTop";
import MyPopup from "./components/home/MyPopup";
import ThankYou from "./components/ThankYou";
import Together from "./components/home/Together";
import ReportInfo from "./components/home/ReportInfo";
import ExtraInfo from "./components/home/ExtraInfo";

Amplify.configure(awsconfig);
Auth.configure(awsconfig);
Storage.configure(awsconfig);

function App() {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const [thanksPopupOpen, setThanksPopupOpen] = useState(null);
  const thanksPopup = thanksPopupOpen ? (
    <MyPopup
      open={thanksPopupOpen}
      close={() => setThanksPopupOpen(null)}
      content={
        thanksPopupOpen.type === "together" ? (
          <Together popup thanksPopupOpen={thanksPopupOpen} />
        ) : thanksPopupOpen.type === "reportcard-info" ? (
          <ReportInfo popup thanksPopupOpen={thanksPopupOpen} />
        ) : thanksPopupOpen.type === "extra-info" ? (
          <ExtraInfo popup thanksPopupOpen={thanksPopupOpen} />
        ) : (
          <ThankYou thanksPopupOpen={thanksPopupOpen} />
        )
      }
    />
  ) : null;

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  return (
    <UserContext.Provider
      value={{
        windowSize,
        setThanksPopupOpen,
        thanksPopupOpen,
      }}
    >
      <div className="App">
        {thanksPopup}
        <Router>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/invest" element={<Invest />} />
            <Route path="/learn" element={<Learn />} />
            <Route path="/raise" element={<Raise />} />
            <Route path="/calculate" element={<Calculate />} />
            <Route path="/calculator" element={<Calculate />} />
            <Route path="/disclaimer" element={<Home disclose />} />
            <Route path="/score" element={<Home />}>
              <Route
                path="*"
                element={
                  <Home scoreId={/[^/]*$/.exec(window.location.pathname)[0]} />
                }
              />
            </Route>
            <Route path="*" element={<Error />} />
          </Routes>
        </Router>
      </div>
    </UserContext.Provider>
  );
}

export default App;
