import * as mainStyle from "./mainStyle";

export const steadyWidth = "400px";
export const translate = (isLeft) => (isLeft ? "left" : "right");
export const translateAdv = (isLeft) => (isLeft ? "flex-start" : "flex-end");
export const reverse = (isLeft) => (isLeft ? "right" : "left");
export const reverseAdv = (isLeft) => (isLeft ? "flex-end" : "flex-start");
export const spacer = (isNarrow) => (
  <div
    style={{
      width: mainStyle.margin.sides(isNarrow),
      height: mainStyle.margin.sides(isNarrow),
    }}
  />
);
