import { Link } from "react-router-dom";
import * as mainStyle from "../styles/mainStyle";
import { Analytics } from "aws-amplify";

const Error = () => {
  Analytics.record({ name: "visit-error" });
  return (
    <div style={MainDivStyle}>
      <Link to="/" style={HeaderDivStyle}>
        <img src="/logo512.png" style={ImageStyle} alt="logo512" />
      </Link>
      <div style={{ height: "30px" }} />
      <div style={TaglineDivStyle}>Uh oh! You seem lost...</div>
      <div style={{ height: "30px" }} />
      <Link to="/" style={linkStyle}>
        <div style={SignUpBoxStyle}>Return to home</div>
      </Link>
    </div>
  );
};

export default Error;

const MainDivStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
  position: "absolute",
  flexDirection: "column",
  backgroundColor: mainStyle.colorGreenBackground,
};

const HeaderDivStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "80px",
  height: "80px",
};

const ImageStyle = {
  height: "100%",
  width: "100%",
  objectFit: "contain",
};

const TaglineDivStyle = {
  width: "350px",
  fontWeight: "700",
  fontFamily: "Lora",
  fontSize: 25,
};

const SignUpBoxStyle = {
  display: "flex",
  height: "65px",
  width: "350px",
  fontWeight: "500",
  fontFamily: "Lora",
  fontSize: 15,
  border: "1px solid black",
  borderRadius: "10px",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
};

const linkStyle = {
  textDecoration: "none",
  color: "black",
};
