import { Link } from "react-router-dom";
import * as mainStyle from "../styles/mainStyle";
import { UserContext } from "../contexts/UserContext";
import { useContext } from "react";
import Popup from "reactjs-popup";
import Disclosure from "./Disclosure";
import ComingSoon from "./ComingSoon";
import { contactButtonVal } from "./Contact";
import Footnote from "./home/Footnote";
import * as Content from "../assets/Content";
import * as TOSContent from "../assets/TOSContent";
import * as PrivacyContent from "../assets/PrivacyContent";
import { Analytics } from "aws-amplify";

const Footer = (params) => {
  const { windowSize } = useContext(UserContext);
  const isNarrow = windowSize.innerWidth < 800;
  const footnotes = params.footnotes || [];

  const linkShow = (path, content) => (
    <Link style={dropdownItem()} to={path}>
      {content}
    </Link>
  );

  const popupShow = (title, content) => (
    <Popup modal nested trigger={<div style={dropdownItem()}>{title}</div>}>
      {content}
    </Popup>
  );

  // const comingSoonShow = (content) => (
  //   <Popup modal nested trigger={content}>
  //     <ComingSoon />
  //   </Popup>
  // );

  const selectSocial = (name, url) => {
    Analytics.record({ name: "select-social-".concat(name) });
    window.open(url);
  };

  return (
    <div style={footerDiv(params.altGreen)}>
      <div style={layerOne}>
        <div style={dropdownListDiv(isNarrow)}>
          <div style={dropdownContainerDiv(isNarrow)}>
            <div style={dropdownDiv}>
              <div style={dropdownItem(true)}>Investments</div>
              {linkShow("/invest", "How it Works")}
              {popupShow("Account", <ComingSoon />)}
            </div>
            <div style={dropdownDiv}>
              <div style={dropdownItem(true)}>Originators</div>
              {linkShow("/raise", "Raise Capital")}
              {contactButtonVal(<div style={dropdownItem()}>Contact</div>)}
            </div>
          </div>
          {isNarrow ? <div style={{ height: "20px" }} /> : null}
          <div style={dropdownContainerDiv(isNarrow)}>
            <div style={dropdownDiv}>
              <div style={dropdownItem(true)}>Legal</div>
              {/* {popupShow("Privacy", <ComingSoon />)}
              {popupShow("Terms of Use", <ComingSoon />)} */}
              {popupShow(
                "Privacy",
                <Disclosure
                  title={PrivacyContent.title}
                  body={PrivacyContent.body}
                />
              )}
              {popupShow(
                "Terms of Use",
                <Disclosure title={TOSContent.title} body={TOSContent.body} />
              )}
            </div>
            <div style={dropdownDiv}>
              <div style={dropdownItem(true)}>Company</div>
              {linkShow("/about", "About")}
              {contactButtonVal(<div style={dropdownItem()}>Contact</div>)}
            </div>
          </div>
        </div>
        <div style={{ flex: 0.5 }} />
        <div style={socialContainerDiv(isNarrow)}>
          <div style={socialDiv(isNarrow)}>
            <img
              onClick={() =>
                window.open("https://www.linkedin.com/company/withgreen")
              }
              src="/linkedin.png"
              style={imgstyle}
              alt="linkedin"
            />
          </div>
          <div style={socialDiv(isNarrow)}>
            <img
              onClick={() =>
                selectSocial("twitter", "https://twitter.com/withgreenco")
              }
              src="/twitter.png"
              style={imgstyle}
              alt="lintwitterkedin"
            />
          </div>
          <div style={socialDiv(isNarrow)}>
            <img
              onClick={() =>
                selectSocial(
                  "instagram",
                  "https://www.instagram.com/withgreen.co/"
                )
              }
              src="/insta.png"
              style={imgstyle}
              alt="insta"
            />
            {/* {comingSoonShow(
              <img
                // onClick={() => window.open("https://facebook.com")}
                src="/facebook.png"
                style={imgstyle}
                alt="facebook"
              />
            )} */}
          </div>
          <div style={socialDiv(isNarrow)}>
            <img
              onClick={() =>
                selectSocial("medium", "https://medium.com/withgreen")
              }
              src="/medium.png"
              style={imgstyle}
              alt="medium"
            />
          </div>
        </div>
      </div>
      <div style={{ minHeight: "15px" }} />
      <div style={layerOne}>
        <img
          alt="america"
          src="/america.jpg"
          style={{ width: "20px", objectFit: "contain", marginRight: "5px" }}
        />
        <div style={copyText}>Born in New York</div>
      </div>
      <div
        style={{
          minHeight: "25px",
          borderBottom: "1px solid white",
          boxSizing: "border-box",
        }}
        id="FooterID"
      />
      <div style={{ minHeight: "20px" }} />
      <Footnote index={"*"} text={Content.simpleTerms} />
      <Footnote index={"**"} text={Content.HAFTerms} />
      {footnotes.map((note, index) => {
        return (
          <Footnote key={"footnote" + index} index={index + 1} text={note} />
        );
      })}
      {/* <div
        style={{
          minHeight: "25px",
          borderBottom: "1px solid white",
          boxSizing: "border-box",
        }}
      />
      <div style={{ minHeight: "25px" }} />
      <div style={layerOne}>
        <div style={copyText2}>Copyright © 2022 Green Everything, Inc.</div>
      </div> */}
    </div>
  );
};

export default Footer;

const footerDiv = (altGreen) => ({
  display: "flex",
  width: "100%",
  flexDirection: "column",
  backgroundColor: altGreen ? altGreen : mainStyle.colorGreen,
  padding: "30px",
  boxSizing: "border-box",
  minWidth: mainStyle.minWidth,
  borderTop: "2px solid " + mainStyle.colorGreenBackground,
});

const layerOne = {
  display: "flex",
  width: "100%",
  // height: "100%",
  flexDirection: "row",
};

const dropdownListDiv = (isNarrow) => ({
  display: "flex",
  flex: 7,
  height: "100%",
  flexDirection: isNarrow ? "column" : "row",
  // border: "1px solid red",
});

const dropdownContainerDiv = (isNarrow) => ({
  display: "flex",
  height: "100%",
  flexDirection: "row",
  flex: 1,
});

const dropdownDiv = {
  display: "flex",
  height: "100%",
  flexDirection: "column",
  flex: 1,
  color: "white",
};

const dropdownItem = (header = false) => ({
  display: "flex",
  flexDirection: "row",
  color: "white",
  fontWeight: header ? "700" : 500,
  marginBottom: header ? "10px" : "5px",
  fontSize: 15,
  textAlign: "left",
  cursor: header ? "default" : "pointer",
  textDecoration: "none",
});

const socialContainerDiv = (isNarrow) => ({
  display: "flex",
  height: "100%",
  flexDirection: isNarrow ? "column" : "row",
  justifyContent: isNarrow ? "flex-start" : "flex-end",
});

const socialDiv = (isNarrow) => ({
  display: "flex",
  marginBottom: isNarrow ? "20px" : "0px",
  justifyContent: "flex-end",
});

const imgstyle = {
  width: "20px",
  height: "20px",
  objectFit: "contain",
  marginLeft: "20px",
  cursor: "pointer",
};

const copyText = {
  color: "white",
  fontFamily: "Lora",
  fontSize: 14,
  fontWeight: "700",
};
