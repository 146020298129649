import React, { useEffect } from "react";
import * as mainStyle from "../styles/mainStyle";
import { UserContext } from "../contexts/UserContext";
import { useContext, useState } from "react";
import Popup from "reactjs-popup";
import { API } from "aws-amplify";
import { createContactMember } from "../graphql/mutations";
import { Analytics } from "aws-amplify";

const standardRadius = "10px";

export const contactButtonVal = (child) => (
  <Popup modal nested trigger={child}>
    {(close) => <Contact close={close} />}
  </Popup>
);

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const Contact = (params) => {
  const { windowSize } = useContext(UserContext);
  const isNarrow = windowSize.innerWidth < 750;
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [inquiryTopic, setInquiryTopic] = useState("");
  const [inquiryDetail, setInquiryDetail] = useState("");
  const [fullName, setFullName] = useState("");
  const [entity, setEntity] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);
  Analytics.record({ name: "visit-contact" });
  var today = Date.now();
  const contactHistory =
    localStorage.getItem("contactHistory") &&
    parseInt(localStorage.getItem("contactHistory")) + 1000 * 60 * 60 * 12 >
      today;

  // const contactHistory =
  // (localStorage.getItem("contactHistory") || "false") === "true";
  // localStorage.setItem("contactHistory", "false");

  // useEffect(() => {
  //   console.log(inquiryDetail);
  // }, [inquiryDetail]);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return function cleanup() {
      document.body.style.overflow = "";
    };
  }, []);

  const requiredContent = (
    <div
      style={{
        position: "absolute",
        color: "red",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "25px",
        paddingTop: "5px",
      }}
    >
      *
    </div>
  );

  const handleSubmit = async () => {
    if (!isLoading) {
      try {
        setIsLoading(true);
        if (entity.length <= 0) setErr('Select an option under "I am a..."');
        else if (fullName.trim().length <= 1) setErr("Enter a valid full name");
        else if (inquiryTopic.trim().length <= 1)
          setErr("Enter a valid inquiry topic");
        else if (inquiryDetail.trim().length <= 1)
          setErr("Enter a valid inquiry detail");
        else if (!validateEmail(email.trim()))
          setErr("Enter a valid email address");
        else {
          try {
            const res = await API.graphql({
              query: createContactMember,
              variables: {
                input: {
                  entity: entity,
                  companyName: companyName.trim(),
                  fullName: fullName.trim(),
                  email: email.trim(),
                  inquiryTopic: inquiryTopic.trim(),
                  inquiryDetail: inquiryDetail.trim(),
                },
              },
              authMode: "API_KEY",
            });
            console.log(res);
            setErr(null);
            Analytics.record({ name: "submit-contact" });
            localStorage.setItem("contactHistory", today);
          } catch (e) {
            try {
              setErr(e.errors[0].message);
              console.log(e.errors[0].message);
            } catch (e2) {
              setErr(e.message);
              console.log(e.message);
            }
          }
        }
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    }
  };

  const [submitDown, setSubmitDown] = useState(false);

  const submitButton = (text) => (
    <div>
      <div style={errorDiv(err ? true : false)}>{err ?? err}</div>
      <div
        style={SignUpBoxBoxStyle(1)}
        onMouseDown={() => setSubmitDown(true)}
        onMouseUp={() => setSubmitDown(false)}
        onMouseLeave={() => setSubmitDown(false)}
      >
        <div
          style={SignUpBoxStyle(true, null, submitDown)}
          onClick={handleSubmit}
        >
          {!isLoading ? (
            text
          ) : (
            <img
              alt="loading"
              src="/loading2.gif"
              style={{ width: "50%", height: "50%", objectFit: "contain" }}
            />
          )}
        </div>
      </div>
    </div>
  );

  const spacer = isNarrow ? (
    <div style={{ minWidth: "5px", minHeight: "5px" }} />
  ) : (
    <div style={{ minWidth: "10px", minHeight: "10px" }} />
  );

  const entityOptions = [
    { label: "Current user", value: "curUser", id: "entity0" },
    { label: "Prospective user", value: "prospUser", id: "entity1" },
    { label: "Current investment partner", value: "curInv", id: "entity2" },
    {
      label: "Prospective investment partner",
      value: "prospInv",
      id: "entity3",
    },
  ];

  const Dropdown = ({ label, value, options, onChange, addFlex, required }) => {
    return (
      <div style={SignUpBoxBoxStyle(addFlex)}>
        {required ? requiredContent : null}
        <select
          style={SignUpBoxStyle(false, value === "")}
          value={value}
          onChange={onChange}
        >
          <option disabled={true} value="">
            {label}...
          </option>
          {options.map((option) => (
            <option value={option.value} key={option.id}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const contactPopupContent = (
    <div style={ContentDivStyle}>
      <div style={QuestionSection}>
        <Dropdown
          label="I am a"
          options={entityOptions}
          value={entity}
          onChange={(e) => {
            setEntity(e.target.value);
          }}
          required
        />
      </div>
      {spacer}
      <div style={QuestionSection}>
        <div style={SignUpBoxBoxStyle()}>
          <input
            style={SignUpBoxStyle(false, companyName === "")}
            name="companyName"
            type="text"
            placeholder="Company Name (Optional)"
            onChange={(e) => {
              setCompanyName(e.target.value);
            }}
          />
        </div>
        {spacer}
        <div style={SignUpBoxBoxStyle()}>
          {requiredContent}
          <input
            style={SignUpBoxStyle(false, fullName === "")}
            name="fullName"
            type="text"
            placeholder="Full Name"
            onChange={(e) => {
              setFullName(e.target.value);
            }}
          />
        </div>
      </div>
      {spacer}
      <div style={QuestionSection}>
        <div style={SignUpBoxBoxStyle()}>
          {requiredContent}
          <input
            style={SignUpBoxStyle(false, email === "")}
            name="Email"
            type="text"
            placeholder="Email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        {spacer}
        <div style={SignUpBoxBoxStyle()}>
          {requiredContent}
          <input
            style={SignUpBoxStyle(false, inquiryTopic === "")}
            name="inquiryTopic"
            type="text"
            placeholder="Inquiry Topic"
            onChange={(e) => {
              setInquiryTopic(e.target.value);
            }}
          />
        </div>
      </div>

      {spacer}
      <div style={{ ...QuestionSection, height: "100px" }}>
        <div
          style={{
            ...SignUpBoxBoxStyle(),
            maxHeight: "100px",
            height: "100px",
          }}
        >
          {requiredContent}
          <textarea
            style={{
              ...SignUpBoxStyle(false, inquiryDetail === ""),

              resize: "none",
              paddingTop: "20px",
            }}
            name="inquiryDetail"
            type="text"
            placeholder="Inquiry Detail"
            onChange={(e) => {
              setInquiryDetail(e.target.value);
            }}
          />
        </div>
      </div>
      {submitButton("Submit")}
    </div>
  );

  return (
    <div
      style={PopupStyle(
        windowSize.innerWidth,
        windowSize.innerHeight,
        isNarrow
      )}
    >
      <div
        style={{
          position: "absolute",
          top: isNarrow ? "8px" : "10px",
          left: isNarrow ? "8px" : "10px",
          height: isNarrow ? "13px" : "18px",
          width: isNarrow ? "13px" : "18px",
          backgroundSize: "contain",
          backgroundImage: 'url("/close.png")',
          cursor: "pointer",
        }}
        onClick={params.close}
      />
      <div
        style={{
          overflow: "scroll",
          width: "100%",
          height: "100%",
          borderRadius: standardRadius,
          overflowX: "hidden",
        }}
      >
        <div style={MainHeaderStyle(isNarrow)}>
          <div style={HeaderDivStyle(false, isNarrow)}>Contact us</div>
          <div style={{ height: "5px" }} />
          <div style={HeaderDivStyle(true, isNarrow)}>
            {!contactHistory
              ? "Select one of the options below to contact someone from our team. We try our best to respond as quickly as possible."
              : "We'll get back to you shortly!"}
          </div>
        </div>
        <div style={MainHeaderStyle(isNarrow, false)}>
          {!contactHistory ? contactPopupContent : null}
        </div>
      </div>
      {!contactHistory ? (
        <div
          style={{
            backgroundColor: mainStyle.colorGreen,
            width: "100%",
            height: "40px",
            color: "white",
            display: "flex",
            fontWeight: "600",
            fontSize: 10,
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.25)",
            paddingLeft: "10px",
            paddingRight: "10px",
            boxSizing: "border-box",
          }}
        >
          By submitting this form you understand that you may be contacted.
        </div>
      ) : null}
    </div>
  );
};

export default Contact;

const PopupStyle = (width, height, isNarrow, isShort) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: mainStyle.colorGreenBackground,
  borderRadius: standardRadius,
  boxSizing: "border-box",
  alignItems: "center",
  width: width - mainStyle.margin.sideVal(isNarrow),
  maxWidth: isNarrow ? "450px" : "700px",
  maxHeight: height - mainStyle.margin.sideVal(false),
  fontFamily: "Lora",
  overflow: "scroll",
  position: "relative",
});

const MainHeaderStyle = (isNarrow, dark = true) => ({
  width: "100%",
  padding: mainStyle.margin.sidesNarrow(isNarrow),
  backgroundColor: dark ? mainStyle.colorGreen : "transparent",
  boxSizing: "border-box",
});

const HeaderDivStyle = (sub, isNarrow) => ({
  fontFamily: "Lora",
  fontWeight: sub ? "600" : "700",
  fontSize: sub ? 14 : isNarrow ? 24 : 32,
  width: "100%",
  color: "white",
});

const ContentDivStyle = {
  flexDirection: "column",
  display: "flex",
  flex: 1,
  width: "100%",
  maxWidth: "100%",
  // overflowX: "hidden",
};

const QuestionSection = {
  display: "flex",
  minHeight: "65px",
  height: "100%",
  width: "100%",
  flexDirection: "row",
  backgroundColor: "transparent",
  boxSizing: "border-box",
};

const SignUpBoxBoxStyle = (addFlex = 1) => ({
  userSelect: "none",
  display: "flex",
  flex: addFlex,
  height: "65px",
  minHeight: "65px",
  width: "100%",
  position: "relative",
});

const SignUpBoxStyle = (filled, dimmed = false, submitDown = false) => ({
  ...{
    boxShadow: submitDown ? "inset 0px 0px 10px 0px rgba(0,0,0,0.25)" : null,
    display: "flex",
    height: "100%",
    width: "100%",
    fontFamily: "Lora",
    fontSize: 15,
    border: "2px solid " + mainStyle.colorGreen,
    borderRadius: standardRadius,
    paddingLeft: "20px",
    paddingRight: "20px",
    backgroundColor: filled ? mainStyle.colorGreen : "transparent",
    boxSizing: "border-box",
    outline: "none",
    color: filled
      ? "white"
      : dimmed
      ? mainStyle.colorGreenAccent
      : mainStyle.colorGreen,
    fontWeight: dimmed ? "500" : "600",
    WebkitAppearance: "none",
    MozAppearance: "none",
    appearance: "none",
  },
  ...(filled
    ? {
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        fontSize: 17,
        fontWeight: "700",
      }
    : {}),
});

const errorDiv = (isBig) => ({
  height: isBig ? "40px" : "20px",
  color: "#FF0909",
  fontFamily: "Lora",
  fontSize: 10,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  textAlign: "center",
});
