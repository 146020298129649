import React from "react";
import * as mainStyle from "../../styles/mainStyle";
import { UserContext } from "../../contexts/UserContext";
import { useContext } from "react";
import { steadyWidth, translate, translateAdv } from "../../styles/common";

const TextArea = (params) => {
  const { windowSize } = useContext(UserContext);
  const isNarrow = windowSize.innerWidth < 850;
  const isLeft = params.isLeft;
  const textLeft = true; //isLeft;
  const bullets = params.bullets || [];
  const button = params.button;
  const margins = !params.noMargins;
  const color = params.color || mainStyle.colorGreenAccent;
  const newWidth = params.width;
  const addMargin = params.addMargin || false;

  const bulletPoint = (text, isLeft) => (
    <div key={Math.random()} style={BulletStyle(isLeft)}>
      {!isLeft ? text : null}
      <img
        alt="bullet"
        src="/bullet-dark.png"
        style={{
          ...{ height: "18px" },
          ...(!isLeft ? { marginLeft: "10px" } : { marginRight: "10px" }),
        }}
      />
      {isLeft ? text : null}
    </div>
  );

  return (
    <div style={textSectionDiv(isNarrow, isLeft, margins, newWidth, addMargin)}>
      <div style={contentLeaderDiv(textLeft, color)}>{params.leader}</div>
      <div style={contentHeaderDiv(textLeft, color)}>{params.header}</div>
      <div style={contentTextDiv}>
        {params.text.map((element) => (
          <div key={Math.random()} style={contentTextSubDiv(textLeft, color)}>
            {element}
          </div>
        ))}
        {bullets.map((element) => bulletPoint(element, textLeft))}
        {button ? <div style={{ height: "15px" }} /> : null}
        {button ? button : null}
      </div>
    </div>
  );
};

export default TextArea;

const textSectionDiv = (isNarrow, isLeft, margins, newWidth, addMargin) => ({
  display: "flex",
  maxWidth: newWidth ? newWidth : steadyWidth,
  height: "100%",
  flexDirection: "column",
  justifyContent: "center",
  marginLeft:
    addMargin || (margins && (isNarrow || !isLeft))
      ? mainStyle.margin.sides(isNarrow)
      : 0,
  marginRight:
    addMargin || (margins && (isNarrow || isLeft))
      ? mainStyle.margin.sides(isNarrow)
      : 0,
});

const contentLeaderDiv = (isLeft = true, color) => ({
  display: "flex",
  fontSize: 15,
  fontWeight: "700",
  fontFamily: "Lora",
  color: color,
  width: "100%",
  justifyContent: translateAdv(isLeft),
  textAlign: translate(isLeft),
});

const contentHeaderDiv = (isLeft = true, color) => ({
  display: "flex",
  fontSize: 36,
  fontWeight: "700",
  fontFamily: "Lora",
  color: color,
  width: "100%",
  justifyContent: translateAdv(isLeft),
  textAlign: translate(isLeft),
});

const contentTextDiv = {
  display: "flex",
  flexDirection: "column",
  minWidth: "100%",
  // border: "1px solid blue",
};

const contentTextSubDiv = (isLeft = true, color) => ({
  display: "flex",
  fontSize: 15,
  fontWeight: "400",
  fontFamily: "Lora",
  color: color,
  marginTop: "10px",
  width: "100%",
  justifyContent: translateAdv(isLeft),
  textAlign: translate(isLeft),
});

const BulletStyle = (isLeft) => ({
  display: "flex",
  flexDirection: "row",
  color: mainStyle.colorGreenAccent,
  alignItems: "center",
  marginTop: "10px",
  fontWeight: "500",
  textAlign: "left",
  justifyContent: translate(isLeft),
});
