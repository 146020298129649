import { UserContext } from "../contexts/UserContext";
import { useContext, useRef, useState, useEffect } from "react";
import * as mainStyle from "../styles/mainStyle";
// import downloadjs from "downloadjs";
import html2canvas from "html2canvas";
import { Analytics } from "aws-amplify";

const spacerHeight = "5px";
const emissionsReductionPerDollar = 1_300_000 / 437_800_000;
// const humanAverage = 13.2;

const getScoreFromEmissions = (emissions) => {
  // return params.emNorm
  const calc = 100 * Math.exp(-0.053 * emissions);
  const scoreVal = calc.toFixed(0);
  return Math.max(Math.min(scoreVal, 100), 0);
};

const getEmissionsFromScore = (score) => {
  const calc = Math.log(score / 100.0) / -0.053;
  const emissionsVal = calc.toFixed(2);
  return emissionsVal;
};

const ReportCard = (params) => {
  Analytics.record({ name: "visit-submitted-calculate" });
  const emissions = params.emissionsInfo["total"].toFixed(2);
  const score = getScoreFromEmissions(emissions);
  const newScore = (score + (100 - score) * 0.2).toFixed(0);
  const newEmissions = getEmissionsFromScore(newScore);
  const investment = (
    (emissions - newEmissions) /
    emissionsReductionPerDollar
  ).toFixed(0);

  const gasEquivalent = Math.floor(emissions * 113.8).toLocaleString("en-US");
  const coalEquivalent = Math.floor(emissions * 1141.968).toLocaleString(
    "en-US"
  );
  // const averageMultiplier = (emissions / humanAverage).toFixed(2);
  // const investment = Math.ceil((1000 * emissions) / 23).toLocaleString("en-US");
  // const newScore = Math.floor((100 - score) * 0.9);

  const styleOptions = {
    color: {
      0: {
        colorAccent: "white",
        color00: "#2BA081",
        color01: "#2D977B",
        color1: "#2AAB89",
        color2: "#2A836C",
        color30: "#25604F",
        color31: "#2AAB89",
        dollarPng: "dollar.png",
        lightningPng: "lightning.png",
        calcHeaderPng: "calcheader.png",
      },
      1: {
        colorAccent: "black",
        color00: "#27B892",
        color01: "#28BA93",
        color1: "#23DDAC",
        color2: "#279B7D",
        color30: "#398B74",
        color31: "#23DDAC",
        dollarPng: "dollar-black.png",
        lightningPng: "lightning-black.png",
        calcHeaderPng: "calcheader-black.png",
      },
    },
    text: {
      0: [
        <div>
          That’s like burning{" "}
          <u>
            {coalEquivalent} <br />
            pounds
          </u>{" "}
          of coal every year.
        </div>,
        coalEquivalent,
        "coals",
      ],
      1: [
        <div>
          That’s like burning <u>{gasEquivalent} gallons</u>
          <br /> of gasoline every year
        </div>,
        gasEquivalent,
        "drops",
      ],
    },
    font: ["Lora", "Impact"],
  };

  const { windowSize, setThanksPopupOpen } = useContext(UserContext);
  const isNarrow = windowSize.innerWidth < 650 || windowSize.innerHeight < 650;
  const isShort = windowSize.innerHeight < 650;
  const isVeryNarrow = windowSize.innerWidth < 450;
  const styleSetting = Object.fromEntries(
    Object.keys(styleOptions).map((type) => [type, 0])
  );
  const [isLoadingShare, setIsLoadingShare] = useState(false);
  const [submitDown, setSubmitDown] = useState(null);
  const [popupForced, setPopupForced] = useState(
    JSON.parse(localStorage.getItem("popupForced")) ? true : false
  );
  const myTimer = useRef(null);
  const myGap = isNarrow ? "5px" : "7px";
  const twitterLink =
    "http://twitter.com/share?text=" +
    "Just calculated my carbon footprint. I am responsible for " +
    emissions +
    " Metric Tons of CO2 emissions per year! Crazy." +
    "&url=withgreen.co/calculate";

  const scrollUp = () => {
    document
      .getElementById("make-a-change")
      .scrollIntoView({ behavior: "smooth" });
  };

  const setMyTimer = () => {
    myTimer.current = setTimeout(() => {
      if (!popupForced) {
        localStorage.setItem("popupForced", "true");
        setThanksPopupOpen({
          type: "together",
          disableClickOutsideToClose: true,
        });
        setPopupForced(true);
      }
    }, 7500);
  };

  useEffect(() => {
    setMyTimer();
    return () => {
      if (myTimer.current) clearTimeout(myTimer.current);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShare = async () => {
    if (isLoadingShare) return;
    if (myTimer.current) clearTimeout(myTimer.current);
    Analytics.record({ name: "share-calculate" });
    setIsLoadingShare(true);
    try {
      const swiperChild = document.getElementById("reportCard").children[0];
      const canvas = await html2canvas(swiperChild, {
        scale: 5,
        backgroundColor: null,
        allowTaint: true,
      });
      canvas.toBlob(async (newFile) => {
        const data = {
          files: [
            new File([newFile], "GreenReportCard.png", {
              type: "image/png",
            }),
          ],
          // title: "Green Report Card",
          text: "Check out withgreen.co/calculate to calculate your carbon footprint!",
        };

        try {
          await navigator.share(data);
          setThanksPopupOpen({
            type: "extra-info",
            emissionsInfo: params.emissionsInfo,
            noKeyboard: true,
          });
        } catch (err) {
          // console.error(err);
          if (!navigator.canShare || !navigator.canShare(data)) {
            window.open(twitterLink);
            setThanksPopupOpen({
              type: "extra-info",
              emissionsInfo: params.emissionsInfo,
              noKeyboard: true,
            });
            // const dataURL = canvas.toDataURL("image/png");
            // downloadjs(dataURL, "GreenReportCard", "image/png");
          }
        }
      });
    } catch (err) {
      console.log(err);
    }
    setIsLoadingShare(false);
  };

  const resultRef = useRef(null);
  const [divWidth, setDivWidth] = useState(0);
  useEffect(() => {
    function handleWindowResize() {
      setDivWidth(resultRef.current.offsetWidth);
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  useEffect(() => {
    setDivWidth(resultRef.current.offsetWidth);
  }, []);

  const qualityCheck = (score) => {
    if (score >= 0 && score < 20) {
      return ["Major Emitter", "#ff6348"];
    } else if (score >= 20 && score < 40) {
      return ["Fossil Fueler", "#f0932b"];
    } else if (score >= 40 && score < 60) {
      return ["Average Joe", "#EAB543"];
    } else if (score >= 60 && score < 80) {
      return ["Mostly Green", "#9ACA13"];
    } else if (score >= 80 && score <= 100) {
      return ["Environmental!", "#0BBF56"];
    }
  };

  const row1 = (styleSetting) => (
    <div style={rowStyle(styleOptions.font[styleSetting.font])}>
      <div
        style={{
          display: "flex",
          flex: "1",
          backgroundColor: styleOptions.color[styleSetting.color].color00,
          maxWidth: "33.3%",
        }}
      >
        <img
          src={styleOptions.color[styleSetting.color].calcHeaderPng}
          alt=""
          style={{
            top: "0",
            left: "0",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flex: "2",
          backgroundColor: styleOptions.color[styleSetting.color].color01,
        }}
      >
        <div
          style={{
            marginLeft: "8%",
            marginRight: "8%",
            display: "flex",
            alignItems: "center",
            boxSizing: "border-box",
            textAlign: "right",
            fontWeight: "700",
            width: "100%",
            justifyContent: "flex-end",
            fontSize: divWidth / 15,
          }}
        >
          <div>
            Your Emissions
            <br />
            Report Card
          </div>
        </div>
      </div>
    </div>
  );

  const row2 = (styleSetting) => (
    <div style={rowStyle(styleOptions.font[styleSetting.font])}>
      <div
        style={{
          display: "flex",
          flex: "1",
          flexDirection: "row",
          position: "relative",
          backgroundColor: styleOptions.color[styleSetting.color].colorAccent,
          overflow: "hidden",
        }}
      >
        <div
          style={{
            display: "flex",
            // width: divWidth / 8,
            paddingLeft: "4%",
            paddingRight: "4%",
            height: divWidth / 25,
            margin: "3%",
            borderRadius: divWidth / 10,
            // border: divWidth / 100 + "px solid " + pickHex(score),
            boxSizing: "border-box",
            backgroundColor: qualityCheck(score)[1],
            fontSize: divWidth / 50,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {qualityCheck(score)[0]}
        </div>
        <div
          style={{
            display: "flex",
            flex: "1",
            flexDirection: "column",
            color: styleOptions.color[styleSetting.color].color1,
            justifyContent: "center",
            position: "absolute",
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            // border: divWidth / 70 + "px solid " + pickHex(score),
          }}
        >
          <div
            style={{
              fontWeight: "600",
              fontSize: divWidth / 18,
              lineHeight: 0.95,
            }}
          >
            Score
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "700",
              fontSize: divWidth / 13,
              lineHeight: 0.95,
              flexDirection: "row",
            }}
          >
            {score}
            <div style={{ fontFamily: "monospace" }}>/</div>100
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flex: "2",
          backgroundColor: styleOptions.color[styleSetting.color].color1,
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            marginLeft: "8%",
            marginRight: "8%",
            display: "flex",
            alignItems: "center",
            boxSizing: "border-box",
            textAlign: "left",
            fontWeight: "700",
          }}
        >
          <div>
            You’re responsible for <br />
            <u>{emissions} metric tons</u> of CO2e
            <br />
            emissions annually.
          </div>
        </div>
      </div>
    </div>
  );

  const row3 = (styleSetting) => (
    <div
      style={{
        ...rowStyle(styleOptions.font[styleSetting.font]),
        backgroundColor: styleOptions.color[styleSetting.color].color2,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <img
          src={
            styleOptions.text[styleSetting.text][2] +
            (styleOptions.color[styleSetting.color].colorAccent === "black"
              ? "-dark"
              : "") +
            ".png"
          }
          alt=""
          style={{
            width: "100%",
            objectFit: "cover",
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        }}
      >
        <div
          style={{
            display: "flex",
            flex: "3",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              marginLeft: "27%",
              marginRight: "3%",
              display: "flex",
              alignItems: "center",
              boxSizing: "border-box",
              textAlign: "right",
              fontWeight: "700",
              fontSize: divWidth / 30,
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            {styleOptions.text[styleSetting.text][0]}
          </div>
        </div>

        <div
          style={{
            flex: "1",
            maxWidth: "25%",
            boxSizing: "border-box",
            height: "100%",
            width: "100%",
            padding: "2%",
          }}
        >
          <div
            style={{
              display: "flex",
              height: "100%",
              width: "100%",
              padding: "5%",
              flexDirection: "row",
              boxSizing: "border-box",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor:
                styleOptions.color[styleSetting.color].colorAccent,
              boxStyle: "border-box",
              borderRadius: "50%",
              position: "relative",
            }}
          >
            <div
              style={{
                display: "flex",
                position: "absolute",
                top: "0",
                left: "0",
                right: "0",
                bottom: "0",
                color: styleOptions.color[styleSetting.color].color2,
                justifyContent: "center",
                alignItems: "center",
                fontSize:
                  styleOptions.text[styleSetting.text][1].length >= 5
                    ? divWidth / 16
                    : divWidth / 14,
                fontWeight: "400",
              }}
            >
              {styleOptions.text[styleSetting.text][1]}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const row4 = (styleSetting) => (
    <div
      style={{
        ...rowStyle(styleOptions.font[styleSetting.font]),
        backgroundColor: styleOptions.color[styleSetting.color].color30,
      }}
    >
      <div
        style={{
          display: "flex",
          flex: "4",
          maxWidth: "33.3%",
          backgroundColor: styleOptions.color[styleSetting.color].color30,
          boxSizing: "border-box",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <img
          alt=""
          src={styleOptions.color[styleSetting.color].dollarPng}
          style={{ objectFit: "contain", width: "80%" }}
        />
        <div
          style={{
            display: "flex",
            flex: "1",
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            color: styleOptions.color[styleSetting.color].color2,
            justifyContent: "center",
            alignItems: "center",
            fontSize: divWidth / 14,
            fontWeight: "400",
          }}
        >
          ${investment}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flex: "6.5",
          backgroundColor: styleOptions.color[styleSetting.color].color30,
        }}
      >
        <div
          style={{
            marginRight: "8%",
            display: "flex",
            alignItems: "center",
            boxSizing: "border-box",
            textAlign: "left",
            fontWeight: "700",
            fontSize: divWidth / 30,
          }}
        >
          <div>
            An annual <u>${investment} investment</u>
            <br /> could reduce your emissions
            <br />
            and increase your score by <u>{newScore - score}</u>!
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flex: "1.5",
          flexDirection: "row",
          backgroundColor: styleOptions.color[styleSetting.color].color31,
          boxSizing: "border-box",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <img
          alt=""
          style={{ objectFit: "fill", height: "125%" }}
          src={styleOptions.color[styleSetting.color].lightningPng}
        />
      </div>
    </div>
  );

  const singlePanel = (styleSetting) => (
    <div
      key="panel"
      style={{
        flexDirection: "column",
        display: "flex",
        width: "100%",
        height: "100%",
        color: styleOptions.color[styleSetting.color].colorAccent,
      }}
    >
      {row1(styleSetting)}
      {row2(styleSetting)}
      {row3(styleSetting)}
      {row4(styleSetting)}
    </div>
  );

  const resultPage = () => (
    <div
      // loop="true"
      // ref={swiperElRef}
      id="reportCard"
      // pagination="true"
      // navigation="true"

      style={{
        display: "flex",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        width: "100%",
        position: "absolute",
        justifyContent: "center",
        alignItems: "center",
        fontSize: divWidth / 28,
        flexDirection: "column",
        overflow: "hidden",
        borderRadius: divWidth / 50,
        zIndex: "0",
        cssMode: true,
      }}
    >
      {singlePanel(styleSetting)}
    </div>
  );

  return (
    <div
      ref={resultRef}
      style={{
        display: "flex",
        boxSizing: "border-box",
        maxWidth: !(windowSize.innerWidth < 600) ? "65vh" : null,
        minWidth: mainStyle.minWidth,
        width: "100%",
        height: "100%",
        flex: "1",
        flexDirection: "column",
        // border: "2px solid purple",
        justifyContent: "center",
        alignItems: "center",
        userSelect: "none",
      }}
    >
      <div
        style={{
          display: "flex",
          boxSizing: "border-box",
          userSelect: "none",
          flexDirection: "column",
          fontWeight: "700",
          fontSize: isShort ? "15px" : isNarrow ? "18px" : "19px",
          width: "100%",
          flex: "1",
          gap: myGap,
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            textAlign: "left",
            justifyContent: "space-between",
            alignItems: "center",

            color: mainStyle.colorGreenBackground,
            flexDirection: "row",
            boxSizing: "border-box",
            // border: "1px solid red"
            fontWeight: "600",
            // paddingBottom: "3px",
            // borderBottom: "1px solid " + mainStyle.colorGreenBackground,
          }}
        >
          <div
            onClick={params.clearResults}
            style={{
              boxSizing: "border-box",
              border: "0px solid red",
              cursor: "pointer",
            }}
          >
            <img
              alt="back arrow"
              src="left-arrow.png"
              style={{
                height: isShort ? "11px" : isNarrow ? "13px" : "13px",
                marginRight: "5px",
              }}
            />
            Return to calculator
          </div>
          <div
            onClick={() => setThanksPopupOpen({ type: "reportcard-info" })}
            style={{
              boxSizing: "border-box",
              display: "flex",
              // border: "1px solid red",
              cursor: "pointer",
            }}
          >
            <img
              alt="info button"
              src="info.png"
              style={{ height: isNarrow ? "18px" : "20px", marginRight: "2px" }}
            />
          </div>
          {/* <div
            onClick={() => setThanksPopupOpen({ type: "reportcard-info" })}
            style={{
              boxSizing: "border-box",
              display: "flex",
              // border: "1px solid red",
              cursor: "pointer",
              width: divWidth / 4,
              height: divWidth / 16,
              borderRadius: "50px",
              backgroundColor: mainStyle.colorGreenBackground,
              paddingLeft: "10px",
              alignItems: "center",
              color: mainStyle.colorGreen,
              fontSize: isNarrow ? divWidth / 35 : divWidth / 40,
            }}
          >
            Learn
          </div> */}
        </div>
        {/* <div style={{ height: spacerHeight }} /> */}
        <div
          className="result-image"
          style={{
            boxShadow: "0px 0px 15px 10px rgba(0,0,0,0.15)",
            borderRadius: divWidth / 50,
            // border: "2px solid yellow",
            boxSizing: "border-box",
            overflow: "hidden",
            position: "relative",
          }}
        >
          {resultPage()}
        </div>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            gap: myGap,
            height: isVeryNarrow ? divWidth / 8 : divWidth / 10,
            // border: "1px solid red",
          }}
        >
          <div
            id="make-a-change"
            onClick={handleShare}
            style={selectorStyle(
              isVeryNarrow,
              isNarrow,
              submitDown === 3,
              false,
              isShort
            )}
            onMouseDown={() => (!isLoadingShare ? setSubmitDown(3) : null)}
            onMouseUp={() => (!isLoadingShare ? setSubmitDown(null) : null)}
            onMouseLeave={() => (!isLoadingShare ? setSubmitDown(null) : null)}
          >
            {isLoadingShare ? (
              <img
                alt=""
                src="loading3.gif"
                style={{ height: isNarrow ? "20px" : "30px" }}
              />
            ) : (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <img
                  alt=""
                  src="send.png"
                  style={{
                    height: isNarrow ? "16px" : "20px",
                    marginRight: "5px",
                  }}
                />
                <div>
                  <u>Share</u> for insights
                </div>
              </div>
            )}
          </div>
          <div
            onClick={scrollUp}
            style={selectorStyle(
              isVeryNarrow,
              isNarrow,
              submitDown === 4,
              false,
              isShort
            )}
            onMouseDown={() => (!isLoadingShare ? setSubmitDown(4) : null)}
            onMouseUp={() => (!isLoadingShare ? setSubmitDown(null) : null)}
            onMouseLeave={() => (!isLoadingShare ? setSubmitDown(null) : null)}
          >
            <img
              src="arrow-down.png"
              alt=""
              style={{ width: "19px", marginRight: "5px" }}
            />
            <div>
              <u>Make a change</u>
            </div>
          </div>
        </div>
        <div style={{ height: spacerHeight }} />
      </div>
    </div>
  );
};

export default ReportCard;

const selectorStyle = (
  isVeryNarrow,
  isNarrow,
  selected,
  transparent,
  isShort
) => ({
  display: "flex",
  flex: "1",
  height: "100%",
  borderRadius: mainStyle.smallRadius,
  backgroundColor: transparent ? null : mainStyle.colorGreenBackground,
  // boxShadow: "0px 0px 15px 10px rgba(0,0,0,0.05)",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  boxSizing: "border-box",
  color: transparent ? mainStyle.colorGreenBackground : mainStyle.colorGreen,
  fontSize: isShort
    ? "12px"
    : isVeryNarrow
    ? "12px"
    : isNarrow
    ? "15px"
    : "16px",
  userSelect: "none",
  boxShadow: selected && "inset 0px 0px 10px 0px rgba(0,0,0,0.25)",
  fontFamily: "Lora",
  fontWeight: "700",
  border: transparent
    ? (isVeryNarrow ? "1px" : "2px") +
      " solid " +
      mainStyle.colorGreenBackground
    : null,
  padding: "10px",
});

const rowStyle = (font) => ({
  fontFamily: font,
  display: "flex",
  flex: "1",
  maxHeight: "25%",
  flexDirection: "row",
  width: "100%",
  position: "relative",
});
