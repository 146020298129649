import { Link, useLocation } from "react-router-dom";
import * as mainStyle from "../styles/mainStyle";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../contexts/UserContext";

const sideBarWidth = "200px";

const NavBar = (params) => {
  const { windowSize, setThanksPopupOpen } = useContext(UserContext);
  const location = useLocation();
  const isDashboard = location.pathname === "/dashboard";
  const isLearn = location.pathname === "/learn";
  const isHome = location.pathname === "/";
  const shadow = isLearn
    ? { boxShadow: "0px 0px 30px 10px rgba(0,0,0,.09)" }
    : {};
  const bottomLine = params.darkTheme
    ? { borderBottom: "2px solid " + mainStyle.colorGreenBackground }
    : {};
  const isNarrow = windowSize.innerWidth < 850;
  const [sideMenuActive, setSideMenuActive] = useState(false);
  const defaultSelection = {
    home: false,
    learn: false,
    invest: false,
    calculate: false,
  };
  const [isShown, setIsShown] = useState(defaultSelection);
  const onMouseOut = () => {
    setIsShown(defaultSelection);
  };

  useEffect(() => {
    if (sideMenuActive) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [sideMenuActive]);

  const sideMenuOverlay = (
    <div
      style={{
        position: "absolute",
        height: "100vh",
        width: "100vw",
        left: 0,
        top: 0,
        zIndex: 1,
        backgroundColor: sideMenuActive ? "rgba(0,0,0,.5)" : "rgba(0,0,0,0)",
      }}
      onClick={() => setSideMenuActive(false)}
    ></div>
  );

  const sideMenu = (
    <div
      style={{
        position: "absolute",
        height: "100vh",
        width: sideBarWidth,
        borderRadius: "10px 0px 0px 10px",
        right: 0,
        top: 0,
        overflowX: "hidden",
        pointerEvents: sideMenuActive ? "auto" : "none",
        zIndex: 2,
      }}
    >
      <div style={menuDiv(sideMenuActive)}>
        <div style={menuContainerDiv}>
          <div style={listContainerDiv}>
            <Link
              onClick={() => setSideMenuActive(false)}
              style={menuItem(location.pathname === "/", isShown.home)}
              onMouseEnter={() =>
                setIsShown({ ...defaultSelection, home: true })
              }
              onMouseLeave={() => onMouseOut()}
              to="/"
            >
              Home
            </Link>
            <Link
              onClick={() => setSideMenuActive(false)}
              style={menuItem(location.pathname === "/learn", isShown.learn)}
              onMouseEnter={() =>
                setIsShown({ ...defaultSelection, learn: true })
              }
              onMouseLeave={() => onMouseOut()}
              to="/learn"
            >
              Learn
            </Link>
            <Link
              onClick={() => setSideMenuActive(false)}
              style={menuItem(location.pathname === "/invest", isShown.invest)}
              onMouseEnter={() =>
                setIsShown({ ...defaultSelection, invest: true })
              }
              onMouseLeave={() => onMouseOut()}
              to="/invest"
            >
              Invest
            </Link>
            <Link
              onClick={() => setSideMenuActive(false)}
              style={menuItem(
                location.pathname === "/calculate",
                isShown.calculate
              )}
              onMouseEnter={() =>
                setIsShown({ ...defaultSelection, calculate: true })
              }
              onMouseLeave={() => onMouseOut()}
              to="/calculate"
            >
              Calculate
            </Link>
          </div>
          <div style={loginContainerDiv}>
            <div
              style={sideMenuActionDivStyle(false, params.darkTheme)}
              onClick={() => setThanksPopupOpen({ type: "together" })}
            >
              Join Waitlist
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const leftSection = (
    <div
      style={{
        ...SubDivStyle(params.darkTheme),
        justifyContent: "left",
        flex: isNarrow || isDashboard ? 0 : 1,
      }}
    >
      {!isDashboard ? (
        <Link
          to="/"
          style={pageDivStyle(location.pathname === "/", params.darkTheme)}
        >
          Home
        </Link>
      ) : null}
      {!isDashboard ? (
        <Link
          to="/learn"
          style={pageDivStyle(location.pathname === "/learn", params.darkTheme)}
        >
          Learn
        </Link>
      ) : null}
      {!isDashboard ? (
        <Link
          to="/invest"
          style={pageDivStyle(
            location.pathname === "/invest",
            params.darkTheme
          )}
        >
          Invest
        </Link>
      ) : null}
      {!isDashboard ? (
        <Link
          to="/calculate"
          style={pageDivStyle(
            location.pathname === "/calculate",
            params.darkTheme
          )}
        >
          Calculate
        </Link>
      ) : null}
    </div>
  );

  const burgerInitDiv = (
    <div
      style={{
        height: "24px",
        width: "24px",
        marginLeft: "20px",
        cursor: "pointer",
      }}
      onClick={() => setSideMenuActive(!sideMenuActive)}
    >
      <img
        alt="hamburger"
        src={params.darkTheme ? "/menu-white.png" : "/menu-dark.png"}
        style={{ objectFit: "contain", height: "100%", width: "100%" }}
      />
    </div>
  );

  const waitlistSelector = (
    <div
      style={{
        ...SubDivStyle(params.darkTheme),
        justifyContent: "flex-end",
        flex: 1,
      }}
    >
      <div
        style={actionDivStyle(false, params.darkTheme)}
        onClick={() => setThanksPopupOpen({ type: "together" })}
      >
        Join Waitlist
      </div>
      {isNarrow && !isDashboard ? burgerInitDiv : null}
    </div>
  );

  const scrollUp = () => {
    if (isHome)
      document
        .getElementById("scrollableHead")
        .scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div
      style={{
        ...MainDivStyle(isNarrow, params.darkTheme),
        ...shadow,
        ...bottomLine,
      }}
    >
      {sideMenu}
      {sideMenuActive ? sideMenuOverlay : null}
      {!isNarrow ? leftSection : null}
      <div style={{ ...SubDivStyle(params.darkTheme) }}>
        <Link to="/" style={imgContainerDivStyle} onClick={scrollUp}>
          <img
            alt="logo512"
            src={params.darkTheme ? "/Green-white.png" : "/Green-dark.png"}
            style={imgDivStyle}
          />
        </Link>
      </div>
      {waitlistSelector}
    </div>
  );
};

export default NavBar;

const MainDivStyle = (isNarrow, darkTheme) => ({
  display: "flex",
  width: "100%",
  height: mainStyle.navBarHeight,
  position: "fixed",
  flexDirection: "row",
  cursor: "default",
  boxSizing: "border-box",
  paddingLeft: isNarrow ? "20px" : "30px",
  paddingRight: "20px",
  backgroundColor: darkTheme
    ? mainStyle.colorGreen
    : mainStyle.colorGreenBackground,
  zIndex: "5",
  userSelect: "none",
});

const SubDivStyle = (darkTheme) => ({
  display: "flex",
  height: "100%",
  paddingTop: "10px",
  paddingBottom: "10px",
  boxSizing: "border-box",
  fontWeight: "700",
  color: darkTheme ? mainStyle.colorGreenBackground : mainStyle.colorGreen,
  alignItems: "center",
  // border: "1px solid red",
});

const imgContainerDivStyle = {
  height: "100%",
};

const imgDivStyle = {
  height: "100%",
  objectFit: "contain",
};

const pageDivStyle = (underline, darkTheme) => ({
  display: "flex",
  height: "100%",
  marginRight: "20px",
  alignItems: "center",
  cursor: "pointer",
  color: darkTheme ? mainStyle.colorGreenBackground : mainStyle.colorGreen,
  textDecoration: "none",
  fontWeight: underline ? "700" : "400",
  boxSizing: "border-box",
});

const actionDivStyle = (darkButton, darkTheme) => ({
  display: "flex",
  height: "90%",
  alignItems: "center",
  borderRadius: mainStyle.mediumRadius,
  border: !darkTheme && "2px solid " + mainStyle.colorGreen,
  marginLeft: "10px",
  paddingLeft: "10px",
  paddingRight: "10px",
  boxSizing: "border-box",
  backgroundColor:
    darkTheme || !darkButton
      ? mainStyle.colorGreenBackground
      : mainStyle.colorGreen,
  cursor: "pointer",
  color: darkTheme || !darkButton ? mainStyle.colorGreen : "white",
  textDecoration: "none",
});

const sideMenuActionDivStyle = (darkButton, darkTheme) => ({
  display: "flex",
  height: "100%",
  alignItems: "center",
  borderRadius: mainStyle.mediumRadius,
  boxSizing: "border-box",
  backgroundColor: darkTheme || !darkButton ? "white" : mainStyle.colorGreen,
  cursor: "pointer",
  flex: 1,
  justifyContent: "center",
  color: darkTheme || !darkButton ? mainStyle.colorGreen : "white",
  textDecoration: "none",
  fontWeight: "600",
});

const menuItem = (underline, isShown = false) => ({
  ...{
    display: "flex",
    color: "white",
    textDecoration: "none",
    textAlign: "left",
    fontWeight: "500",
    minHeight: "38px",
    paddingLeft: "12px",
    alignItems: "center",
    borderRadius: "10px",
    marginBottom: "5px",
    boxSizing: "border-box",
  },
  ...(isShown
    ? {
        border: "2px solid " + mainStyle.colorGreenBackground,
        paddingLeft: "10px",
        fontWeight: "700",
      }
    : {}),
  ...(underline
    ? {
        paddingLeft: "10px",
        border: "0px",
        backgroundColor: "rgba(0,0,0,.1)",
        fontWeight: "700",
        cursor: "default",
      }
    : {}),
});

const menuDiv = (sideMenuActive) => ({
  display: "flex",
  flexDirection: "column",
  width: sideBarWidth,
  height: "100vh",
  position: "absolute",
  right: sideMenuActive ? 0 : "-200px",
  boxSizing: "border-box",
  backgroundColor: mainStyle.colorGreen,
  transition: "350ms",
});

const menuContainerDiv = {
  display: "flex",
  flexDirection: "column",
  flex: 1,
  margin: "10px",
  boxSizing: "border-box",
};

const listContainerDiv = {
  display: "flex",
  flexDirection: "column",
  flex: 1,
  width: "100%",
  // padding: "20px 20px 0px 20px",
  boxSizing: "border-box",
};

const loginContainerDiv = {
  display: "flex",
  flexDirection: "row",
  height: "60px",
  width: "100%",
  boxSizing: "border-box",
  // border: "1px solid red",
};
