import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import { useState, useEffect } from "react";
import { UserContext } from "../contexts/UserContext";
import { useContext } from "react";
import * as mainStyle from "../styles/mainStyle";
import ReportCard from "../components/reportCard";
import CalcInput from "../components/CalcInput";
import Together from "../components/home/Together";

const colorGreenAlt = "#2A836C";

const Calculate = (params) => {
  const { windowSize } = useContext(UserContext);
  const isNarrow = windowSize.innerWidth < 850 || windowSize.innerHeight < 650;
  const [result, setResult] = useState(
    params.result ? params.result : JSON.parse(localStorage.getItem("result"))
  );

  const scrollUp = () => {
    document
      .getElementById("scrollableHeadCalc")
      .scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (result && result["total"]) {
      scrollUp();
    }
    localStorage.setItem("result", JSON.stringify(result));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  const clearResults = () => {
    setResult(null);
  };

  return (
    <div id="scrollableHeadCalc" style={MainDivStyle}>
      <NavBar darkTheme />
      <div style={BodyDivStyle}>
        <div
          style={{
            display: "flex",
            boxSizing: "border-box",
            justifyContent: "center",
            flex: 1,
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
            paddingLeft: mainStyle.margin.sidesTiny(isNarrow),
            paddingRight: mainStyle.margin.sidesTiny(isNarrow),
            paddingTop: mainStyle.margin.sidesTiny(isNarrow),
            paddingBottom: !result
              ? mainStyle.margin.sidesTiny(isNarrow)
              : mainStyle.margin.sidesTiny(isNarrow),
          }}
        >
          <div
            style={{
              display: "flex",
              boxSizing: "border-box",
              justifyContent: "center",
              flex: 1,
              flexDirection: "column",
              alignItems: "center",
              // border: "2px solid red",
              maxWidth: "1000px",
              // maxHeight: "600px",
              width: "100%",
            }}
          >
            {result === null ? (
              <CalcInput setResult={setResult} result={result} />
            ) : (
              <ReportCard clearResults={clearResults} emissionsInfo={result} />
            )}
          </div>
        </div>
        {result !== null && <Together whiteBorder callToAction />}
        <Footer />
      </div>
    </div>
  );
};

export default Calculate;

const MainDivStyle = {
  display: "flex",
  flexDirection: "column",
  cursor: "default",
  backgroundColor: colorGreenAlt,
  userSelect: "none",
  fontFamily: "Lora",
  boxSizing: "border-box",
};

const BodyDivStyle = {
  display: "flex",
  flexDirection: "column",
  width: "100vw",
  boxSizing: "border-box",
  minHeight: "100vh",
  paddingTop: mainStyle.navBarHeight,
  minWidth: mainStyle.minWidth,
  justifyContent: "center",
  alignItems: "center",
};
